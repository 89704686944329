import { BookableSlotFragmentFragment } from "../gql/graphql.ts";
import { ClassRoomCalendarCardItemDTO } from "./BookedClassRoom/ClassRoomCalendarCardItemDTO.tsx";

export function slotToClassRoomCalendarCardItemDTO(
  slot: BookableSlotFragmentFragment,
): ClassRoomCalendarCardItemDTO {
  if (!slot.classRoom)
    throw new Error(`classRoom is required on slot ${JSON.stringify(slot)}`);
  return {
    id: slot.classRoom.id,
    startedAtUtc: slot.startDateUtc,
    endedAtUtc: slot.endDateUtc,
    topic: {
      id: slot.topic.id,
      name: slot.topic.name,
      level: slot.slotLevel,
      pdfUrl: slot.topic.pdfUrl,
    },
    speakingLevel: slot.slotLevel,
    type: slot.slotType,
    participantsCount: slot.participantsCount,
    capacity: slot.capacity,
    meetingUri: slot.classRoom.meetingUri,
    canBeLeaved: slot.classRoom.canBeLeaved,
    timezone: slot.slotTimezone,
  };
}
