import { PropsWithChildren } from "react";
import { RequireUserData } from "./RequireUserData.tsx";
import { RequireUserSpeakingLevel } from "./RequireUserSpeakingLevel.tsx";
import { RequireAuth } from "./RequireAuth.tsx";
import { RequireActiveUser } from "../User/NotActive/RequireActiveUser.tsx";

export function RequireCompleteUser({ children }: PropsWithChildren) {
  return (
    <RequireAuth>
      <RequireUserData>
        <RequireActiveUser>
          <RequireUserSpeakingLevel>
            {children ?? null}
          </RequireUserSpeakingLevel>
        </RequireActiveUser>
      </RequireUserData>
    </RequireAuth>
  );
}
