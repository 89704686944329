import { PropsWithChildren } from "react";

type Props = PropsWithChildren;
export function Row({ children }: Props) {
  return (
    <div
      className={
        "flex space-y-4 md:space-y-0 md:space-x-4 md:flex-row flex-col"
      }
    >
      {children ?? null}
    </div>
  );
}
