import { useAuthCtx } from "./useAuthCtx.ts";

export function useCurrentUserOrThrow() {
  const { user, refetch } = useAuthCtx();
  if (!user) throw new Error("No user defined in auth context");
  return {
    user,
    refetch,
  };
}
