import { ReactNode, useEffect, useState } from "react";
import { classNames } from "../Utils/classNames.ts";
export type TabDTO = {
  id: string;
  label: string | ReactNode;
  element: ReactNode;
};
type Props = {
  tabs: TabDTO[];
  initialSelectedTabId?: string;
  onChangeTab: (tab: TabDTO) => void;
};
export function Tabs({ tabs, initialSelectedTabId, onChangeTab }: Props) {
  function getInitialSelectedTabId(): string {
    if (initialSelectedTabId && tabs.some((t) => t.id === initialSelectedTabId))
      return initialSelectedTabId;

    if (tabs.length > 0) return tabs[0].id;

    return "";
  }

  const [selectedTabId, setSelectedTabId] = useState(getInitialSelectedTabId());

  useEffect(() => {
    if (initialSelectedTabId) setSelectedTabId(initialSelectedTabId);
  }, [initialSelectedTabId]);

  return (
    <div className={"w-full"}>
      <div className={"flex items-center w-full"}>
        {tabs.map((tab) => {
          return (
            <div
              onClick={() => {
                setSelectedTabId(tab.id);
                onChangeTab(tab);
              }}
              className={classNames(
                "px-4 py-2 cursor-pointer flex-1 flex justify-center items-center text-sm whitespace-nowrap",
                selectedTabId === tab.id
                  ? "border-primary font-semibold text-primary border-b-[2px]"
                  : "border-slate-400 hover:border-slate-600 text-slate-400 hover:text-slate-600 border-b-[1px]",
              )}
              key={tab.id}
            >
              {tab.label}
            </div>
          );
        })}
      </div>
      <div>
        {tabs.map((tab) => {
          if (tab.id !== selectedTabId) return null;
          return <div key={tab.id}>{tab.element}</div>;
        })}
      </div>
    </div>
  );
}
