import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { RatingInputWithLabels, TextAreaInput } from "@normasteaching/norma-ui";
import { InputWithLabel } from "../UI/Input/InputWithLabel.tsx";
import { PrimaryButton } from "../UI/Button/PrimaryButton.tsx";
import { useWithLoading } from "../UI/Loading/useWithLoading.ts";
import { CheckCircleIcon } from "@heroicons/react/24/solid/index.js";
import { HorizontalLineDivider } from "../UI/HorizontalLineDivider.tsx";
import { LabelSize } from "../UI/Input/LabelSize.tsx";

export type LeaveClassRoomReviewFormInput = {
  topicUsefulnessScore: number;
  topicDifficultyScore: number;
  teacherClearanceScore: number;
  teacherInvolvementScore: number;
  teacherComfortableScore: number;
  generalLessonScore: number;
  reviewNotes?: string;
};

type Props = {
  onSave: (data: LeaveClassRoomReviewFormInput) => Promise<void>;
};
export function LeaveClassRoomReviewForm({ onSave }: Props) {
  const { t } = useTranslation("leaveClassroomReviewForm");
  const { withLoading, loading } = useWithLoading();
  const schema = yup
    .object({
      topicUsefulnessScore: yup
        .number()
        .integer()
        .required(t("errors.requireValueError")),
      topicDifficultyScore: yup
        .number()
        .integer()
        .required(t("errors.requireValueError")),
      teacherClearanceScore: yup
        .number()
        .integer()
        .required(t("errors.requireValueError")),
      teacherInvolvementScore: yup
        .number()
        .integer()
        .required(t("errors.requireValueError")),
      teacherComfortableScore: yup
        .number()
        .integer()
        .required(t("errors.requireValueError")),
      generalLessonScore: yup
        .number()
        .integer()
        .required(t("errors.requireValueError")),
      reviewNotes: yup.string(),
    })
    .required();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<LeaveClassRoomReviewFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<LeaveClassRoomReviewFormInput> = async (
    data,
  ) => {
    await withLoading(async () => onSave(data));
  };

  function generateRatingLabels(prefixKey: string): {
    label: string;
  }[] {
    const levelKey: Record<string, string> = {
      "0": "veryLow",
      "1": "low",
      "2": "medium",
      "3": "high",
      "4": "veryHigh",
    };

    return Array.from({
      length: 5,
    }).map((_, i) => {
      return {
        label: t(`${prefixKey}.${levelKey[i]}`),
      };
    });
  }

  return (
    <form className={"flex flex-col space-y-6"}>
      <h2 className={"font-bold"}>{t("section.topic")}</h2>
      <InputWithLabel
        labelSize={LabelSize.Normal}
        label={t("labels.topicUsefulness")}
      >
        <Controller
          render={({ field }) => {
            return (
              <RatingInputWithLabels
                possibleRatings={generateRatingLabels(
                  "labels.topicUsefulnessScore",
                )}
                errorMessage={errors.topicUsefulnessScore?.message}
                value={field.value}
                onChange={field.onChange}
                ref={field.ref}
              />
            );
          }}
          control={control}
          name={"topicUsefulnessScore"}
        />
      </InputWithLabel>
      <InputWithLabel
        labelSize={LabelSize.Normal}
        label={t("labels.topicDifficulty")}
      >
        <Controller
          render={({ field }) => {
            return (
              <RatingInputWithLabels
                possibleRatings={generateRatingLabels(
                  "labels.topicDifficultyScore",
                )}
                errorMessage={errors.topicDifficultyScore?.message}
                value={field.value}
                onChange={field.onChange}
                ref={field.ref}
              />
            );
          }}
          control={control}
          name={"topicDifficultyScore"}
        />
      </InputWithLabel>
      <HorizontalLineDivider />
      <h2 className={"font-bold"}>{t("section.teacher")}</h2>
      <InputWithLabel
        labelSize={LabelSize.Normal}
        label={t("labels.teacherClearance")}
      >
        <Controller
          render={({ field }) => {
            return (
              <RatingInputWithLabels
                possibleRatings={generateRatingLabels(
                  "labels.teacherClearanceScore",
                )}
                errorMessage={errors.teacherClearanceScore?.message}
                value={field.value}
                onChange={field.onChange}
                ref={field.ref}
              />
            );
          }}
          control={control}
          name={"teacherClearanceScore"}
        />
      </InputWithLabel>
      <InputWithLabel
        labelSize={LabelSize.Normal}
        label={t("labels.teacherInvolvement")}
      >
        <Controller
          render={({ field }) => {
            return (
              <RatingInputWithLabels
                possibleRatings={generateRatingLabels(
                  "labels.teacherInvolvementScore",
                )}
                errorMessage={errors.teacherInvolvementScore?.message}
                value={field.value}
                onChange={field.onChange}
                ref={field.ref}
              />
            );
          }}
          control={control}
          name={"teacherInvolvementScore"}
        />
      </InputWithLabel>
      <InputWithLabel
        labelSize={LabelSize.Normal}
        label={t("labels.teacherComfortable")}
      >
        <Controller
          render={({ field }) => {
            return (
              <RatingInputWithLabels
                possibleRatings={generateRatingLabels(
                  "labels.teacherComfortableScore",
                )}
                errorMessage={errors.teacherComfortableScore?.message}
                value={field.value}
                onChange={field.onChange}
                ref={field.ref}
              />
            );
          }}
          control={control}
          name={"teacherComfortableScore"}
        />
      </InputWithLabel>
      <HorizontalLineDivider />
      <h2 className={"font-bold"}>{t("section.lesson")}</h2>
      <InputWithLabel
        labelSize={LabelSize.Normal}
        label={t("labels.generalLesson")}
      >
        <Controller
          render={({ field }) => {
            return (
              <RatingInputWithLabels
                possibleRatings={generateRatingLabels(
                  "labels.generalLessonScore",
                )}
                errorMessage={errors.generalLessonScore?.message}
                value={field.value}
                onChange={field.onChange}
                ref={field.ref}
              />
            );
          }}
          control={control}
          name={"generalLessonScore"}
        />
      </InputWithLabel>
      <InputWithLabel labelSize={LabelSize.Normal} label={t("labels.notes")}>
        <TextAreaInput {...register("reviewNotes")} />
      </InputWithLabel>
      <HorizontalLineDivider />
      <div className={"flex"}>
        <div className={"ml-auto"}>
          <PrimaryButton
            iconLeft={<CheckCircleIcon className={"w-5"} />}
            loading={loading}
            label={t("sendValuation")}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </form>
  );
}
