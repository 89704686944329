import { Logo } from "./Logo.tsx";
import { useAuthCtx } from "../../Auth/useAuthCtx.ts";
import { TextButton } from "../Button/TextButton.tsx";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";

export function UnCompleteUserNavBar({
  navBarRight,
}: {
  navBarRight?: ReactNode;
}) {
  const { signOut } = useAuthCtx();
  const { t } = useTranslation("navBarWithLogo");
  return (
    <div
      className={
        "p-4 shadow-xl shadow-slate-200/10 w-full flex items-center justify-between"
      }
    >
      <Logo />
      {navBarRight ? (
        navBarRight
      ) : (
        <TextButton
          label={t("logOut")}
          onClick={async () => {
            await signOut();
          }}
        />
      )}
    </div>
  );
}
