import { useMyRemainingLessons } from "./useMyRemainingLessons.ts";
import { SpinnerContainer } from "../../UI/Loading/SpinnerContainer.tsx";
import { ProgressBar } from "../../UI/Progress/ProgressBar.tsx";
import { Trans, useTranslation } from "react-i18next";
import { classNames } from "../../UI/Utils/classNames.ts";
import { TI } from "../../I18n/TI.ts";

export function TotalRemainingLessonsComponent() {
  const { totalAvailableLessons, remainingLessons, loading } =
    useMyRemainingLessons();

  const { t } = useTranslation("totalRemainingLessonsComponent");

  function getWarningThreshold() {
    return Math.round(totalAvailableLessons * 0.1);
  }

  const warningThreshold = getWarningThreshold();

  const hasWarning = remainingLessons <= warningThreshold;

  return (
    <SpinnerContainer loading={loading}>
      <div className={"flex flex-col space-y-3"}>
        <div className={"space-y-1 text-sm"}>
          <h6>{t("title")}</h6>
          <p className={classNames("")}>
            <Trans
              ns={"totalRemainingLessonsComponent"}
              i18nKey={"remainingLessons"}
            >
              <span className={hasWarning ? "text-red-600 font-bold" : ""}>
                {{ remainingLessons } as TI}
              </span>{" "}
              di {{ totalAvailableLessons } as TI}
            </Trans>
          </p>
        </div>
        <ProgressBar
          colorClassRanges={[
            {
              minIncl: totalAvailableLessons - warningThreshold,
              maxExcl: Infinity,
              colorClass: "bg-red-600",
            },
          ]}
          total={totalAvailableLessons}
          current={totalAvailableLessons - remainingLessons}
        />
        {/*<HorizontalLineDivider />*/}
        {/*<OutlinedButton*/}
        {/*  iconLeft={<ShoppingCartIcon className={"w-5"} />}*/}
        {/*  label={t("buy")}*/}
        {/*  onClick={() => {}}*/}
        {/*/>*/}
      </div>
    </SpinnerContainer>
  );
}
