import { ISpeakingLevel } from "./ISpeakingLevel.ts";
import { ExplorerLevel } from "./ExplorerLevel.ts";
import { NavigatorLevel } from "./NavigatorLevel.ts";
import { PioneerLevel } from "./PioneerLevel.ts";
import { VoyagerLevel } from "./VoyagerLevel.ts";
import { NotSupportedLevelError } from "../Error/NotSupportedLevelError.ts";
import { StarterLevel } from "./StarterLevel.ts";

export class SpeakingLevelRepository {
  public static get(level: string): ISpeakingLevel {
    const levels = this.getAllLevels();
    const foundLevel = levels.find((l) => l.is(level));
    if (!foundLevel) throw new NotSupportedLevelError(level);
    return foundLevel;
  }

  public static getAllLevels(): ISpeakingLevel[] {
    return [
      new StarterLevel(),
      new ExplorerLevel(),
      new NavigatorLevel(),
      new PioneerLevel(),
      new VoyagerLevel(),
    ];
  }
}
