import { graphql } from "../../gql";

export const ClassRoomFragment = graphql(`
  fragment ClassRoomFragment on ClassRoomWithoutParticipantDto {
    id
    endedAtUtc
    startedAtUtc
    topic {
      name
      id
      level
      pdfUrl
    }
    type
    speakingLevel
    capacity
    participantsCount
    meetingUri
    canBeLeaved
    classRoomTimezone
  }
`);
