import { graphql } from "../gql/index.ts";

export const BookableSlotFragment = graphql(`
  fragment BookableSlotFragment on BookableSlot {
    slotId
    startDateUtc
    endDateUtc
    hasEnoughRemainingLessons
    topic {
      name
      id
      level
      pdfUrl
    }
    capacity
    slotLevel
    participantsCount
    slotType
    userIsAParticipant
    slotTimezone
    classRoom {
      id
      meetingUri
      canBeLeaved
    }
  }
`);
