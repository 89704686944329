import { ClassRoomCalendarCardItemDTO } from "./ClassRoomCalendarCardItemDTO.tsx";
import { useTranslation } from "react-i18next";
import { CalendarCardComponent } from "../CalendarCardComponent.tsx";
import { useLeaveClassRoom } from "./useLeaveClassRoom.ts";
import { ErrorMessagePopup } from "../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useLeavingClassErrorHandler } from "./UseLeavingClassErrorHandler.tsx";

type Props = {
  classRoom: ClassRoomCalendarCardItemDTO;
  showModal: boolean;
  onClose: () => void;
  onOpen: () => void;
};

export function SpeakingLessonCalendarCard({
  classRoom,
  showModal,
  onOpen,
  onClose,
}: Props) {
  const { t } = useTranslation("speakingClassCard");

  const { show, title, description, handleError, closePopup } =
    useLeavingClassErrorHandler();
  const { leaveClassRoom, loading } = useLeaveClassRoom();

  async function leave() {
    try {
      await leaveClassRoom(classRoom.id);
    } catch (e) {
      handleError(e as Error);
      throw e;
    }
  }
  return (
    <>
      <ErrorMessagePopup
        onClose={closePopup}
        title={title}
        description={description}
        show={show}
      />
      <CalendarCardComponent
        showModal={showModal}
        onOpen={onOpen}
        onClose={onClose}
        showLessonTopicBadge
        title={classRoom.topic.name}
        startDateUtc={classRoom.startedAtUtc}
        level={classRoom.speakingLevel}
        participantsCount={classRoom.participantsCount}
        capacity={classRoom.capacity}
        type={classRoom.type}
        ctaLabel={t("cancelSpeakingLesson")}
        ctaAction={leave}
        pdfUrl={classRoom.topic.pdfUrl}
        meetUri={classRoom.meetingUri}
        loadingCta={loading}
        disabled={!classRoom.canBeLeaved}
        disabledReason={t("cancelSpeakingLessonDisabledDueToTime")}
        slotTimezone={classRoom.timezone}
      />
    </>
  );
}
