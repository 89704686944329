import { graphql } from "../../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { GetProgressCheckAvailabilityQuery } from "../PorgressCheck/useProgressCheckAvailability.ts";
import { GetSpeakingClassAvailabilityQuery } from "../SpeakingLesson/useSpeakingClassAvailability.ts";
import { GetRemainingLessonPerWeek } from "../../User/Plan/useRemainingLessonPerWeek.ts";
import { GetMyRemainingLessons } from "../../User/Plan/useMyRemainingLessons.ts";
import { GetUserClassRoomsBetweenDates } from "./useMyClassRoomBetweenDates.ts";

const LeaveClassRoomMutation = graphql(`
  mutation leaveClassRoom($classRoomId: String!) {
    leaveClassRoom(classRoomId: $classRoomId) {
      ...ClassRoomFragment
    }
  }
`);
export function useLeaveClassRoom() {
  const [leaveClassRoomMutation, { loading }] = useMutation(
    LeaveClassRoomMutation,
    {
      refetchQueries: [
        GetProgressCheckAvailabilityQuery,
        GetSpeakingClassAvailabilityQuery,
        GetRemainingLessonPerWeek,
        GetMyRemainingLessons,
        GetUserClassRoomsBetweenDates,
      ],
      awaitRefetchQueries: true,
    },
  );

  const leaveClassRoom = useCallback(
    async (classRoomId: string) => {
      await leaveClassRoomMutation({
        variables: {
          classRoomId,
        },
      });
    },
    [leaveClassRoomMutation],
  );

  return { leaveClassRoom, loading };
}
