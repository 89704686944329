import { PropsWithChildren } from "react";
import { PrimaryLoadingIndicator } from "./PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "./LoaderSize.tsx";

type Props = PropsWithChildren<{
  loading: boolean;
  size?: LoaderSize;
}>;
export function SpinnerContainer({ children, loading, size }: Props) {
  return (
    <div className={"relative"}>
      <div className={"relative"}>{children ?? null}</div>
      {loading ? (
        <div
          className={
            "bg-white bg-opacity-85 absolute inset-0 z-10 flex items-center justify-center"
          }
        >
          <PrimaryLoadingIndicator size={size ?? LoaderSize.Large} />
        </div>
      ) : null}
    </div>
  );
}
