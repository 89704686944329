import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const SendResetPasswordEmailMutation = graphql(`
  mutation sendResetPasswordEmail {
    sendResetPasswordEmail
  }
`);
export function useSendResetPasswordLink() {
  const [sendResetPasswordEmailMutation, { loading }] = useMutation(
    SendResetPasswordEmailMutation,
  );

  const sendResetPasswordEmail = useCallback(async () => {
    await sendResetPasswordEmailMutation();
  }, [sendResetPasswordEmailMutation]);

  return {
    sendResetPasswordEmail,
    loading,
  };
}
