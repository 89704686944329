import { classNames } from "../Utils/classNames.ts";

export function ProgressBar({
  total,
  current,
  colorClassRanges,
}: {
  total: number;
  current: number;
  colorClassRanges?: {
    minIncl: number;
    maxExcl: number;
    colorClass: string;
  }[];
}) {
  const completePercentage = total > 0 ? (current / total) * 100 : 100;
  function getBgClass() {
    const foundColorClass = colorClassRanges?.find(
      (range) => current >= range.minIncl && current < range.maxExcl,
    );
    return foundColorClass?.colorClass ?? "bg-primary";
  }
  return (
    <div
      className={
        "w-full h-[6px] bg-slate-100 rounded-full relative overflow-hidden"
      }
    >
      <div
        className={classNames("h-full", getBgClass())}
        style={{ width: `${completePercentage}%` }}
      />
    </div>
  );
}
