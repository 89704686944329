import { getFragmentData, graphql } from "../../../gql/index.ts";
import { useQuery } from "@apollo/client";
import { FrozenWeekFragment } from "./FrozenWeekFragment.ts";

export const GetMyFrozenWeeksQuery = graphql(`
  query getMyFrozenWeeks {
    getMyFrozenWeeks {
      ...FrozenWeek
    }
  }
`);

export function useMyFrozenWeeks() {
  const { data, loading } = useQuery(GetMyFrozenWeeksQuery, {
    fetchPolicy: "cache-and-network",
  });

  return {
    frozenWeeks:
      getFragmentData(FrozenWeekFragment, data?.getMyFrozenWeeks) ?? [],
    loading,
  };
}
