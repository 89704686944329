import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AuthenticationError, useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { ImpersonationRepository } from "../Impersonation/ImpersonationRepository.ts";
import { onError } from "@apollo/client/link/error/index.js";
import { RetryLink } from "@apollo/client/link/retry/index.js";

enum AuthErrorCode {
  MissingRefreshToken = "missing_refresh_token",
}
export function useCreateApolloClient() {
  const { getAccessTokenSilently, logout } = useAuth0();
  const createApolloClient = useCallback(() => {
    const httpLink = new HttpLink({
      uri: `${import.meta.env.VITE_API_BASE_URL}`,
    });
    const authLink = setContext(async (_, prevContext) => {
      async function getAccessToken() {
        try {
          return await getAccessTokenSilently();
        } catch (e) {
          const error = e as AuthenticationError;
          if (error?.error === AuthErrorCode.MissingRefreshToken) {
            await logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }
          throw e;
        }
      }

      const token = await getAccessToken();
      return {
        ...prevContext,
        headers: {
          ...prevContext.headers,
          Authorization: token ? `Bearer ${token}` : "",
          ...(ImpersonationRepository.hasUid()
            ? {
                "X-Norma-Auth0-Sub":
                  ImpersonationRepository.getImpersonationUid(),
              }
            : {}),
        },
      };
    });

    const errorLink = onError(({ graphQLErrors }) => {
      if (graphQLErrors)
        for (const { message } of graphQLErrors) {
          if (message.includes("401: Unauthorized")) {
            window.location.reload();
          }
        }
    });

    const retryLink = new RetryLink({
      attempts: {
        max: 5,
      },
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: authLink.concat(errorLink).concat(retryLink).concat(httpLink),
    });
  }, [getAccessTokenSilently, logout]);

  return {
    createApolloClient,
  };
}
