import { useMessagePopupController } from "../../UI/MessagePopup/useMessagePopupController.ts";
import { useCallback } from "react";
import { ApolloError } from "@apollo/client";
import { ClassRoomErrorCodes } from "./ClassRoomErrorCodes.tsx";
import { useTranslation } from "react-i18next";

export function useLeavingClassErrorHandler() {
  const { show, title, description, showPopup, closePopup } =
    useMessagePopupController({
      timeoutInMs: 5 * 1000,
    });

  const { t } = useTranslation("leavingClassError");

  const handleError = useCallback(
    (e: Error) => {
      if (!(e instanceof ApolloError)) return;
      if (
        e.graphQLErrors?.some((e) => {
          return e.extensions?.code === ClassRoomErrorCodes.CannotBeLeaved;
        })
      )
        showPopup(t("title"), t("description"));
    },
    [showPopup, t],
  );

  return {
    show,
    title,
    description,
    handleError,
    closePopup,
  };
}
