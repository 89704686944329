import { getFragmentData, graphql } from "../gql";
import { useQuery } from "@apollo/client";
import { ClassRoomV2Fragment } from "../Speaking/BookedClassRoom/ClassRoomV2Fragment.ts";

const GetClassRoomByIdQuery = graphql(`
  query getClassRoomByIdV2($classRoomId: String!) {
    getClassRoomByIdV2(classRoomId: $classRoomId) {
      ...ClassRoomV2
    }
  }
`);
export function useClassRoomByIdV2(classRoomId: string) {
  const { data, loading } = useQuery(GetClassRoomByIdQuery, {
    variables: {
      classRoomId,
    },
  });

  return {
    classRoom: getFragmentData(ClassRoomV2Fragment, data?.getClassRoomByIdV2),
    loading,
  };
}
