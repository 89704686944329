import { LayoutWithOnlyNavbar } from "../UI/Layout/LayoutWithOnlyNavbar.tsx";
import { NavBarDropDownWithOnlyLogout } from "../UI/Layout/NavBarDropDown/NavBarDropDownWithOnlyLogout.tsx";
import { useTranslation } from "react-i18next";
import { Card } from "../UI/Card.tsx";
import { PrimaryButton } from "../UI/Button/PrimaryButton.tsx";
import { useNavigate } from "react-router-dom";
import { BookSpeakingLessonCalendarScreenPath } from "../Speaking/SpeakingLesson/BookSpeakingLessonCalendarScreenPath.tsx";

export const LeaveClassRoomReviewThankYouScreenPath =
  "/classroom/review/thank-you";
export function LeaveClassRoomReviewThankYouScreen() {
  const { t } = useTranslation("leaveReviewThankYouScreen");
  const navigate = useNavigate();
  return (
    <LayoutWithOnlyNavbar navBarRight={<NavBarDropDownWithOnlyLogout />}>
      <div className={"flex flex-1 items-center justify-center"}>
        <Card>
          <h1 className={"text-center font-bold text-3xl"}>{t("title")}</h1>
          <p className={"text-center text-lg"}>{t("description")}</p>
          <div className={"flex"}>
            <div className={"mx-auto"}>
              <PrimaryButton
                label={t("cta")}
                onClick={() => navigate(BookSpeakingLessonCalendarScreenPath)}
              />
            </div>
          </div>
        </Card>
      </div>
    </LayoutWithOnlyNavbar>
  );
}
