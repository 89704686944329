import { ClassRoomFragmentFragment } from "../../gql/graphql.ts";
import { CalendarItemMap } from "../CalendarItemMap.ts";
import { SlotDayAndHourKey } from "../SlotDayAndHourKey.tsx";
import dayjs from "dayjs";

export function convertClassRoomToCalendarItemMap(
  classRooms: ClassRoomFragmentFragment[],
): CalendarItemMap<ClassRoomFragmentFragment> {
  const map = new CalendarItemMap<ClassRoomFragmentFragment>();
  classRooms.forEach((c) => {
    const key = SlotDayAndHourKey.fromDate(dayjs(c.startedAtUtc));
    map.set(key, c);
  });
  return map;
}
