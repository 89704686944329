import { TFunction } from "i18next";
import { SpeakingLevel } from "../../gql/graphql.ts";
import { ISpeakingLevel } from "./ISpeakingLevel.ts";

export class StarterLevel implements ISpeakingLevel {
  getLevelTitle(t: TFunction<"levelTitle">): string {
    return t("starters");
  }
  getLevelDescription(t: TFunction<"levelDesc">): string {
    return t("starters");
  }
  getTextColorClass(): string {
    return "text-norma-starter";
  }
  getBgColorClass(): string {
    return "bg-norma-starter";
  }
  getTextOnBgColorClass(): string {
    return "text-black";
  }
  getId(): SpeakingLevel {
    return SpeakingLevel.Starters;
  }
  is(level: string): boolean {
    return level == this.getId();
  }
}
