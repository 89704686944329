import { useLocation, useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import { classNames } from "../Utils/classNames.ts";
import { useTranslation } from "react-i18next";
import { TotalRemainingLessonsComponent } from "../../User/Plan/TotalRemainingLessonsComponent.tsx";
import { getRoutes } from "./getRoutes.tsx";
import { HasUnlockedProgressCheckSideMenuInfo } from "./HasUnlockedProgressCheckSideMenuInfo.tsx";
import { HorizontalLineDivider } from "../HorizontalLineDivider.tsx";
import { StudyPlanButton } from "../../User/Plan/FreezeWeek/StudyPlanButton.tsx";
import { useCurrentUserOrThrow } from "../../Auth/useCurrentUserOrThrow.ts";

function SideMenuButton({
  label,
  to,
  icon,
  isActive,
  onClick,
  externalUrl,
}: {
  to?: string;
  label: string | ReactNode;
  icon: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  externalUrl?: boolean;
}) {
  if (onClick && to) throw new Error("Cannot use both onClick and to");
  const navigate = useNavigate();

  function navigateToDest(to: string, externalUrl = false) {
    if (externalUrl) window.location.href = to;
    else navigate(to);
  }

  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        if (to) navigateToDest(to, externalUrl);
        if (onClick) onClick();
      }}
      className={classNames(
        "px-4 cursor-pointer py-2 shadow-slate-200/40 rounded-lg w-full text-center flex items-center space-x-2",
        isActive
          ? "bg-primary hover:brightness-125 text-white"
          : "hover:bg-slate-200",
      )}
    >
      {icon} {typeof label === "string" ? <span>{label}</span> : label}
    </a>
  );
}

export function SideMenu({ open }: { open?: boolean }) {
  const { t } = useTranslation("sideMenu");
  const { user } = useCurrentUserOrThrow();
  const location = useLocation();
  const activeRoute = location.pathname;
  const routes = getRoutes(t, {
    hideBookProgressCheck: user.hideBookProgressCheckSection,
    hideBookSpeakingClass: user.hideBookSpeakingClassSection,
  });

  return (
    <div
      className={classNames(
        open ? "flex" : "hidden md:flex",
        "p-4 shadow-lg border-r shadow-slate-200/10 border-slate-100 flex-col h-full bg-white",
      )}
    >
      <div className={"mt-4 w-full flex flex-col flex-1 h-full space-y-4"}>
        <div className={"overflow-hidden flex-1 flex flex-col space-y-4"}>
          <div className={"space-y-3 select-none overflow-y-auto max-h-full"}>
            {routes.map((r, i) => {
              return (
                <SideMenuButton
                  key={i}
                  label={r.label}
                  to={r.to}
                  isActive={r.to === activeRoute}
                  icon={r.icon}
                  externalUrl={r.externalUrl}
                />
              );
            })}
          </div>
          {user.hideBookProgressCheckSection ? null : (
            <HasUnlockedProgressCheckSideMenuInfo />
          )}
        </div>
        <div className={"mt-auto space-y-4"}>
          <TotalRemainingLessonsComponent />
          <HorizontalLineDivider />
          <StudyPlanButton />
        </div>
      </div>
    </div>
  );
}
