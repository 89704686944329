import { Layout } from "../../UI/Layout/Layout.tsx";
import { HorizontalLineDivider } from "../../UI/HorizontalLineDivider.tsx";
import { useState } from "react";
import { SlotCalendarCard } from "../SlotCalendarCard.tsx";
import { convertSlotToSlotByDateAndHourMap } from "../convertSlotToSlotByDateAndHourMap.tsx";
import { Interval } from "../Interval.tsx";
import { useSpeakingClassAvailability } from "./useSpeakingClassAvailability.ts";
import { createTodayInterval } from "../createTodayInterval.ts";
import { WeekRemainingLessons } from "../../User/Plan/WeekRemainingLessons.tsx";
import { WeeklyCalendar } from "../WeeklyCalendar.tsx";
import { HasSpeakingEnabledGuard } from "../HasSpeakingEnabledGuard.tsx";
import { SomethingWentWrong } from "../SomethingWentWrong.tsx";
import { useTranslation } from "react-i18next";
import { EmptyCalendarMessage } from "../EmptyCalendarMessage.tsx";
import dayjs from "dayjs";
import { useRemainingLessonPerWeek } from "../../User/Plan/useRemainingLessonPerWeek.ts";
import { ScrollToTop } from "../../UI/Layout/ScrollToTop.tsx";
import { useIsFrozenWeek } from "../../User/Plan/FreezeWeek/useIsFrozenWeek.ts";
import { useCurrentUserOrThrow } from "../../Auth/useCurrentUserOrThrow.ts";
import { Navigate } from "react-router-dom";
import { BookedClassRoomCalendarScreenPath } from "../BookedClassRoom/BookedClassRoomCalendarScreen.tsx";
const dateFormat = "YYYY-MM-DD";
function BookSpeakingLessonCalendarScreenInner() {
  const [interval, setInterval] = useState<Interval>(createTodayInterval());

  const { isFrozenWeek, loading: loadingIsFrozen } = useIsFrozenWeek({
    startOfWeekDate: interval.fromDate.format(dateFormat),
    endOfWeekDate: interval.toDate.format(dateFormat),
  });

  const { bookableSlots, loading } = useSpeakingClassAvailability({
    interval,
  });

  const { t } = useTranslation("bookSpeakingLessonScreen");

  const {
    remainingLessonCount,
    totalLessonCount,
    loading: loadingRemainingLessons,
  } = useRemainingLessonPerWeek({
    date: interval.fromDate,
  });
  return (
    <div className={"flex flex-col flex-1"}>
      <div
        className={"hidden md:flex items-center justify-between space-x-2 mb-4"}
      >
        <h1 className={"font-bold text-2xl"}>{t("title")}</h1>
        <WeekRemainingLessons interval={interval} />
      </div>
      <div className={"hidden md:block mb-4"}>
        <HorizontalLineDivider />
      </div>
      <WeeklyCalendar
        canGoToPrevWeek={interval.fromDate.isAfter(dayjs())}
        emptyDayComponent={
          <EmptyCalendarMessage message={t("emptyCalendarDay")} />
        }
        interval={interval}
        onChangeInterval={setInterval}
        loading={loading || loadingRemainingLessons || loadingIsFrozen}
        items={convertSlotToSlotByDateAndHourMap(bookableSlots)}
        itemRenderer={(slot) => (
          <SlotCalendarCard
            remainingLessonsInfo={{
              remaining: remainingLessonCount,
              total: totalLessonCount,
            }}
            slot={slot}
          />
        )}
        disabled={{
          isDisabled: isFrozenWeek,
          reason: t("frozenWeekDisabled"),
        }}
      />
    </div>
  );
}

export function BookSpeakingLessonCalendarScreen() {
  const { user } = useCurrentUserOrThrow();
  if (user.hideBookSpeakingClassSection)
    return <Navigate to={BookedClassRoomCalendarScreenPath} />;
  return (
    <>
      <ScrollToTop />

      <HasSpeakingEnabledGuard
        ifEnabled={
          <Layout maxWClass={"max-w-full"}>
            <BookSpeakingLessonCalendarScreenInner />
          </Layout>
        }
        ifDisabled={<SomethingWentWrong />}
      />
    </>
  );
}
