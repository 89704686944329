import { useCreateApolloClient } from "./ApolloClient/useCreateApolloClient.ts";
import { useMemo } from "react";
import { ApolloProvider } from "@apollo/client";
import { AuthCtxProvider } from "./Auth/AuthCtxProvider.tsx";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginScreen, LoginScreenPath } from "./Auth/LoginScreen.ts";
import { BookSpeakingLessonCalendarScreen } from "./Speaking/SpeakingLesson/BookSpeakingLessonCalendarScreen.tsx";
import { RequireAuth } from "./Auth/RequireAuth.tsx";
import {
  CompleteUserDataScreen,
  CompleteUserDataScreenPath,
} from "./Auth/CompleteUserDataScreen.tsx";
import { RequireUserData } from "./Auth/RequireUserData.tsx";
import {
  BookProgressCheckCalendarScreen,
  BookProgressCheckCalendarScreenPath,
} from "./Speaking/PorgressCheck/BookProgressCheckCalendarScreen.tsx";
import {
  BookedClassRoomCalendarScreen,
  BookedClassRoomCalendarScreenPath,
} from "./Speaking/BookedClassRoom/BookedClassRoomCalendarScreen.tsx";
import { RequireUserNotComplete } from "./Auth/RequireUserNotComplete.tsx";
import {
  MyProfileScreen,
  MyProfileScreenPath,
} from "./User/ProfileScreen/MyProfileScreen.tsx";
import {
  MissingSpeakingTestScreen,
  MissingSpeakingTestScreenPath,
} from "./User/MissingSpeakingTestScreen.tsx";
import { RequireCompleteUser } from "./Auth/RequireCompleteUser.tsx";
import { RequireMissingSpeakingLevel } from "./Auth/RequireMissingSpeakingLevel.tsx";
import {
  MissingAccountScreen,
  MissingAccountScreenPath,
} from "./Auth/MissingAccountScreen.tsx";
import { BookSpeakingLessonCalendarScreenPath } from "./Speaking/SpeakingLesson/BookSpeakingLessonCalendarScreenPath.tsx";
import {
  StartHereScreen,
  StartHereScreenPath,
} from "./Help/StartHereScreen.tsx";
import {
  OtherProductsScreen,
  OtherProductsScreenPath,
} from "./OtherCourses/OtherProductsScreen.tsx";
import {
  NotActiveScreen,
  NotActiveScreenPath,
} from "./User/NotActive/NotActiveScreen.tsx";
import { RequireActiveUser } from "./User/NotActive/RequireActiveUser.tsx";
import { RequireNotActiveUser } from "./User/NotActive/RequireNotActiveUser.tsx";
import { RequireMissingAccount } from "./Auth/RequireMissingAccount.tsx";
import { TestScreen, TestScreenPath } from "./UI/Test/TestScreen.tsx";
import {
  PostSelfAssessmentLandingScreen,
  PostSelfAssessmentLandingScreenPath,
} from "./User/PostSelfAssessmentLandingScreen.tsx";
import {
  ImpersonationScreen,
  ImpersonationScreenPath,
} from "./Impersonation/ImpersonationScreen.tsx";
import {
  StudyPlanScreen,
  StudyPlanScreenPath,
} from "./User/Plan/FreezeWeek/StudyPlanScreen.tsx";
import {
  LeaveClassRoomReviewScreen,
  LeaveClassRoomReviewScreenPath,
} from "./ClassRoom/LeaveClassRoomReviewScreen.tsx";
import {
  LeaveClassRoomReviewThankYouScreen,
  LeaveClassRoomReviewThankYouScreenPath,
} from "./ClassRoom/LeaveClassRoomReviewThankYouScreen.tsx";

const BookSpeakingLessonRoute = (
  <RequireCompleteUser>
    <BookSpeakingLessonCalendarScreen />
  </RequireCompleteUser>
);

function App() {
  const { createApolloClient } = useCreateApolloClient();
  const apolloClient = useMemo(
    () => createApolloClient(),
    [createApolloClient],
  );

  return (
    <ApolloProvider client={apolloClient}>
      <AuthCtxProvider>
        <BrowserRouter>
          <Routes>
            <Route path={LoginScreenPath} element={<LoginScreen />} />

            <Route
              path={CompleteUserDataScreenPath}
              element={
                <RequireAuth>
                  <RequireUserNotComplete>
                    <CompleteUserDataScreen />
                  </RequireUserNotComplete>
                </RequireAuth>
              }
            />
            <Route
              path={"/"}
              element={
                <div>
                  <Navigate to={BookSpeakingLessonCalendarScreenPath} />
                </div>
              }
            />
            <Route
              path={BookSpeakingLessonCalendarScreenPath}
              element={BookSpeakingLessonRoute}
            />
            <Route
              path={BookProgressCheckCalendarScreenPath}
              element={
                <RequireCompleteUser>
                  <BookProgressCheckCalendarScreen />
                </RequireCompleteUser>
              }
            />
            <Route
              path={MyProfileScreenPath}
              element={
                <RequireCompleteUser>
                  <MyProfileScreen />
                </RequireCompleteUser>
              }
            />
            <Route
              path={BookedClassRoomCalendarScreenPath}
              element={
                <RequireCompleteUser>
                  <BookedClassRoomCalendarScreen />
                </RequireCompleteUser>
              }
            />
            <Route
              path={OtherProductsScreenPath}
              element={
                <RequireCompleteUser>
                  <OtherProductsScreen />
                </RequireCompleteUser>
              }
            />
            <Route
              path={NotActiveScreenPath}
              element={
                <RequireAuth>
                  <RequireUserData>
                    <RequireNotActiveUser>
                      <NotActiveScreen />
                    </RequireNotActiveUser>
                  </RequireUserData>
                </RequireAuth>
              }
            />
            <Route
              path={MissingSpeakingTestScreenPath}
              element={
                <RequireAuth>
                  <RequireUserData>
                    <RequireActiveUser>
                      <RequireMissingSpeakingLevel>
                        <MissingSpeakingTestScreen />
                      </RequireMissingSpeakingLevel>
                    </RequireActiveUser>
                  </RequireUserData>
                </RequireAuth>
              }
            />
            <Route
              path={PostSelfAssessmentLandingScreenPath}
              element={
                <RequireAuth>
                  <RequireUserData>
                    <RequireActiveUser>
                      <PostSelfAssessmentLandingScreen />
                    </RequireActiveUser>
                  </RequireUserData>
                </RequireAuth>
              }
            />
            <Route
              path={MissingAccountScreenPath}
              element={
                <RequireMissingAccount>
                  <MissingAccountScreen />
                </RequireMissingAccount>
              }
            />
            <Route
              path={StartHereScreenPath}
              element={
                <RequireCompleteUser>
                  <StartHereScreen />
                </RequireCompleteUser>
              }
            />
            <Route
              path={StudyPlanScreenPath}
              element={
                <RequireCompleteUser>
                  <StudyPlanScreen />
                </RequireCompleteUser>
              }
            />
            <Route
              path={LeaveClassRoomReviewScreenPath}
              element={
                <RequireCompleteUser>
                  <LeaveClassRoomReviewScreen />
                </RequireCompleteUser>
              }
            />
            <Route
              path={LeaveClassRoomReviewThankYouScreenPath}
              element={
                <RequireCompleteUser>
                  <LeaveClassRoomReviewThankYouScreen />
                </RequireCompleteUser>
              }
            />
            <Route
              path={ImpersonationScreenPath}
              element={
                <RequireAuth>
                  <RequireUserData>
                    <ImpersonationScreen />
                  </RequireUserData>
                </RequireAuth>
              }
            />
            <Route
              path={TestScreenPath}
              element={
                <RequireAuth>
                  <TestScreen />
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthCtxProvider>
    </ApolloProvider>
  );
}

export default App;
