import { PropsWithChildren } from "react";
import { useCurrentUserOrThrow } from "./useCurrentUserOrThrow.ts";
import { SpeakingLevel } from "../gql/graphql.ts";
import { Navigate } from "react-router-dom";

export function RequireMissingSpeakingLevel({ children }: PropsWithChildren) {
  const { user } = useCurrentUserOrThrow();

  if (user.speakingLevel !== SpeakingLevel.Unknown)
    return <Navigate to={"/"} replace />;

  return children ?? null;
}
