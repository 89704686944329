import { getFragmentData, graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { Interval } from "../Interval.tsx";
import { ClassRoomFragment } from "./ClassRoomFragment.ts";
import { Writeable } from "../../DataUtils/Writable.ts";
import { ClassRoomFragmentFragment } from "../../gql/graphql.ts";

export const GetUserClassRoomsBetweenDates = graphql(`
  query getUserClassRoomsBetweenDates(
    $fromDate: String!
    $toDate: String!
    $clientTimezone: String!
  ) {
    getUserClassRoomsBetweenDates(
      fromDate: $fromDate
      toDate: $toDate
      clientTimezone: $clientTimezone
    ) {
      ...ClassRoomFragment
    }
  }
`);
export function useMyClassRoomBetweenDates({
  interval,
}: {
  interval: Interval;
}) {
  const formatString = "YYYY-MM-DD";
  const { data, loading } = useQuery(GetUserClassRoomsBetweenDates, {
    variables: {
      fromDate: interval.fromDate.format(formatString),
      toDate: interval.toDate.format(formatString),
      clientTimezone: dayjs.tz.guess(),
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    classRooms:
      (getFragmentData(
        ClassRoomFragment,
        data?.getUserClassRoomsBetweenDates,
      ) as Writeable<ClassRoomFragmentFragment[]>) ?? [],
    loading,
  };
}
