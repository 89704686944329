import { TFunction } from "i18next";
import { ISpeakingLevel } from "./ISpeakingLevel.ts";
import { SpeakingLevel } from "../../gql/graphql.ts";

export class NavigatorLevel implements ISpeakingLevel {
  is(level: string): boolean {
    return level == this.getId();
  }
  getId(): SpeakingLevel {
    return SpeakingLevel.Navigators;
  }
  getBgColorClass(): string {
    return "bg-norma-navigator";
  }

  getLevelDescription(t: TFunction<"levelDesc">): string {
    return t("navigators");
  }

  getLevelTitle(t: TFunction<"levelTitle">): string {
    return t("navigators");
  }

  getTextColorClass(): string {
    return "text-norma-navigator";
  }

  getTextOnBgColorClass(): string {
    return "text-black";
  }
}
