export enum ContentPlatformKnownPath {
  Mindset = "/courses/take/mindset",
  ExtraResources = "/pages/extra-resources",
  HelpStudents = "/pages/help",
  Explorers = "/courses/take/explorers",
  Navigators = "/courses/take/navigators",
  Pioneers = "/courses/take/pioneers",
  Voyagers = "/courses/take/voyagers",
  Skills = "/pages/tutti-i-livelli",
  NormaLive = "/pages/norma-s-hour",
}
