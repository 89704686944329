import { NavBarDropDown } from "./NavBarDropDown/NavBarDropDown.tsx";
import { Logo } from "./Logo.tsx";
import { Squares2X2Icon } from "@heroicons/react/24/solid/index.js";
import { MobileSubNavBar } from "./MobileSubNavBar.tsx";
import { useState } from "react";

export function CompleteUserNavBar() {
  const [showMobileSubNavBar, setShowMobileSubNavBar] = useState(false);

  return (
    <div>
      <div
        className={
          "p-4 min-h-16 bg-white shadow-xl shadow-slate-200/10 border-b border-slate-100 w-full flex items-center justify-between"
        }
      >
        <Logo />
        <div className={"flex items-center space-x-2 md:space-x-6"}>
          <button
            onClick={(e) => {
              e.preventDefault();
              setShowMobileSubNavBar((prev) => !prev);
            }}
            className={"block md:hidden"}
          >
            <Squares2X2Icon
              className={"w-6 cursor-pointer pointer-events-none"}
            />
          </button>
          <NavBarDropDown />
        </div>
      </div>
      {showMobileSubNavBar ? (
        <div className={"bg-white block md:hidden"}>
          <MobileSubNavBar />
        </div>
      ) : null}
    </div>
  );
}
