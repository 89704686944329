import { CogIcon } from "@heroicons/react/24/solid/index.js";
import { OutlinedButton } from "../../../UI/Button/OutlinedButton.tsx";
import { useNavigate } from "react-router-dom";
import { StudyPlanScreenPath } from "./StudyPlanScreen.tsx";
import { useTranslation } from "react-i18next";

export function StudyPlanButton() {
  const navigate = useNavigate();
  const { t } = useTranslation("lessonPlanManagementButton");
  return (
    <>
      <div>
        <OutlinedButton
          iconLeft={<CogIcon className={"w-6"} />}
          label={t("label")}
          onClick={() => navigate(StudyPlanScreenPath)}
        />
      </div>
    </>
  );
}
