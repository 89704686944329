export const en = {
  bookSpeakingLessonScreen: {
    title: "Book Real Life Conversation",
    emptyCalendarDay: "Oops, all lessons for today are already full",
    frozenWeekDisabled:
      "You've suspended Real Life Conversations for this week",
  },
  bookProgressCheckScreen: {
    title: "Book Progress Check",
    emptyCalendarDay: "Oops, all progress checks are already full",
    noProgressCheck: {
      title: "Oops! You can't book the Progress Check yet",
      subTitle:
        "You can book the Progress Check only when you have attended at least 10 Real Life Conversations",
      lessonsAttended:
        "{{lessonCompletedToUnlockProgressCheck}} out of {{totalLessonNeededToUnlockProgressCheck}} Real Life Conversations attended",
      goToLessonCalendar: "Book Real Life Conversation",
    },
    frozenWeekDisabled: "You put your plan on hold for this week",
  },
  weeklyCalendar: {
    previousWeek: "Previous Week",
    nextWeek: "Next Week",
  },
  weeklyRemainingLessons: {
    remainingLessons:
      "Remaining Weekly Lessons: <2>{{remainingLessonCount}} out of {{totalLessonCount}}</2>",
  },
  sideMenu: {
    lessonCalendar: "{{index}}.Book Real Life Conversation",
    progressCheckCalendar: "{{index}}.Book Progress Check",
    bookedLessonsCalendar: "{{index}}.My Bookings",
    menu: "Menu",
    startHere: "{{index}}.How FPS Works",
    help: "{{index}}.Help",
    otherProducts: "{{index}}.Other Products",
    mindset: "{{index}}.Mindset",
    extraResources: "{{index}}.Extra Resources",
    realLifeSkills: "{{index}}.Real Life Skills",
    liveWithNorma: "{{index}}.Norma's Hour",
  },
  progressCheckSlotCard: {
    bookProgressCheck: "Book Progress Check",
    allProgressCheckBooked:
      "You have already booked all available Progress Checks",
    allHourForThisWeekUsed:
      "You have already booked all Progress Checks for this week",
    errorTitle: "Oops, something went wrong",
    errorDescription:
      "An error occurred while booking the Progress Check, please refresh the page and try again",
  },
  progressCheckCard: {
    cancelProgressCheck: "Cancel Progress Check",
    cancelProgressCheckDisabledDueToTime:
      "🚫 You can cancel a Progress Check no later than 24 hours before the start so as not to lose it",
  },
  speakingClassSlotCard: {
    bookSpeakingLesson: "Book Real Life Conversation",
    allHourForThisWeekUsed:
      "You have already booked all your Real Life Conversations for this week",
    errorTitle: "Oops, something went wrong",
    errorDescription:
      "An error occurred while booking the Real Life Conversation, please refresh the page and try again",
  },
  speakingClassCard: {
    cancelSpeakingLesson: "Cancel Real Life Conversation",
    cancelSpeakingLessonDisabledDueToTime:
      "🚫 You can cancel a Real Life Conversation no later than 24 hours before the start so as not to lose it",
  },
  calendarCardComponent: {
    backToCalendar: "Back to calendar",
    lessonTopic: "Topic of the Real Life Conversation",
    close: "Close",
    downloadLessonMaterial: "Download the lesson presentation",
    join: "Join with Google Meet",
    remainingLessons:
      "Remaining weekly bookings: <2>{{remaining}} out of {{total}}</2>",
    participants: "{{participantsCount}} out of {{capacity}}",
    timezoneInfo: "Time is expressed in {{timezone}}",
    localTimeHour: "{{hour}} in your local time",
  },
  navBarDropDown: {
    logout: "Logout",
    englishLevel: "Your Fluency Personality",
    myProfile: "My account",
  },
  bookedClassRoomCalendarScreen: {
    title: "My bookings",
    emptyCalendarDay:
      "You have not booked any Real Life Conversations and/or Progress Checks today!",
    noClassRoomYet: {
      title: "No booking",
      description: "You have not yet booked your Real Life Conversations",
      goToLessonCalendar: "Book Now!",
    },
  },
  totalRemainingLessonsComponent: {
    title: "Total Remaining Lessons",
    buy: "Buy more Lessons",
    remainingLessons:
      "<0>{{remainingLessons}}</0> out of {{totalAvailableLessons}}",
  },
  leavingClassError: {
    title: "Error",
    description:
      "You can cancel a Real Life Conversation or a Progress Check no later than 24 hours before the start so as not to lose them",
  },
  completeUserDataScreen: {
    title: "Welcome!",
    completeData: "Complete your data to continue",
  },
  completeUserForm: {
    givenName: "Name",
    familyName: "Surname",
    save: "Confirm and proceed",
    insertAName: "Enter a name",
    insertASurname: "Enter a surname",
  },
  navBarWithLogo: {
    logOut: "Logout",
  },
  editUserForm: {
    email: "Email",
    givenName: "Name",
    familyName: "Surname",
    save: "Save",
    password: "Password",
    resetPassword: "Reset Password",
    insertAName: "Enter your name",
    insertASurname: "Enter your surname",
    insertAValidEmail: "Enter a valid email",
    passwordResetSentTitle: "Password Reset",
    passwordResetSentDescription:
      "We have sent you a link to reset your password on your email",
    passwordResetErrorTitle: "Password Reset",
    passwordResetErrorDescription:
      "An error occurred while sending the password reset link, please try again",
  },
  myProfileMyAccountTab: {
    personalInformation: "Personal Information",
    lessonsPlanTitle: "Real Life Conversation",
  },
  myAccountEnglishLevelTab: {
    myEnglishLevel: "My Fluency Personality",
    myCefrLevel: "My CEFR level",
    commonEuropeanFrameOfReference: "Common European Framework of Reference",
    commonEuropeanFrameOfReferenceDesc:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.",

    mySpeakingLevel: "My Fluency Personality",
    mySpeakingLevelTitle: "Speaking Level",
    mySpeakingLevelDesc:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  myProfileScreen: {
    title: "Personal Information",
    myAccount: "My account",
    myEnglishLevel: "My Fluency Personality",
  },
  missingGrammarTestScreen: {
    title: "Welcome to Fluent Forever!",
    description:
      "In 6 months (or less) you will become Fluent with the English Norma-Lization method. Watch Norma's video and start this fantastic adventure today!",
    goToTest: "Go to Self-Assessment",
    perks: {
      perk1: {
        title: "Self-Assessment",
        description:
          "Complete the Self-Assessment and discover your Fluency Personality",
      },
      perk2: {
        title: "Real Life Conversations",
        description:
          "Book your Real Life Conversation (Live class) for this week now",
      },
      perk3: {
        title: "Real Life Skills",
        description:
          "Practice with the Real Life Skills (video and audio lessons) on the platform",
      },
    },
  },
  missingAccountScreen: {
    title: "Missing account",
    description:
      "It seems that there is no account associated with your credentials. If you think this is a mistake, please contact our support team",
    contactSupport: "Contact support",
  },
  speakingFeatureDisabled: {
    title: "Feature not available",
    subTitle:
      "We need you to complete your Evaluation Test so we can understand your spoken level and recommend the most suitable lessons for you",
    bookEvaluationCheck: "Go to the evaluation test",
  },
  deleteAccount: {
    title: "Delete account",
    warningTitle: "Warning! Irreversible action",
    warningText:
      "You are about to permanently delete your account, bookings and all your history of completed lessons. This action is irreversible and cannot be undone. Contact us at help@normasteaching.com for more information",
    ctaText: "Permanently delete my account",
    ctaConfirm:
      "Are you sure you want to delete your account, you will lose access to Fluent Forever",
  },
  hasUnlockedProgressCheckSideMenu: {
    title: "You have unlocked the Progress Check!",
    description: "Access the calendar and proceed with your booking",
    bookNow: "Book Now",
  },
  startHere: {
    cta: "Download the Promise",
    title: "How Fluent Forever Works",
    description:
      "Watch the tutorial and discover how to become fluent in six months or less",
    perks: {
      perk1: {
        title: "Real Life Conversations",
        description: "Book the Real Life Conversations and unlock your Fluency",
      },
      perk2: {
        title: "Real Life Skills",
        description:
          "Practice with the Real Life Skills: watch, listen, understand and repeat",
      },
      perk3: {
        title: "Mindset and Extra Resources",
        description:
          "Deepen the English Norma-Lization method in the Mindset section and discover additional resources in the Extra Resources section",
      },
    },
  },
  completeUserNavBar: {
    theCourse: "The course",
    skills: {
      title: "Real Life Skills",
      description: "Audio, Video lessons and Exercises",
    },
    mindset: {
      title: "Mindset",
      description: "The right approach to start",
    },
    resources: {
      title: "Extra Resources",
      description: "Discover additional content",
    },
  },
  footer: {
    privacyPolicy: "Legal Info",
    cookiePolicy: "Cookies",
    helpCenter: "Help",
  },
  languageSelector: {
    english: "English",
    italian: "Italian",
  },
  otherProductsScreen: {
    title: "Norma's Teaching's Other Products",
    description:
      "Discover how to improve and train your English to take it to the next level",
    nod: {
      description:
        "NOD, Norma On Demand, is Norma's Teaching subscription platform. Over 1000 HD video lessons and 30 new lessons every month. 3 live appointments per month with Norma and the Teachers and a dedicated student community. 5 minutes a day to continue training your English in a Fast, Fun and Fantastic® way.",
    },
    yay: {
      description:
        "Pronunci-YAY-tion! is the online course to improve your pronunciation in just 5 weeks consisting of 16 HD video lessons, 5 modules, audio exercises, quizzes, glossaries, useful learning tools, and review cards.",
    },
    cvwow: {
      description:
        "CVwow is the online course to create a CV in English with WOW factor! 5 modules of HD video lessons with practical examples, templates, and step-by-step tutorials to stand out among thousands of candidates and get noticed by recruiters. Your dream job awaits!",
    },
    discover: "Discover",
  },
  notVisibleAvailability: {
    title: "Oops!",
    desc: "You can book your Real Life Conversations up to a maximum of 3 weeks in advance from the current week. The calendar updates automatically every week. Please come back soon.",
  },
  notActiveAccountScreen: {
    title: "Account Not Active",
    description:
      "It seems your account is not currently active. If you believe this is an error, please contact us at help@normasteaching.com",
  },
  postSelfAssessmentLanding: {
    title: "Thank You for Completing the Self-Assessment 😊",
    subTitle:
      "How exciting! Shortly, you'll discover your Fluency Personality. Please do not close this page",
    errorTitle: "Oops, Something Went Wrong 😢",
    errorSubtitle:
      "Please wait 5 minutes and try again. If the problem persists, contact us at help@normasteaching.com",
    tryAgain: "Try Again",
    resultTitle: "🎉 Here's Your Result! 🎉",
    resultSubTitle:
      "Your <1>Fluency Personality</1> is: <4>{{speakingLevel}}</4>",
    letsStart: "Let's Start!",
  },
  levelDesc: {
    explorers:
      "Explorers are just beginning to learn English and don't yet feel confident or solid in their skills. They know some words and grammar but often struggle to put together simple complete sentences on their own. They can understand and use basic expressions for everyday needs. When speaking with others, they can say who they are and ask or answer simple questions about where they live, who they know, and what they own. But they really need the other person to speak slowly and clearly and be willing to help for the conversation to work. This level is about taking those first steps in learning how to communicate more complex ideas later on.",
    navigators:
      "Navigators can talk about topics they know well, like their hobbies or what they like, but find it difficult to converse on less specific topics or in everyday situations. They're getting better at understanding more things but often struggle to find the right words when they need them. They can understand simple sentences and common expressions regarding basic personal information, shopping, where they live and work. They can have simple conversations about familiar things but usually stick to phrases they've memorized.",
    pioneers:
      "Pioneers know English well enough to get by in many different situations. They may not always choose the perfect words or put together sentences like a native speaker would, but they can make themselves understood. Their English is good for everyday use, and they don't make mistakes in simple structures. They can follow what's being said in normal conversations about work, school, interests, or topics that aren't completely new to them. They're also ready when traveling and can talk about things they know or like. They can start, maintain, and end conversations on familiar topics, sharing their thoughts and explaining things without too much trouble.",
    voyagers:
      "Voyagers at this level are becoming quite confident in their English. They can converse easily using a wide range of structures and can grasp the main point of conversations and texts even when they don't know every word. They're good at understanding both everyday and more complicated topics. When they speak, they can keep up with native English speakers without too much difficulty, making the conversation natural. They're also ready to participate in discussions on topics they know, sharing their thoughts clearly and in detail. Most of the time, they speak English correctly and make only minor errors. This level shows they've come a long way in learning English, now focusing on improving in expressing themselves clearly and understanding deeper or more complex ideas.",
    starters:
      "Starters are at the beginning of their English learning journey. They don't have previous experience with the language (or if they have very little experience) and they need extra support to take their first steps. This level give them the base they need to feel secure and face their new language journey with confidence. With an attentive guide and a patient approach, they will be able to get over their first hurdles and get ready for the next level.",
  },
  levelTitle: {
    explorers: "Explorer",
    navigators: "Navigator",
    pioneers: "Pioneer",
    voyagers: "Voyager",
    starters: "Starter",
  },
  megaMenu: {
    back: "Back",
  },
  lessonPlanManagement: {
    backToProfile: "Back to Profile",
    freezeWeekComponent: {
      title: "Suspend Real Life Conversations",
      explanation:
        "During the 6 months of the course, you can decide to suspend Real Life Conversations for up to 2 weeks, which equals 4 live lessons, without losing them. During the suspended weeks, you will still have access to all other content of Fluent Forever.",
      rememberTitle: "Remember that:",
      remember1: "The week runs from Sunday to Saturday",
      remember2:
        "The suspension must occur in advance; you cannot suspend a current or past week.",
      selectWeekToSuspend: "Select weeks to suspend",
      fromTo: "From {{from}} to {{to}}",
      alreadySuspendedAllWeeks:
        "You have already suspended all available weeks.",
      suspend: "Suspend",
      selectAtLeastOneWeek: "Select at least one week to suspend",
      freezeError: {
        generic: {
          title: "Oops",
          description:
            "There was an error during the week suspension, please try again",
        },
        alreadyBookedClassroom: {
          title: "Unable to suspend this week",
          description:
            "You have already booked classes for the week from {{from}} to {{to}}. If you want to suspend it, you must first cancel these classes",
        },
      },
    },
    frozenWeeks: {
      title: "Suspended Weeks",
      noSuspensionTitle: "No Suspension",
      noSuspensionDescription: "You have not suspended any weeks at this time",
      item: {
        fromTo: "From {{from}} to {{to}}",
        unfreezeError: {
          title: "Ops",
          description:
            "Something went wrong during the reactivation of the week, please try again.",
        },
      },
    },
  },
  lessonPlanManagementButton: {
    label: "Manage Study Plan",
  },
  somethingWentWrong: {
    title: "Something went wrong",
    description:
      "An error occurred while loading the page, please refresh the page and try again",
    reload: "Reload",
  },
  leaveClassRoomReviewScreen: {
    title: "Rate the lesson on {{date}} with the teacher {{teacherName}}",
    dateFormat: "ddd DD MMM YYYY [at] HH:mm",
    error: {
      title: "Oops!",
      description:
        "Something went wrong while trying to leave a review. Please try again later.",
    },
  },
  leaveClassroomReviewForm: {
    errors: {
      requireValueError: "Select a value between 1 and 5",
    },
    labels: {
      topicUsefulness:
        "How useful did you find the topic of the Real Life Conversation?",
      topicDifficulty:
        "How easy/difficult did you find the topic of the Real Life Conversation?",
      teacherClearance: "How clear was the teacher in explaining the concepts?",
      teacherInvolvement: "How engaging was the teacher during the lesson?",
      teacherComfortable:
        "How comfortable did the teacher make you feel during the lesson?",
      generalLesson:
        "How would you rate the overall experience of this Real Life Conversation?",
      notes: "Notes (optional)",
      topicUsefulnessScore: {
        veryLow: "Not useful at all",
        low: "Slightly useful",
        medium: "Fairly useful",
        high: "Very useful",
        veryHigh: "Extremely useful",
      },
      topicDifficultyScore: {
        veryLow: "Extremely difficult",
        low: "Very difficult",
        medium: "Ok",
        high: "Easy",
        veryHigh: "Very easy",
      },
      teacherClearanceScore: {
        veryLow: "Not clear at all",
        low: "Slightly clear",
        medium: "Fairly clear",
        high: "Clear",
        veryHigh: "Extremely clear",
      },
      teacherInvolvementScore: {
        veryLow: "Not engaging at all",
        low: "Slightly engaging",
        medium: "Fairly engaging",
        high: "Engaging",
        veryHigh: "Extremely engaging",
      },
      teacherComfortableScore: {
        veryLow: "Not comfortable at all",
        low: "Slightly comfortable",
        medium: "Fairly comfortable",
        high: "Very comfortable",
        veryHigh: "Extremely comfortable",
      },
      generalLessonScore: {
        veryLow: "Not positive at all",
        low: "Slightly positive",
        medium: "Fairly positive",
        high: "Very positive",
        veryHigh: "Extremely positive",
      },
    },
    section: {
      topic: "Topic - Real Life Conversation",
      teacher: "Teacher - Real Life Conversation",
      lesson: "Overall Experience - Real Life Conversation",
    },
    sendValuation: "Submit Review",
  },
  notFound: {
    title: "Oops! 🥺",
    description: "What you were looking for seems to no longer be here",
  },
  leaveReviewThankYouScreen: {
    title: "Thank you! 😊",
    description: "Your feedback is very important to us",
    cta: "Go to Fluent Forever",
  },
};
