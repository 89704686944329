import { PrimaryButton } from "../Button/PrimaryButton.tsx";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid/index.js";
import { PerkBox } from "./PerkBox.tsx";
import { ReactNode } from "react";

export function MissingTestComponent({
  title,
  description,
  cta,
  perks,
  topRightComponent,
}: {
  title: string;
  description: string;
  cta?: {
    label: string;
    action: () => void;
  };
  perks: {
    title: string;
    description: string;
    icon: ReactNode;
  }[];
  topRightComponent: ReactNode;
}) {
  return (
    <div className={"sm:tablet-bubble-bg md:desktop-bubble-bg flex-1 p-4"}>
      <div className={"max-w-[1230px] mx-auto"}>
        <div className={"flex-1 flex flex-col justify-center"}>
          <div
            className={
              "flex flex-col md:flex-row pb-20 pt-10 md:py-20 md:space-x-6 md:space-y-0"
            }
          >
            <div className={"space-y-4 flex-1 flex justify-center flex-col"}>
              <h1 className={"font-semibold text-4xl text-center md:text-left"}>
                {title}
              </h1>
              <p className={"font-light text-lg text-center md:text-left"}>
                {description}
              </p>
              {cta ? (
                <div className={"mx-auto md:ml-0 md:mr-auto"}>
                  <PrimaryButton
                    label={cta.label}
                    onClick={cta.action}
                    iconRight={<ArrowLongRightIcon className={"w-5"} />}
                  />
                </div>
              ) : null}
            </div>
            <div
              className={
                "flex-1 md:py-0 pt-36 pb-20 sm:bg-none mobile-bubble-bg"
              }
            >
              {topRightComponent}
            </div>
          </div>
          {perks.length > 0 ? (
            <div
              className={
                "flex flex-col md:flex-row md:space-x-6 space-y-14 md:space-y-0 mt-0 md:mt-32"
              }
            >
              {perks.map((p, index) => (
                <div className={"flex-1"} key={index}>
                  <PerkBox
                    title={p.title}
                    description={p.description}
                    icon={p.icon}
                  />
                </div>
              ))}
            </div>
          ) : null}
          {cta ? (
            <div className={"flex py-6"}>
              <div className={"mx-auto md:hidden"}>
                <PrimaryButton
                  label={cta.label}
                  onClick={cta.action}
                  iconRight={<ArrowLongRightIcon className={"w-5"} />}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
