import { useAuthCtx } from "./useAuthCtx.ts";
import { isCompleteUser } from "./isCompleteUser.tsx";
import { Navigate } from "react-router-dom";
import { PropsWithChildren } from "react";

export function RequireUserNotComplete({ children }: PropsWithChildren) {
  const { user } = useAuthCtx();

  if (isCompleteUser(user)) {
    return <Navigate to={"/"} replace />;
  }

  return children ?? null;
}
