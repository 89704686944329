import { CenteredLoading } from "./CenteredLoading.tsx";

export function FullPageLoading() {
  return (
    <div className={"min-h-screen min-w-screen flex flex-col"}>
      <div className={"w-full h-full flex items-center justify-center flex-1"}>
        <CenteredLoading />
      </div>
    </div>
  );
}
