import { PropsWithChildren } from "react";
import { classNames } from "../Utils/classNames.ts";
import { LabelSize } from "./LabelSize.tsx";

export function InputWithLabel({
  children,
  label,
  labelSize = LabelSize.Small,
}: PropsWithChildren<{
  label: string;
  labelSize?: LabelSize;
}>) {
  return (
    <div className={"w-full"}>
      <label className={"flex flex-col"}>
        <span
          className={classNames(
            "block mb-2 font-medium text-gray-900 dark:text-white",
            labelSize,
          )}
        >
          {label}
        </span>
        {children}
      </label>
    </div>
  );
}
