import { Interval } from "./Interval.tsx";
import { CalendarItemMap } from "./CalendarItemMap.ts";
import { ReactNode, useState } from "react";
import { DesktopCalendarComponent } from "./DesktopCalendarComponent.tsx";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { CalendarDatePicker } from "../UI/DatePicker/CalendarDatePicker.tsx";
import { maxDate } from "../DataUtils/maxDate.tsx";
import { MobileCalendarComponent } from "./MobileCalendarComponent.tsx";
import { DisabledEyeIcon } from "../UI/DisabledEyeIcon.tsx";
function CalendarDisabledComponent({ desc }: { desc: string }) {
  return (
    <div
      className={
        "flex flex-col items-center h-full flex-1 justify-center space-y-4 p-3"
      }
    >
      <DisabledEyeIcon />
      <div className={"space-y-2"}>
        <p className={"text-center max-w-md"}>{desc}</p>
      </div>
    </div>
  );
}
function CalendarDisabledAfterThreeWeeksInFuture() {
  const { t } = useTranslation("notVisibleAvailability");
  return <CalendarDisabledComponent desc={t("desc")} />;
}
export function WeeklyCalendar<T>({
  interval,
  items,
  loading,
  onChangeInterval,
  itemRenderer,
  emptyDayComponent,
  canGoToPrevWeek,
  disabled,
}: {
  interval: Interval;
  items: CalendarItemMap<T>;
  loading: boolean;
  onChangeInterval: (interval: Interval) => void;
  itemRenderer: (item: T) => ReactNode;
  emptyDayComponent: ReactNode;
  canGoToPrevWeek: boolean;
  disabled?: {
    isDisabled: boolean;
    reason: string;
  };
}) {
  const { t } = useTranslation("weeklyCalendar");
  const selectedDate = maxDate(dayjs(), interval.fromDate).toDate();
  const [selectedDay, setSelectedDay] = useState<Dayjs>();
  const maxStartDate = dayjs().startOf("week").add(3, "week");
  const maxEndDate = maxStartDate.endOf("week");
  const afterMaxWeeks = interval.fromDate.isAfter(maxStartDate, "day");
  const isDisabled = afterMaxWeeks || (disabled?.isDisabled ?? false);
  const datePickerComponent: (renderProps: {
    close: () => void;
  }) => ReactNode = ({ close }) => (
    <CalendarDatePicker
      isSelectedDay={(d) => dayjs(selectedDate).isSame(d, "week")}
      selectedDate={selectedDate}
      onChange={(date) => {
        const d = dayjs(date);
        onChangeInterval(new Interval(d.startOf("week"), d.endOf("week")));
        setSelectedDay(d);
        close();
      }}
      canNavigateBack={(interval) =>
        dayjs(interval.fromDate).isAfter(dayjs(), "month")
      }
      isDisabled={(day) =>
        dayjs(day).isBefore(dayjs(), "day") ||
        dayjs(day).isAfter(maxEndDate, "day")
      }
    />
  );
  return (
    <>
      <div className={"hidden md:flex flex-1 flex-col"}>
        <DesktopCalendarComponent
          canGoToNextPeriod={!afterMaxWeeks}
          canGoToPreviousPeriod={canGoToPrevWeek}
          emptyDayComponent={emptyDayComponent}
          previousPeriodLabel={t("previousWeek")}
          nextPeriodLabel={t("nextWeek")}
          interval={interval}
          onChangeInterval={onChangeInterval}
          loading={loading}
          items={items}
          itemRenderer={itemRenderer}
          datePickerComponent={datePickerComponent}
          disabled={{
            disabled: isDisabled,
            disabledComponent: disabled?.isDisabled ? (
              <CalendarDisabledComponent desc={disabled.reason} />
            ) : (
              <CalendarDisabledAfterThreeWeeksInFuture />
            ),
          }}
        />
      </div>
      <div className={"flex flex-col md:hidden flex-1"}>
        <MobileCalendarComponent
          scrollToDay={selectedDay}
          items={items}
          interval={interval}
          itemRenderer={itemRenderer}
          onChangeInterval={onChangeInterval}
          loading={loading}
          emptyDayComponent={emptyDayComponent}
          datePickerComponent={datePickerComponent}
          disabled={{
            disabled: isDisabled,
            disabledComponent: disabled?.isDisabled ? (
              <CalendarDisabledComponent desc={disabled.reason} />
            ) : (
              <CalendarDisabledAfterThreeWeeksInFuture />
            ),
          }}
        />
      </div>
    </>
  );
}
