import { SpinnerContainer } from "../../UI/Loading/SpinnerContainer.tsx";
import { LoaderSize } from "../../UI/Loading/LoaderSize.tsx";
import { BookmarkIcon } from "@heroicons/react/24/solid/index.js";
import { useRemainingLessonPerWeek } from "./useRemainingLessonPerWeek.ts";
import { Interval } from "../../Speaking/Interval.tsx";
import { Trans } from "react-i18next";
import { TI } from "../../I18n/TI.ts";

type Props = {
  interval: Interval;
};
export function WeekRemainingLessons({ interval }: Props) {
  const {
    remainingLessonCount,
    totalLessonCount,
    loading: loadingRemainingLessons,
  } = useRemainingLessonPerWeek({
    date: interval.fromDate,
  });
  return (
    <SpinnerContainer size={LoaderSize.Small} loading={loadingRemainingLessons}>
      <div
        className={
          "shadow-slate-100/70 border border-primary shadow-lg px-5 py-2 flex items-center space-x-2 bg-white rounded-lg"
        }
      >
        <BookmarkIcon className={"w-5"} />
        <span>
          <Trans ns={"weeklyRemainingLessons"} i18nKey={"remainingLessons"}>
            Prenotazioni settimanali rimanenti:{" "}
            <b>
              {{ remainingLessonCount } as TI} di {{ totalLessonCount } as TI}
            </b>
          </Trans>
        </span>
      </div>
    </SpinnerContainer>
  );
}
