import { ClassRoomFragmentFragment } from "../../gql/graphql.ts";
import { ClassRoomCalendarCardItemDTO } from "./ClassRoomCalendarCardItemDTO.tsx";

export function convertClassRoomFragmentToClassRoomCalendarCardItem(
  c: ClassRoomFragmentFragment,
): ClassRoomCalendarCardItemDTO {
  return {
    capacity: c.capacity,
    endedAtUtc: c.endedAtUtc,
    id: c.id,
    participantsCount: c.participantsCount,
    speakingLevel: c.speakingLevel,
    startedAtUtc: c.startedAtUtc,
    topic: {
      id: c.topic.id,
      level: c.topic.level,
      name: c.topic.name,
      pdfUrl: c.topic.pdfUrl,
    },
    type: c.type,
    meetingUri: c.meetingUri,
    canBeLeaved: c.canBeLeaved,
    timezone: c.classRoomTimezone,
  };
}
