import { PropsWithChildren } from "react";
import { useAuthCtx } from "./useAuthCtx.ts";
import { Navigate } from "react-router-dom";
import { CompleteUserDataScreenPath } from "./CompleteUserDataScreen.tsx";
import { isCompleteUser } from "./isCompleteUser.tsx";

type Props = PropsWithChildren;

export function RequireUserData({ children }: Props) {
  const { user } = useAuthCtx();

  if (!isCompleteUser(user)) {
    return <Navigate to={CompleteUserDataScreenPath} replace />;
  }

  return children ?? null;
}
