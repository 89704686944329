import { ClassRoomType } from "../gql/graphql.ts";
import { Modal } from "../UI/Modal/Modal.tsx";
import { classNames } from "../UI/Utils/classNames.ts";
import dayjs from "dayjs";
import {
  ChevronLeftIcon,
  ClockIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid/index.js";
import { useTranslation } from "react-i18next";
import { CompleteUserNavBar } from "../UI/Layout/CompleteUserNavBar.tsx";
import { Dialog } from "@headlessui/react";
import { Badge } from "../UI/Badge/Badge.tsx";
import { Footer } from "../UI/Layout/Footer.tsx";
import { CalendarCardComponentProps } from "./CalendarCardComponentProps.tsx";
import {
  CalendarCardSlotDetail,
  SlotInfoLine,
} from "./CalendarCardSlotDetail.tsx";

export function CalendarCardComponent(props: CalendarCardComponentProps) {
  const {
    title,
    startDateUtc,
    participantsCount,
    capacity,
    type,
    showLessonTopicBadge,
    showModal,
    onOpen,
    onClose,
    slotTimezone,
  } = props;
  const { t, i18n } = useTranslation("calendarCardComponent");
  const isDifferentFromLocalTimezone = dayjs.tz.guess() !== slotTimezone;
  function getFormatHourString() {
    return isDifferentFromLocalTimezone ? "HH:mm z" : "HH:mm";
  }

  function getHourString(): string {
    const parts: string[] = [];
    parts.push(
      dayjs(startDateUtc)
        .tz(slotTimezone)
        .locale(i18n.language)
        .format(getFormatHourString()),
    );
    if (isDifferentFromLocalTimezone)
      parts.push(
        `(${t("localTimeHour", {
          hour: dayjs(startDateUtc).locale(i18n.language).format("HH:mm"),
        })})`,
      );
    parts.push("(50min)");
    return parts.join(" ");
  }

  function getBorderColorClass(): string {
    switch (type) {
      case ClassRoomType.Exam:
        return "border-norma-purple";
      default:
        return "border-norma-cyan";
    }
  }

  function getColorClass(type: ClassRoomType): string {
    switch (type) {
      case ClassRoomType.SpeakingClass:
        return "bg-norma-cyan bg-opacity-20 text-primary";
      case ClassRoomType.Exam:
        return "bg-norma-purple bg-opacity-20 text-norma-purple";
      default:
        return "";
    }
  }

  const wClass = "md:max-w-[400px] md:min-w-[300px] relative";
  return (
    <>
      <Modal
        isOpen={showModal}
        onClose={onClose}
        title={
          <span className={classNames(wClass, "block font-bold")}>{title}</span>
        }
        overridePanel={({ title, children }) => {
          return (
            <Dialog.Panel
              className={classNames(
                "flex-1 md:flex-auto md:max-w-md transform overflow-hidden md:rounded-2xl bg-white text-left align-middle shadow-xl transition-all flex flex-col",
                getBorderColorClass(),
                "md:border-t-[7px]",
              )}
            >
              <div className={"block md:hidden"}>
                <CompleteUserNavBar />
              </div>
              <div className={"p-6 flex-1"}>
                <div className={"block md:hidden mb-4"}>
                  <a
                    className={
                      "flex items-center space-x-1 text-xs font-semibold cursor-pointer"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      onClose();
                    }}
                  >
                    <ChevronLeftIcon className={"w-3"} />
                    <span>{t("backToCalendar")}</span>
                  </a>
                </div>
                {showLessonTopicBadge ? (
                  <div className={"flex md:hidden mb-1"}>
                    <Badge
                      colorClass={getColorClass(type)}
                      title={t("lessonTopic")}
                    />
                  </div>
                ) : null}
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                <div className={"mt-4"}>{children ?? null}</div>
              </div>
              <div className={"md:hidden"}>
                <Footer />
              </div>
            </Dialog.Panel>
          );
        }}
      >
        <div className={wClass}>
          <CalendarCardSlotDetail {...props} />
        </div>
      </Modal>
      <div
        onClick={onOpen}
        className={classNames(
          "p-2 border-t-4 bg-white rounded-2xl shadow-xl shadow-slate-200/50 text-sm space-y-3 cursor-pointer",
          getBorderColorClass(),
        )}
      >
        <h3
          className={
            "whitespace-nowrap overflow-ellipsis overflow-hidden font-bold"
          }
        >
          {title}
        </h3>
        <div className={"space-y-1"}>
          <SlotInfoLine
            text={getHourString()}
            icon={<ClockIcon className={"h-4 w-4"} />}
          />
          <SlotInfoLine
            text={t("participants", {
              participantsCount,
              capacity,
            })}
            icon={<UserGroupIcon className={"h-4 w-4"} />}
          />
        </div>
      </div>
    </>
  );
}
