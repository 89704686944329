import { graphql } from "../../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { BookSlotInput } from "../../gql/graphql.ts";
import { GetSpeakingClassAvailabilityQuery } from "./useSpeakingClassAvailability.ts";
import { GetRemainingLessonPerWeek } from "../../User/Plan/useRemainingLessonPerWeek.ts";
import { GetMyRemainingLessons } from "../../User/Plan/useMyRemainingLessons.ts";
import { GetUserClassRoomsBetweenDates } from "../BookedClassRoom/useMyClassRoomBetweenDates.ts";
import { GetUserClassRoomCountQuery } from "../../User/useUserClassRoomCount.ts";

const BookSpeakingClassMutation = graphql(`
  mutation bookSpeakingClass($bookSlotInput: BookSlotInput!) {
    bookSpeakingClass(bookSlotInput: $bookSlotInput)
  }
`);
export function useBookSpeakingClass() {
  const [bookSpeakingClassMutation, { loading }] = useMutation(
    BookSpeakingClassMutation,
    {
      refetchQueries: [
        GetSpeakingClassAvailabilityQuery,
        GetRemainingLessonPerWeek,
        GetMyRemainingLessons,
        GetUserClassRoomsBetweenDates,
        GetUserClassRoomCountQuery,
      ],
      awaitRefetchQueries: true,
    },
  );

  const bookSpeakingClass = useCallback(
    async (input: BookSlotInput) => {
      await bookSpeakingClassMutation({
        variables: {
          bookSlotInput: input,
        },
      });
    },
    [bookSpeakingClassMutation],
  );

  return {
    bookSpeakingClass,
    loading,
  };
}
