import { useTranslation } from "react-i18next";
import { FooterLanguageSelector } from "./FooterLanguageSelector.tsx";
import dayjs from "dayjs";

function FooterLink({ label, href }: { href: string; label: string }) {
  return (
    <p className={"text-center"}>
      <a
        target={"_blank"}
        rel={"noopener noreferrer"}
        className={"underline"}
        href={href}
      >
        {label}
      </a>
    </p>
  );
}

export function Footer() {
  const { t } = useTranslation("footer");

  return (
    <div
      className={
        "flex flex-col md:flex-row p-5 bg-slate-700 text-white text-xs space-y-4 md:space-y-0 md:justify-between"
      }
    >
      <p className={"text-center"}>
        Norma's Teaching S.r.l. © {dayjs().format("YYYY")}
      </p>
      <div
        className={
          "flex flex-col items-center sm:flex-row sm:justify-center sm:space-x-2 sm:space-y-0 space-y-2"
        }
      >
        <div className={"text-black"}>
          <FooterLanguageSelector />
        </div>
        <div className={"flex space-x-2"}>
          <FooterLink
            href={"https://normasteaching.com/legal-info/"}
            label={t("privacyPolicy")}
          />
          <FooterLink
            href={"https://content.normasteaching.com/pages/help"}
            label={t("helpCenter")}
          />
        </div>
      </div>
    </div>
  );
}
