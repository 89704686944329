import { Dialog, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren, ReactNode } from "react";
import { classNames } from "../Utils/classNames.ts";

type Props = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  title: string | ReactNode;
  overridePanel?: (input: {
    title: string | ReactNode;
    children: ReactNode;
  }) => ReactNode;
}>;

export function Modal({
  onClose,
  isOpen,
  children,
  title,
  overridePanel,
}: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames("relative z-10")}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={classNames("fixed inset-0 bg-black/25")} />
        </Transition.Child>

        <div className={classNames("fixed inset-0 overflow-y-auto")}>
          <div className="flex min-h-full md:items-center md:justify-center md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {overridePanel ? (
                overridePanel({ title, children })
              ) : (
                <Dialog.Panel className="flex-1 md:flex-auto md:max-w-md transform overflow-hidden md:rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <div className={"mt-4"}>{children ?? null}</div>
                </Dialog.Panel>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
