import { PropsWithChildren } from "react";
import { CompleteUserNavBar } from "./CompleteUserNavBar.tsx";
import { SideMenu } from "./SideMenu.tsx";
import { classNames } from "../Utils/classNames.ts";
import { Footer } from "./Footer.tsx";
import { ImpersonationRepository } from "../../Impersonation/ImpersonationRepository.ts";
import { ImpersonationTopNotice } from "../../Impersonation/ImpersonationTopNotice.tsx";

type Props = PropsWithChildren<{
  maxWClass?: string;
  removePadding?: boolean;
}>;

const isImpersonating = ImpersonationRepository.hasUid();
export function Layout({ children, maxWClass, removePadding }: Props) {
  return (
    <div>
      {isImpersonating ? <ImpersonationTopNotice /> : null}
      <div className={"flex flex-col min-h-screen relative"}>
        <div className={"z-10 sticky top-0"}>
          <CompleteUserNavBar />
        </div>

        <div className={"flex-row flex-1 flex"}>
          <div className={"sticky top-16 h-[calc(100vh-4rem)] shrink-0"}>
            <SideMenu />
          </div>
          <div className={"flex-1 flex flex-col overflow-hidden"}>
            <div className={"flex-1 bg-slate-100/50"}>
              <div
                className={classNames(
                  "mx-auto min-h-full flex flex-col",
                  maxWClass ? maxWClass : "max-w-[1024px]",
                  removePadding ? "" : "p-4",
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
