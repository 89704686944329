import { createPortal } from "react-dom";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
type Props = {
  title: string;
  description: string;
  show: boolean;
  onClose: () => void;
};
export function SuccessMessagePopup({
  title,
  description,
  show,
  onClose,
}: Props) {
  return show
    ? createPortal(
        <div
          className={
            "fixed max-w-md top-4 right-4 z-10 bg-green-500 p-3 rounded-md text-white flex space-x-2 items-center"
          }
        >
          <div className={"rounded-full p-2 bg-green-400"}>
            <ExclamationCircleIcon className={"h-7 w-7"} />
          </div>
          <div>
            <div className={"flex space-x-2 justify-between items-center"}>
              <h4 className={"font-bold"}>{title}</h4>
              <XMarkIcon onClick={onClose} className={"w-5 cursor-pointer"} />
            </div>
            <div className={"font-light"}>{description}</div>
          </div>
        </div>,
        window.document.body,
      )
    : null;
}
