import { useCurrentUserOrThrow } from "./useCurrentUserOrThrow.ts";
import { SpeakingLevel } from "../gql/graphql.ts";
import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { MissingSpeakingTestScreenPath } from "../User/MissingSpeakingTestScreen.tsx";

export function RequireUserSpeakingLevel({ children }: PropsWithChildren) {
  const { user } = useCurrentUserOrThrow();

  if (user.speakingLevel === SpeakingLevel.Unknown)
    return <Navigate to={MissingSpeakingTestScreenPath} replace />;

  return children ?? null;
}
