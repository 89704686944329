import { graphql } from "../../../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { GetMyFrozenWeeksQuery } from "./useMyFrozenWeeks.ts";
import { GetFreezableWeeksQuery } from "./useFreezableWeeks.ts";

const FreezeWeekMutation = graphql(`
  mutation addFrozenWeek($week: FrozenWeekInput!) {
    addFrozenWeek(week: $week)
  }
`);
export function useFreezeWeek() {
  const [freezeWeekMutation, { loading }] = useMutation(FreezeWeekMutation);
  const freezeWeek = useCallback(
    async ({
      startOfWeekDateString,
      endOfWeekDateString,
    }: {
      startOfWeekDateString: string;
      endOfWeekDateString: string;
    }) => {
      await freezeWeekMutation({
        variables: {
          week: {
            startOfWeekDate: startOfWeekDateString,
            endOfWeekDate: endOfWeekDateString,
          },
        },
        refetchQueries: [GetMyFrozenWeeksQuery, GetFreezableWeeksQuery],
      });
    },
    [freezeWeekMutation],
  );

  return {
    freezeWeek,
    loading,
  };
}
