import { PropsWithChildren } from "react";
import { useAuthCtx } from "../../Auth/useAuthCtx.ts";
import { Navigate } from "react-router-dom";
import { NotActiveScreenPath } from "./NotActiveScreen.tsx";

type Props = PropsWithChildren;
export function RequireActiveUser({ children }: Props) {
  const { isActiveUser } = useAuthCtx();
  if (!isActiveUser) return <Navigate to={NotActiveScreenPath} />;

  return children ?? null;
}
