import { useCallback, useState } from "react";
import { useHandleOpenedSlotQueryString } from "./useHandleOpenedSlotQueryString.ts";

export function useControlCalendarCardModal(id: string) {
  const { setOpenedSlot, openedSlotId } = useHandleOpenedSlotQueryString();
  const [showModal, setShowModal] = useState(openedSlotId === id);
  const onClose = useCallback(() => {
    setShowModal(false);
    setOpenedSlot(undefined);
  }, [setOpenedSlot]);

  const onOpen = useCallback(() => {
    setShowModal(true);
    setOpenedSlot(id);
  }, [setOpenedSlot, id]);

  return { showModal, onClose, onOpen };
}
