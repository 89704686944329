export function UserLetterProfileImage({
  givenName,
  familyName,
}: {
  familyName: string;
  givenName: string;
}) {
  return (
    <div
      className={
        "h-8 w-8 text-xs text-white rounded-full bg-norma-fucsia flex items-center justify-center select-none"
      }
    >
      {givenName.charAt(0).toUpperCase() + familyName.charAt(0).toUpperCase()}
    </div>
  );
}
