export function TextButton({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) {
  return (
    <a
      className={
        "cursor-pointer underline hover:no-underline font-semibold underline-offset-2"
      }
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {label}
    </a>
  );
}
