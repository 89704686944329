import { LayoutWithOnlyNavbar } from "../UI/Layout/LayoutWithOnlyNavbar.tsx";
import { useClassRoomIdParamsOrThrow } from "./useClassRoomIdParamsOrThrow.ts";
import { useClassRoomByIdV2 } from "./useClassRoomByIdV2.ts";
import { useLeaveClassRoomReview } from "./useLeaveClassRoomReview.ts";
import { NavBarDropDownWithOnlyLogout } from "../UI/Layout/NavBarDropDown/NavBarDropDownWithOnlyLogout.tsx";
import {
  LeaveClassRoomReviewForm,
  LeaveClassRoomReviewFormInput,
} from "./LeaveClassRoomReviewForm.tsx";
import { FullPageLoading } from "../UI/Loading/FullPageLoading.tsx";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Card } from "../UI/Card.tsx";
import { NotFoundLoggedInScreen } from "../UI/NotFound/NotFoundLoggedInScreen.tsx";
import { PrimaryTag } from "../UI/Tag/PrimaryTag.tsx";
import { useNavigate } from "react-router-dom";
import { LeaveClassRoomReviewThankYouScreenPath } from "./LeaveClassRoomReviewThankYouScreen.tsx";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";

export const LeaveClassRoomReviewScreenPath =
  "/classroom/:classRoomId/leave-review";
export function LeaveClassRoomReviewScreen() {
  const { classRoomId } = useClassRoomIdParamsOrThrow();

  const { i18n, t } = useTranslation("leaveClassRoomReviewScreen");
  const { loading, classRoom } = useClassRoomByIdV2(classRoomId);
  const { leaveClassRoomReview } = useLeaveClassRoomReview();
  const navigate = useNavigate();
  const { title, description, show, showPopup, closePopup } =
    useMessagePopupController({
      timeoutInMs: 5 * 1000,
    });

  function formatDate(date: string): string {
    const stringFormat = t("dateFormat");
    return dayjs(date).locale(i18n.language).format(stringFormat);
  }

  async function sendReview(data: LeaveClassRoomReviewFormInput) {
    try {
      await leaveClassRoomReview({
        classRoomId: classRoom!.id,
        teacherClearanceScore: data.teacherClearanceScore,
        teacherComfortableScore: data.teacherComfortableScore,
        teacherInvolvementScore: data.teacherInvolvementScore,
        generalLessonScore: data.generalLessonScore,
        topicDifficultyScore: data.topicDifficultyScore,
        topicUsefulnessScore: data.topicUsefulnessScore,
        reviewNotes: data.reviewNotes ?? "",
      });
      navigate(LeaveClassRoomReviewThankYouScreenPath, {
        replace: true,
      });
    } catch (e) {
      showPopup(t("error.title"), t("error.description"));
    }
  }
  if (loading) return <FullPageLoading />;
  if (!classRoom) return <NotFoundLoggedInScreen />;
  return (
    <>
      <ErrorMessagePopup
        title={title}
        description={description}
        show={show}
        onClose={closePopup}
      />
      <LayoutWithOnlyNavbar navBarRight={<NavBarDropDownWithOnlyLogout />}>
        <div className={"space-y-6"}>
          <div className={"space-y-2"}>
            <PrimaryTag text={classRoom.topic.name} />
            <h1 className={"font-bold text-xl"}>
              {t("title", {
                date: formatDate(classRoom.startedAtUtc),
                teacherName: classRoom.teacher.givenName,
              })}
            </h1>
          </div>
          <Card>
            <LeaveClassRoomReviewForm onSave={sendReview} />
          </Card>
        </div>
      </LayoutWithOnlyNavbar>
    </>
  );
}
