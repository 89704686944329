import { BookableSlotFragmentFragment } from "../gql/graphql.ts";
import { CalendarItemMap } from "./CalendarItemMap.ts";
import { SlotDayAndHourKey } from "./SlotDayAndHourKey.tsx";
import dayjs from "dayjs";

export function convertSlotToSlotByDateAndHourMap(
  slots: BookableSlotFragmentFragment[],
): CalendarItemMap<BookableSlotFragmentFragment> {
  const map = new CalendarItemMap<BookableSlotFragmentFragment>();
  slots.forEach((s) => {
    const key = SlotDayAndHourKey.fromDate(
      dayjs(s.startDateUtc).tz(s.slotTimezone),
    );
    map.set(key, s);
  });
  return map;
}
