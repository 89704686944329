import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import { MyProfileTab } from "./ProfileScreen/MyProfileTab.tsx";

export function useMyProfileSelectedTab() {
  const selectedTabParamName = "t";
  const [searchParams, setSearchParams] = useSearchParams();
  const getSelectedTabUrl = useCallback((url: URL, tab: MyProfileTab): URL => {
    const newUrl = new URL(url.toString());
    newUrl.searchParams.set(selectedTabParamName, tab);
    return newUrl;
  }, []);

  const setSelectedTab = useCallback(
    (tab: MyProfileTab) => {
      searchParams.set(selectedTabParamName, tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const selectedTab = searchParams.get(selectedTabParamName) as MyProfileTab;

  return {
    selectedTab,
    getSelectedTabUrl,
    setSelectedTab,
  };
}
