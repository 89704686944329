import { ReactNode } from "react";
import { useHasSpeakingEnabled } from "./useHasSpeakingEnabled.ts";
import { CenteredLoading } from "../UI/Loading/CenteredLoading.tsx";

type Props = {
  ifEnabled: ReactNode;
  ifDisabled: ReactNode;
};
export function HasSpeakingEnabledGuard({ ifEnabled, ifDisabled }: Props) {
  const { hasSpeakingEnabled, loading } = useHasSpeakingEnabled();

  if (loading)
    return (
      <div className={"min-h-screen flex"}>
        <CenteredLoading />
      </div>
    );

  return hasSpeakingEnabled ? ifEnabled : ifDisabled;
}
