import { useState } from "react";
import { Interval } from "../Interval.tsx";
import { createTodayInterval } from "../createTodayInterval.ts";
import { Layout } from "../../UI/Layout/Layout.tsx";
import { WeekRemainingLessons } from "../../User/Plan/WeekRemainingLessons.tsx";
import { HorizontalLineDivider } from "../../UI/HorizontalLineDivider.tsx";
import { convertSlotToSlotByDateAndHourMap } from "../convertSlotToSlotByDateAndHourMap.tsx";
import { SlotCalendarCard } from "../SlotCalendarCard.tsx";
import { useProgressCheckAvailability } from "./useProgressCheckAvailability.ts";
import { WeeklyCalendar } from "../WeeklyCalendar.tsx";
import { pad } from "../../DataUtils/pad.ts";
import { Navigate, useNavigate } from "react-router-dom";
import { useHasUnlockedProgressCheckInfo } from "./useHasUnlockedProgressCheckInfo.ts";
import { CenteredLoading } from "../../UI/Loading/CenteredLoading.tsx";
import { HasSpeakingEnabledGuard } from "../HasSpeakingEnabledGuard.tsx";
import { SomethingWentWrong } from "../SomethingWentWrong.tsx";
import { EmptyCalendarMessage } from "../EmptyCalendarMessage.tsx";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ScrollToTop } from "../../UI/Layout/ScrollToTop.tsx";
import { BookSpeakingLessonCalendarScreenPath } from "../SpeakingLesson/BookSpeakingLessonCalendarScreenPath.tsx";
import { EmptySectionComponent } from "../EmptySectionComponent.tsx";
import { useIsFrozenWeek } from "../../User/Plan/FreezeWeek/useIsFrozenWeek.ts";
import { useCurrentUserOrThrow } from "../../Auth/useCurrentUserOrThrow.ts";
import { BookedClassRoomCalendarScreenPath } from "../BookedClassRoom/BookedClassRoomCalendarScreen.tsx";

export const BookProgressCheckCalendarScreenPath = "/book-progress-check";
const dateFormat = "YYYY-MM-DD";
function BookProgressCheckCalendarScreenInner() {
  const [interval, setInterval] = useState<Interval>(createTodayInterval());

  const { user } = useCurrentUserOrThrow();
  const navigate = useNavigate();

  const { t } = useTranslation("bookProgressCheckScreen");
  const { bookableSlots, loading, hasAtLeastOnProgressCheckBookable } =
    useProgressCheckAvailability({
      interval,
    });

  const {
    hasUnlockedProgressCheck,
    totalLessonNeededToUnlockProgressCheck,
    lessonCompletedToUnlockProgressCheck,
    loading: loadingHasUnlockedProgressCheck,
  } = useHasUnlockedProgressCheckInfo();

  const { isFrozenWeek, loading: loadingIsFrozenWeek } = useIsFrozenWeek({
    startOfWeekDate: interval.fromDate.format(dateFormat),
    endOfWeekDate: interval.toDate.format(dateFormat),
  });

  if (loadingHasUnlockedProgressCheck) return <CenteredLoading />;

  return (
    <div className={"flex flex-col flex-1"}>
      <div
        className={"hidden md:flex items-center justify-between space-x-2 mb-4"}
      >
        <h1 className={"font-bold text-2xl"}>{t("title")}</h1>
        {hasUnlockedProgressCheck ? (
          <div>
            <WeekRemainingLessons interval={interval} />
          </div>
        ) : null}
      </div>
      {hasUnlockedProgressCheck ? (
        <>
          <div className={"w-full hidden md:block mb-4"}>
            <HorizontalLineDivider />
          </div>
          <WeeklyCalendar
            canGoToPrevWeek={interval.fromDate.isAfter(dayjs())}
            emptyDayComponent={
              <EmptyCalendarMessage message={t("emptyCalendarDay")} />
            }
            interval={interval}
            onChangeInterval={setInterval}
            loading={loading || loadingIsFrozenWeek}
            items={convertSlotToSlotByDateAndHourMap(bookableSlots)}
            itemRenderer={(slot) => (
              <SlotCalendarCard
                hasAtLeastOnProgressCheckBookable={
                  hasAtLeastOnProgressCheckBookable
                }
                slot={slot}
              />
            )}
            disabled={{
              isDisabled: isFrozenWeek,
              reason: t("frozenWeekDisabled"),
            }}
          />
        </>
      ) : (
        <EmptySectionComponent
          title={t("noProgressCheck.title")}
          description={t("noProgressCheck.subTitle")}
          cta={{
            label: t("noProgressCheck.goToLessonCalendar"),
            onClick: () => navigate(BookSpeakingLessonCalendarScreenPath),
            hidden: user.hideBookSpeakingClassSection,
          }}
          progress={{
            total: totalLessonNeededToUnlockProgressCheck,
            current: lessonCompletedToUnlockProgressCheck,
            progressText: t("noProgressCheck.lessonsAttended", {
              lessonCompletedToUnlockProgressCheck:
                lessonCompletedToUnlockProgressCheck > 0
                  ? pad(lessonCompletedToUnlockProgressCheck)
                  : "0",
              totalLessonNeededToUnlockProgressCheck: pad(
                totalLessonNeededToUnlockProgressCheck,
              ),
            }),
          }}
        />
      )}
    </div>
  );
}

export function BookProgressCheckCalendarScreen() {
  const { user } = useCurrentUserOrThrow();
  if (user.hideBookProgressCheckSection)
    return <Navigate to={BookedClassRoomCalendarScreenPath} />;
  return (
    <>
      <ScrollToTop />

      <HasSpeakingEnabledGuard
        ifDisabled={<SomethingWentWrong />}
        ifEnabled={
          <Layout maxWClass={"max-w-full"}>
            <BookProgressCheckCalendarScreenInner />
          </Layout>
        }
      />
    </>
  );
}
