import { graphql } from "../../gql";

export const ClassRoomV2Fragment = graphql(`
  fragment ClassRoomV2 on ClassRoomV2 {
    id
    endedAtUtc
    startedAtUtc
    topic {
      name
      id
      level
      pdfUrl
    }
    type
    speakingLevel
    capacity
    participantsCount
    meetingUri
    canBeLeaved
    classRoomTimezone
    teacher {
      id
      givenName
      familyName
    }
  }
`);
