import { TFunction } from "i18next";
import { ReactNode } from "react";
import {
  BeakerIcon,
  BoltIcon,
  BookmarkIcon,
  CalendarDaysIcon,
  CheckBadgeIcon,
  LifebuoyIcon,
  PuzzlePieceIcon,
  StarIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid/index.js";
import { BookProgressCheckCalendarScreenPath } from "../../Speaking/PorgressCheck/BookProgressCheckCalendarScreen.tsx";
import { BookedClassRoomCalendarScreenPath } from "../../Speaking/BookedClassRoom/BookedClassRoomCalendarScreen.tsx";
import { BookSpeakingLessonCalendarScreenPath } from "../../Speaking/SpeakingLesson/BookSpeakingLessonCalendarScreenPath.tsx";
import { ContentPlatformKnownPath } from "../../ContentPlatform/ContentPlatformKnownPath.ts";
import { getContentPlatformUrl } from "../../ContentPlatform/getContentPlatformUrl.tsx";
import { StartHereScreenPath } from "../../Help/StartHereScreen.tsx";

export const getRoutes = (
  t: TFunction,
  hideOptions?: Partial<{
    hideBookSpeakingClass: boolean;
    hideBookProgressCheck: boolean;
  }>,
): { to: string; label: string; icon: ReactNode; externalUrl?: boolean }[] => {
  const bookSpeakingClassLabel = "lessonCalendar";
  const bookProgressCheckLabel = "progressCheckCalendar";
  return [
    {
      label: "mindset",
      to: getContentPlatformUrl(ContentPlatformKnownPath.Mindset),
      icon: <PuzzlePieceIcon className={"h-5 w-5"} />,
      externalUrl: true,
    },
    {
      label: "realLifeSkills",
      to: getContentPlatformUrl(ContentPlatformKnownPath.Skills),
      icon: <BoltIcon className={"h-5 w-5"} />,
      externalUrl: true,
    },
    {
      label: bookSpeakingClassLabel,
      to: BookSpeakingLessonCalendarScreenPath,
      icon: <CalendarDaysIcon className={"h-5 w-5"} />,
    },
    {
      label: bookProgressCheckLabel,
      to: BookProgressCheckCalendarScreenPath,
      icon: <CheckBadgeIcon className={"h-5 w-5"} />,
    },
    {
      label: "bookedLessonsCalendar",
      to: BookedClassRoomCalendarScreenPath,
      icon: <BookmarkIcon className={"h-5 w-5"} />,
    },
    {
      label: "extraResources",
      to: getContentPlatformUrl(ContentPlatformKnownPath.ExtraResources),
      icon: <BeakerIcon className={"h-5 w-5"} />,
      externalUrl: true,
    },
    {
      label: "startHere",
      to: StartHereScreenPath,
      icon: <StarIcon className={"h-5 w-5"} />,
    },
    {
      label: "help",
      to: getContentPlatformUrl(ContentPlatformKnownPath.HelpStudents),
      icon: <LifebuoyIcon className={"h-5 w-5"} />,
      externalUrl: true,
    },
    {
      label: "liveWithNorma",
      to: getContentPlatformUrl(ContentPlatformKnownPath.NormaLive),
      icon: <VideoCameraIcon className={"h-5 w-5"} />,
      externalUrl: true,
    },
    // {
    //   label: "otherProducts",
    //   to: OtherProductsScreenPath,
    //   icon: <PlusIcon className={"h-5 w-5"} />,
    // },
  ]
    .filter((r) => {
      if (
        r.label === bookSpeakingClassLabel &&
        hideOptions?.hideBookSpeakingClass
      )
        return false;

      return !(
        r.label === bookProgressCheckLabel && hideOptions?.hideBookProgressCheck
      );
    })
    .map((r, i) => ({
      ...r,
      label: t(r.label, {
        index: i,
      }),
    }));
};
