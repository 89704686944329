import { LayoutWithOnlyNavbar } from "../UI/Layout/LayoutWithOnlyNavbar.tsx";
import { useCurrentUserOrThrow } from "../Auth/useCurrentUserOrThrow.ts";
import { Card } from "../UI/Card.tsx";
import { InfiniteProgressBarIndicator } from "../UI/Progress/InfiniteProgressBarIndicator.tsx";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { SpeakingLevel } from "../gql/graphql.ts";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../UI/Button/PrimaryButton.tsx";
import { Trans, useTranslation } from "react-i18next";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks/index.js";
import { TRunAnimationParams } from "react-canvas-confetti/src/types/index.ts";
import { NavBarDropDownWithOnlyLogout } from "../UI/Layout/NavBarDropDown/NavBarDropDownWithOnlyLogout.tsx";
import { StartHereScreenPath } from "../Help/StartHereScreen.tsx";
import { TI } from "../I18n/TI.ts";
import { SpeakingLevelRepository } from "./SpeakingLevel/SpeakingLevelRepository.ts";

type TConductorInstance = {
  run: (params: TRunAnimationParams) => void;
  shoot: () => void;
  pause: () => void;
  stop: () => void;
};

const MAX_TIME_MS = 30 * 1000;

export const PostSelfAssessmentLandingScreenPath = "/post-self-assessment";
export function PostSelfAssessmentLandingScreen() {
  const { user, refetch } = useCurrentUserOrThrow();
  const [timeoutOccurred, setTimeoutOccurred] = useState(false);
  const startRef = useRef(new Date());
  const navigate = useNavigate();
  const confettiFiredRef = useRef(false);
  const [fireworksConductor, setFireworksConductor] =
    useState<TConductorInstance>();

  const { t } = useTranslation("postSelfAssessmentLanding");
  const { t: tLevelDesc } = useTranslation("levelDesc");

  const speakingLevel = user.speakingLevel;

  const intervalRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    intervalRef.current = setInterval(async () => {
      if (dayjs().diff(startRef.current, "ms") > MAX_TIME_MS) {
        clearInterval(intervalRef.current);
        setTimeoutOccurred(true);
        return;
      }
      await refetch();
    }, 5 * 1000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [refetch]);

  useEffect(() => {
    if (
      speakingLevel !== SpeakingLevel.Unknown &&
      !confettiFiredRef.current &&
      fireworksConductor
    ) {
      confettiFiredRef.current = true;
      fireworksConductor?.run({
        duration: 1.5 * 1000,
        speed: 1,
      });
    }
  }, [speakingLevel, navigate, fireworksConductor]);

  function getLevelDesc(): string {
    return SpeakingLevelRepository.get(speakingLevel).getLevelDescription(
      tLevelDesc,
    );
  }

  return (
    <LayoutWithOnlyNavbar navBarRight={<NavBarDropDownWithOnlyLogout />}>
      <div className={"flex-1 flex items-center justify-center"}>
        {timeoutOccurred && speakingLevel === SpeakingLevel.Unknown ? (
          <Card>
            <h1 className={"font-bold text-center text-xl"}>
              {t("errorTitle")}
            </h1>
            <p className={"text-center max-w-[500px]"}>{t("errorSubtitle")}</p>
            <div className={"flex"}>
              <div className={"mx-auto"}>
                <PrimaryButton
                  label={t("tryAgain")}
                  onClick={() => window.location.reload()}
                />
              </div>
            </div>
          </Card>
        ) : speakingLevel !== SpeakingLevel.Unknown ? (
          <Card>
            <h1 className={"font-bold text-center text-xl"}>
              {t("resultTitle")}
            </h1>
            <p className={"text-center"}>
              <Trans
                ns={"postSelfAssessmentLanding"}
                i18nKey={"resultSubTitle"}
              >
                La tua <span className={"italic"}>Fluency Personality</span> è:{" "}
                <b>{{ speakingLevel } as TI}</b>
              </Trans>
            </p>
            <p className={"bg-slate-100 rounded-lg p-2.5 space-y-2"}>
              <span className={"font-bold block"}>{speakingLevel}</span>
              <span className={"block"}>{getLevelDesc()}</span>
            </p>
            <div className={"flex"}>
              <div className={"mx-auto"}>
                <PrimaryButton
                  label={t("letsStart")}
                  onClick={() => {
                    navigate(StartHereScreenPath, {
                      replace: true,
                    });
                  }}
                />
              </div>
            </div>
            <Fireworks
              // decorateOptions={(opts) => {
              //   return {
              //     ...opts,
              //     decay: 1,
              //   };
              // }}
              onInit={(i) => {
                setFireworksConductor(i.conductor);
              }}
            />
          </Card>
        ) : (
          <Card>
            <h1 className={"font-bold text-center text-xl"}>{t("title")}</h1>
            <p className={"text-center"}>{t("subTitle")}</p>
            <InfiniteProgressBarIndicator />
          </Card>
        )}
      </div>
    </LayoutWithOnlyNavbar>
  );
}
