import { useAuthCtx } from "./useAuthCtx.ts";
import { Navigate } from "react-router-dom";
import { PropsWithChildren } from "react";

export function RequireMissingAccount({ children }: PropsWithChildren) {
  const { user } = useAuthCtx();

  if (user) return <Navigate to={"/"} replace />;

  return children ?? null;
}
