import { ShieldCheckIcon } from "@heroicons/react/24/solid/index.js";
import { classNames } from "../Utils/classNames.ts";

export function StepProgress({
  steps,
  current,
}: {
  steps: {
    name: string;
    colorClass: string;
    textColorClass: string;
    iconColor: string;
    id: string;
  }[];
  current?: string;
}) {
  return (
    <>
      <div className={"md:flex items-center w-full hidden"}>
        {steps.map((step, i) => {
          const isSelected = step.id === current;
          return (
            <div
              key={i}
              className={classNames("flex-1", isSelected ? "" : "opacity-40")}
            >
              <ShieldCheckIcon
                className={classNames(
                  "h-7 mb-1",
                  isSelected ? "" : "invisible",
                  step.iconColor,
                )}
              />
              <div className={classNames("h-2", step.colorClass)} />
              <p className={"mt-1"}>{step.name}</p>
            </div>
          );
        })}
      </div>
      <div className={"space-y-3 md:hidden"}>
        {steps.map((step, i) => {
          const isSelected = step.name === current;
          return (
            <div
              key={i}
              className={classNames(
                "flex items-center space-x-2",
                isSelected ? "" : "opacity-40",
              )}
            >
              <div
                className={classNames("p-3 flex-1 rounded-md", step.colorClass)}
              >
                <p className={classNames("text-center", step.textColorClass)}>
                  {step.name}
                </p>
              </div>
              <ShieldCheckIcon
                className={classNames(
                  "h-7 mb-1",
                  isSelected ? "" : "invisible",
                  step.iconColor,
                )}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}
