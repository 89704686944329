import { graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";
import dayjs, { Dayjs } from "dayjs";

export const GetRemainingLessonPerWeek = graphql(`
  query getUserRemainingLessonByWeek(
    $dateUtc: String!
    $clientTimezone: String!
  ) {
    getUserRemainingLessonByWeek(
      dateUtc: $dateUtc
      clientTimezone: $clientTimezone
    ) {
      remainingLessonCount
      totalLessonCount
    }
  }
`);
export function useRemainingLessonPerWeek({ date }: { date: Dayjs }) {
  const { data, loading } = useQuery(GetRemainingLessonPerWeek, {
    variables: {
      dateUtc: date.toISOString(),
      clientTimezone: dayjs.tz.guess(),
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    remainingLessonCount:
      data?.getUserRemainingLessonByWeek.remainingLessonCount ?? 0,
    totalLessonCount: data?.getUserRemainingLessonByWeek.totalLessonCount ?? 0,
    loading,
  };
}
