import { BookableSlotFragmentFragment, ClassRoomType } from "../gql/graphql.ts";
import { ProgressCheckSlotCalendarCard } from "./PorgressCheck/ProgressCheckSlotCalendarCard.tsx";
import { SpeakingLessonSlotCalendarCard } from "./SpeakingLesson/SpeakingLessonSlotCalendarCard.tsx";
import { RemainingLessonInfoBag } from "./RemainingLessonInfoBag.tsx";

export function SlotCalendarCard({
  slot,
  hasAtLeastOnProgressCheckBookable,
  remainingLessonsInfo,
}: {
  slot: BookableSlotFragmentFragment;
  hasAtLeastOnProgressCheckBookable?: boolean;
  remainingLessonsInfo?: RemainingLessonInfoBag;
}) {
  switch (slot.slotType) {
    case ClassRoomType.Exam:
      return (
        <ProgressCheckSlotCalendarCard
          remainingLessonInfo={remainingLessonsInfo}
          hasAtLeastOnProgressCheckBookable={hasAtLeastOnProgressCheckBookable}
          slot={slot}
        />
      );
    case ClassRoomType.SpeakingClass:
      return (
        <SpeakingLessonSlotCalendarCard
          remainingLessonsInfo={remainingLessonsInfo}
          slot={slot}
        />
      );
    default:
      throw new Error(`Unknown slot type: ${slot.slotType}`);
  }
}
