import { LayoutWithOnlyNavbar } from "../UI/Layout/LayoutWithOnlyNavbar.tsx";
import { Card } from "../UI/Card.tsx";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../UI/Button/PrimaryButton.tsx";
import { LifebuoyIcon } from "@heroicons/react/24/solid/index.js";

export const MissingAccountScreenPath = "/missing-account";
export function MissingAccountScreen() {
  const { t } = useTranslation("missingAccountScreen");
  return (
    <LayoutWithOnlyNavbar>
      <div className={"flex-1 justify-center flex flex-col"}>
        <Card>
          <div className={"space-y-1"}>
            <h1 className={"font-bold text-xl"}>{t("title")}</h1>
            <p>{t("description")}</p>
          </div>
          <div className={"flex w-full"}>
            <div className={"mr-auto"}>
              <PrimaryButton
                iconLeft={<LifebuoyIcon className={"w-5"} />}
                label={t("contactSupport")}
                onClick={() => {
                  window.location.href =
                    "mailto:help@normasteaching.com?subject=Missing%20Account%20Support%20Request&body=Please%20help%20me%20recover%20my%20account.";
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </LayoutWithOnlyNavbar>
  );
}
