import {
  GetFreezableWeeksResponse,
  WeekWithPlan,
} from "../../../gql/graphql.ts";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import _groupBy from "lodash.groupby";
import _keyBy from "lodash.keyby";
import dayjs from "dayjs";
import { MultipleSelect } from "../../../UI/Select/MultipleSelect.tsx";
import { PrimaryButton } from "../../../UI/Button/PrimaryButton.tsx";
import { PauseCircleIcon } from "@heroicons/react/24/solid/index.js";
import { useFreezeWeek } from "./useFreezeWeek.ts";
import { useWithLoading } from "../../../UI/Loading/useWithLoading.ts";
import { ErrorMessagePopup } from "../../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../../../UI/MessagePopup/useMessagePopupController.ts";
import { ApolloError } from "@apollo/client";
import { FreezeWeekGraphqlErrorCode } from "./FreezeWeekGraphqlErrorCode.tsx";

export function FreezeWeekComponent({
  freezableWeeks,
}: {
  freezableWeeks: GetFreezableWeeksResponse;
}) {
  const { i18n, t } = useTranslation("lessonPlanManagement");
  const [selectedWeeks, setSelectedWeeks] = useState<string[]>([]);
  const [weekNotSelectedError, setWeekNotSelectedError] = useState("");
  const { freezeWeek } = useFreezeWeek();
  const { withLoading, loading } = useWithLoading();
  const { description, title, show, closePopup, showPopup } =
    useMessagePopupController({
      timeoutInMs: 5 * 1000,
    });

  const freezableWeekId = freezableWeeks.weeks
    .map((w) => createWeekId(w))
    .join("-");

  useEffect(() => {
    setSelectedWeeks([]);
  }, [freezableWeekId]);
  function printGenericError() {
    showPopup(
      t("freezeWeekComponent.freezeError.generic.title"),
      t("freezeWeekComponent.freezeError.generic.description"),
    );
  }

  async function suspend() {
    const weeksToFreeze = freezableWeeks.weeks.filter((w) =>
      selectedWeeks.includes(createWeekId(w)),
    );
    if (weeksToFreeze.length <= 0) {
      setWeekNotSelectedError(t("freezeWeekComponent.selectAtLeastOneWeek"));
      return;
    }

    await withLoading(async () => {
      for (const week of weeksToFreeze) {
        try {
          await freezeWeek({
            startOfWeekDateString: week.week.startOfWeekUtc,
            endOfWeekDateString: week.week.endOfWeekUtc,
          });
        } catch (e) {
          if (!(e instanceof ApolloError)) {
            printGenericError();
            continue;
          }
          if (
            e.graphQLErrors?.some((e) => {
              return (
                e.extensions?.code ===
                FreezeWeekGraphqlErrorCode.ClassRoomAlreadyBookedInWeek
              );
            })
          )
            showPopup(
              t("freezeWeekComponent.freezeError.alreadyBookedClassroom.title"),
              t(
                "freezeWeekComponent.freezeError.alreadyBookedClassroom.description",
                {
                  from: formatDate(week.week.startOfWeekUtc),
                  to: formatDate(week.week.endOfWeekUtc),
                },
              ),
            );
          else printGenericError();
        }
      }
    });
  }

  function createWeekId(w: WeekWithPlan): string {
    return `${w.week.startOfWeekUtc}|${w.week.endOfWeekUtc}|${w.planId}`;
  }

  function parseId(id: string): {
    startOfWeekUtc: string;
    endOfWeekUtc: string;
    planId: string;
  } {
    const [startOfWeekUtc, endOfWeekUtc, planId] = id.split("|");
    return {
      startOfWeekUtc,
      endOfWeekUtc,
      planId,
    };
  }

  const selectedWeekByPlanId = _groupBy(selectedWeeks, (wId) => {
    const { planId } = parseId(wId);
    return planId;
  });
  const remainingWeeksByPlanId = _keyBy(
    freezableWeeks.remainingWeeksToFreeze,
    "planId",
  );

  const parseDateFormat = "YYYY-MM-DD";

  function formatDate(dateString: string): string {
    return dayjs(dateString, parseDateFormat)
      .local()
      .locale(i18n.language)
      .format("ddd DD MMM YYYY");
  }

  return (
    <>
      <ErrorMessagePopup
        title={title}
        description={description}
        show={show}
        onClose={closePopup}
      />
      <div className={"space-y-4"}>
        <h3 className={"font-bold"}>{t("freezeWeekComponent.title")}</h3>
        <p className={"text-md"}>
          {t("freezeWeekComponent.explanation")}
          <br />
          <br />
          <span className={"font-semibold"}>
            {t("freezeWeekComponent.rememberTitle")}
          </span>
        </p>
        <ul className={"list-disc list-inside mt-1"}>
          <li>{t("freezeWeekComponent.remember1")}</li>
          <li>{t("freezeWeekComponent.remember2")}</li>
        </ul>
        <MultipleSelect
          placeholder={t("freezeWeekComponent.selectWeekToSuspend")}
          errorMessage={weekNotSelectedError}
          onChange={(value) => {
            setSelectedWeeks(value);
            setWeekNotSelectedError("");
          }}
          value={selectedWeeks}
          options={freezableWeeks.weeks.map((w) => {
            const remainingByPlan = remainingWeeksByPlanId[w.planId];
            const remaining =
              (remainingByPlan?.remainingCount ?? 0) -
              (selectedWeekByPlanId[w.planId]?.length ?? 0);
            return {
              label: t("freezeWeekComponent.fromTo", {
                from: formatDate(w.week.startOfWeekUtc),
                to: formatDate(w.week.endOfWeekUtc),
              }),
              id: createWeekId(w),
              disabled:
                remaining <= 0
                  ? {
                      isDisabled: true,
                      message: t(
                        "freezeWeekComponent.alreadySuspendedAllWeeks",
                      ),
                    }
                  : undefined,
            };
          })}
        />
        <div className={"flex"}>
          <div className={"ml-auto"}>
            <PrimaryButton
              iconLeft={<PauseCircleIcon className={"w-5"} />}
              label={t("freezeWeekComponent.suspend")}
              onClick={suspend}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
