import { PrimaryButton } from "../UI/Button/PrimaryButton.tsx";
import { BookmarkIcon } from "@heroicons/react/24/solid/index.js";
import { useTranslation } from "react-i18next";
import { DisabledEyeIcon } from "../UI/DisabledEyeIcon.tsx";
import { Layout } from "../UI/Layout/Layout.tsx";

export function SomethingWentWrong() {
  const { t } = useTranslation("somethingWentWrong");
  return (
    <Layout maxWClass={"max-w-full"}>
      <div
        className={
          "flex justify-center lg:justify-start lg:items-center flex-1 flex-col-reverse lg:flex-row lg:space-x-6 p-4"
        }
      >
        <div className={"flex lg:flex-1 flex-col items-center justify-center"}>
          <div
            className={"flex flex-col items-center lg:justify-center space-y-6"}
          >
            <DisabledEyeIcon />
            <div className={"space-y-2"}>
              <h3 className={"text-center font-bold text-lg"}>{t("title")}</h3>
              <p className={"text-center max-w-md"}>{t("description")}</p>
            </div>
            <div className={"mx-auto"}>
              <PrimaryButton
                iconLeft={<BookmarkIcon className={"w-5"} />}
                label={t("reload")}
                onClick={() => window.location.reload()}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
