import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useHandleRedirectToOldUrl } from "./useHandleRedirectToOldUrl.ts";

export const LoginScreenPath = "/login";
export function LoginScreen() {
  const { loginWithRedirect } = useAuth0();

  const { redirect } = useHandleRedirectToOldUrl();
  useEffect(() => {
    (async function login(): Promise<void> {
      await loginWithRedirect({
        appState: {
          returnTo: redirect,
        },
      });
    })();
  }, [loginWithRedirect, redirect]);

  return null;
}
