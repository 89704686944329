import { MyProfileCard } from "./MyProfileCard.tsx";
import { InformationBox } from "../../UI/Information/InformationBox.tsx";
import { SpeakingLevelProgress } from "../SpeakingLevelProgress.tsx";
import { useCurrentUserOrThrow } from "../../Auth/useCurrentUserOrThrow.ts";
import { useTranslation } from "react-i18next";
import { SpeakingLevelRepository } from "../SpeakingLevel/SpeakingLevelRepository.ts";

export function MyProfileEnglishLevelTab() {
  const { t } = useTranslation("myAccountEnglishLevelTab");
  const { t: tLevelDesc } = useTranslation("levelDesc");
  const { t: tLevelTitle } = useTranslation("levelTitle");
  const { user } = useCurrentUserOrThrow();
  const speakingLevel = user.speakingLevel;
  function getLevelDesc(): string {
    return SpeakingLevelRepository.get(speakingLevel).getLevelDescription(
      tLevelDesc,
    );
  }
  return (
    <div className={"mt-4 space-y-4"}>
      <MyProfileCard>
        <h2 className={"font-semibold text-lg"}>{t("mySpeakingLevel")}</h2>
        <InformationBox
          title={tLevelTitle(speakingLevel.toLowerCase())}
          description={getLevelDesc()}
        />
        <div>
          <SpeakingLevelProgress user={user} />
        </div>
      </MyProfileCard>
    </div>
  );
}
