import { graphql } from "../../../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { GetMyFrozenWeeksQuery } from "./useMyFrozenWeeks.ts";
import { GetFreezableWeeksQuery } from "./useFreezableWeeks.ts";

const UnfreezeWeekMutation = graphql(`
  mutation removeFrozenWeek($week: FrozenWeekInput!) {
    removeFrozenWeek(week: $week)
  }
`);

export function useUnfreezeWeek() {
  const [unfreezeWeekMutation, { loading }] = useMutation(UnfreezeWeekMutation);
  const unFreezeWeek = useCallback(
    async ({
      startOfWeekDateString,
      endOfWeekDateString,
    }: {
      startOfWeekDateString: string;
      endOfWeekDateString: string;
    }) => {
      await unfreezeWeekMutation({
        variables: {
          week: {
            startOfWeekDate: startOfWeekDateString,
            endOfWeekDate: endOfWeekDateString,
          },
        },
        refetchQueries: [GetMyFrozenWeeksQuery, GetFreezableWeeksQuery],
      });
    },
    [unfreezeWeekMutation],
  );
  return {
    unFreezeWeek,
    loading,
  };
}
