import { ClassRoomCalendarCardItemDTO } from "./ClassRoomCalendarCardItemDTO.tsx";
import { CalendarCardComponent } from "../CalendarCardComponent.tsx";
import { useTranslation } from "react-i18next";
import { useLeaveClassRoom } from "./useLeaveClassRoom.ts";
import { useLeavingClassErrorHandler } from "./UseLeavingClassErrorHandler.tsx";
import { ErrorMessagePopup } from "../../UI/MessagePopup/ErrorMessagePopup.tsx";

type Props = {
  classRoom: ClassRoomCalendarCardItemDTO;
  showModal: boolean;
  onClose: () => void;
  onOpen: () => void;
};

export function ProgressCheckCalendarCard({
  classRoom,
  showModal,
  onOpen,
  onClose,
}: Props) {
  const { t } = useTranslation("progressCheckCard");
  const { title, description, show, handleError, closePopup } =
    useLeavingClassErrorHandler();

  const { leaveClassRoom, loading } = useLeaveClassRoom();

  async function leave() {
    try {
      await leaveClassRoom(classRoom.id);
    } catch (e) {
      handleError(e as Error);
      throw e;
    }
  }
  return (
    <>
      <ErrorMessagePopup
        title={title}
        description={description}
        show={show}
        onClose={closePopup}
      />
      <CalendarCardComponent
        showModal={showModal}
        onOpen={onOpen}
        onClose={onClose}
        title={"Progress check"}
        startDateUtc={classRoom.startedAtUtc}
        level={classRoom.speakingLevel}
        participantsCount={classRoom.participantsCount}
        capacity={classRoom.capacity}
        type={classRoom.type}
        ctaLabel={t("cancelProgressCheck")}
        ctaAction={leave}
        loadingCta={loading}
        meetUri={classRoom.meetingUri}
        disabled={!classRoom.canBeLeaved}
        disabledReason={t("cancelProgressCheckDisabledDueToTime")}
        slotTimezone={classRoom.timezone}
      />
    </>
  );
}
