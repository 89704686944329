import { TFunction } from "i18next";
import { ISpeakingLevel } from "./ISpeakingLevel.ts";
import { SpeakingLevel } from "../../gql/graphql.ts";

export class ExplorerLevel implements ISpeakingLevel {
  is(level: string): boolean {
    return level === this.getId();
  }
  getId(): SpeakingLevel {
    return SpeakingLevel.Explorers;
  }
  getBgColorClass(): string {
    return "bg-norma-explorer";
  }

  getLevelDescription(t: TFunction<"levelDesc">): string {
    return t("explorers");
  }

  getLevelTitle(t: TFunction<"levelTitle">): string {
    return t("explorers");
  }

  getTextColorClass(): string {
    return "text-norma-explorer";
  }

  getTextOnBgColorClass(): string {
    return "text-black";
  }
}
