import { CalendarCardComponentProps } from "./CalendarCardComponentProps.tsx";
import { Trans, useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  BookOpenIcon,
  CalendarDaysIcon,
  ClockIcon,
  ComputerDesktopIcon,
  ShieldCheckIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid/index.js";
import { PrimaryButton } from "../UI/Button/PrimaryButton.tsx";
import { BookmarkIcon } from "@heroicons/react/24/outline/index.js";
import { TextButton } from "../UI/Button/TextButton.tsx";
import { TI } from "../I18n/TI.ts";
import { ReactNode } from "react";
import { classNames } from "../UI/Utils/classNames.ts";

export function SlotInfoLine({
  icon,
  text,
  onClick,
  smallText,
}: {
  text: string;
  icon: ReactNode;
  onClick?: () => void;
  smallText?: string;
}) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        "flex items-center space-x-2",
        onClick ? "underline cursor-pointer" : "",
      )}
    >
      {icon}
      <p className={"flex flex-col"}>
        <span>{text}</span>
        {smallText ? (
          <span className={"text-sm text-slate-600"}>{smallText}</span>
        ) : null}
      </p>
    </div>
  );
}

export function CalendarCardSlotDetail({
  startDateUtc,
  level,
  participantsCount,
  capacity,
  ctaLabel,
  ctaAction,
  loadingCta,
  disabled,
  disabledReason,
  pdfUrl,
  meetUri,
  onClose,
  remainingLessonInfo,
  slotTimezone,
}: CalendarCardComponentProps & {
  onClose: () => void;
}) {
  const { t, i18n } = useTranslation("calendarCardComponent");
  const remaining = remainingLessonInfo?.remaining ?? 0;
  const total = remainingLessonInfo?.total ?? 0;
  const isDifferentTimezoneThanLocal = dayjs.tz.guess() !== slotTimezone;
  function getFormatHourString() {
    return isDifferentTimezoneThanLocal ? "HH:mm z" : "HH:mm";
  }

  function getHourString(): string {
    const parts: string[] = [];
    parts.push(
      dayjs(startDateUtc)
        .tz(slotTimezone)
        .locale(i18n.language)
        .format(getFormatHourString()),
    );

    if (isDifferentTimezoneThanLocal) {
      parts.push(
        `(${t("localTimeHour", {
          hour: dayjs(startDateUtc).locale(i18n.language).format("HH:mm"),
        })})`,
      );
    }

    parts.push("(50min)");
    return parts.join(" ");
  }
  return (
    <>
      <div className={"space-y-2"}>
        <SlotInfoLine
          text={dayjs(startDateUtc)
            .locale(i18n.language)
            .format("dddd DD MMM YYYY")}
          icon={<CalendarDaysIcon className={"w-5"} />}
        />
        <SlotInfoLine
          text={getHourString()}
          icon={<ClockIcon className={"w-5"} />}
          smallText={
            isDifferentTimezoneThanLocal
              ? t("timezoneInfo", {
                  timezone: dayjs
                    .tz(new Date(), slotTimezone)
                    .locale(i18n.language)
                    .format("zzz"),
                })
              : undefined
          }
        />
        <SlotInfoLine
          text={level}
          icon={<ShieldCheckIcon className={"h-5 w-5"} />}
        />
        <SlotInfoLine
          text={t("participants", {
            participantsCount,
            capacity,
          })}
          icon={<UserGroupIcon className={"w-5"} />}
        />
        {pdfUrl ? (
          <SlotInfoLine
            text={t("downloadLessonMaterial")}
            onClick={() => {
              window.open(pdfUrl, "_blank", "noopener noreferrer");
            }}
            icon={<BookOpenIcon className={"w-5"} />}
          />
        ) : null}
        {meetUri ? (
          <SlotInfoLine
            text={t("join")}
            onClick={() => {
              window.open(meetUri, "_blank", "noopener noreferrer");
            }}
            icon={<ComputerDesktopIcon className={"w-5"} />}
          />
        ) : null}
      </div>
      <div className={"mt-6"}>
        <PrimaryButton
          loading={loadingCta}
          iconLeft={<BookmarkIcon className={"h-5"} />}
          label={ctaLabel}
          disabled={disabled}
          onClick={async () => {
            await ctaAction();
          }}
        />
        {disabled && disabledReason ? (
          <p className={"text-slate-600 text-xs text-center mt-2"}>
            {disabledReason}
          </p>
        ) : null}
        <div className={"md:flex justify-center mt-3 text-sm hidden"}>
          <TextButton label={t("close")} onClick={onClose} />
        </div>
        {remainingLessonInfo ? (
          <div className={"block md:hidden text-center mt-2 text-sm"}>
            <span>
              <Trans ns={"calendarCardComponent"} i18nKey={"remainingLessons"}>
                Weekly booking remaining:{" "}
                <strong>
                  {{ remaining } as TI} of {{ total } as TI}
                </strong>
              </Trans>
            </span>
          </div>
        ) : null}
      </div>
    </>
  );
}
