import { Dayjs } from "dayjs";
import { HourRepresentation } from "./HourRepresentation.tsx";
import { SlotDayAndHourKey } from "./SlotDayAndHourKey.tsx";
import { Interval } from "./Interval.tsx";
import { CalendarItemMap } from "./CalendarItemMap.ts";

export class CalendarRepresentation<T> {
  constructor(
    private readonly days: Dayjs[],
    private readonly hour: HourRepresentation[],
    private readonly slotsMap: CalendarItemMap<T>,
    private readonly interval: Interval,
  ) {}

  public getAllDays(): Dayjs[] {
    return this.days;
  }

  public getAllHours(): HourRepresentation[] {
    return this.hour;
  }

  public getSlotOfDate(date: Dayjs, hour: HourRepresentation): T | undefined {
    const key = SlotDayAndHourKey.fromDateAndHourRepresentation(date, hour);
    return this.slotsMap.get(key);
  }

  public countSlotOnDate(date: Dayjs): number {
    return this.slotsMap.countSlotsOnDate(date);
  }

  getInterval(): Interval {
    return this.interval;
  }

  public isFirstHour(hour: HourRepresentation): boolean {
    return this.hour[0].from === hour.from;
  }

  public getAllSlotOfADate(date: Dayjs): T[] {
    return this.slotsMap.getAllSlotOfADate(SlotDayAndHourKey.fromDate(date));
  }
}
