import { ReactNode } from "react";
import { classNames } from "../Utils/classNames.ts";
import { PrimaryLoadingIndicator } from "../Loading/PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "../Loading/LoaderSize.tsx";

type Props = {
  label: string;
  onClick: () => void;
  loading?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  disabled?: boolean;
};
export function OutlinedButton({
  label,
  onClick,
  loading,
  iconLeft,
  iconRight,
  disabled,
}: Props) {
  return (
    <button
      onClick={
        disabled
          ? undefined
          : (e) => {
              e.preventDefault();
              onClick();
            }
      }
      className={classNames(
        "border flex w-full justify-center items-center space-x-2 font-medium rounded-lg text-sm px-5 py-2.5",
        disabled
          ? "border-slate-400 text-slate-400 cursor-not-allowed"
          : "cursor-pointer hover:brightness-125 hover:bg-primary hover:text-white focus:ring-4 ring-blue-600/20 bg-transparent text-primary border-primary ring-offset-1",
      )}
    >
      {iconLeft ?? null}
      <span>{label}</span>
      {iconRight ?? null}
      {loading ? <PrimaryLoadingIndicator size={LoaderSize.Small} /> : null}
    </button>
  );
}
