import { BookableSlotFragmentFragment } from "../../gql/graphql.ts";
import { CalendarCardComponent } from "../CalendarCardComponent.tsx";
import { useBookSpeakingClass } from "./useBookSpeakingClass.ts";
import { useTranslation } from "react-i18next";
import { SpeakingLessonCalendarCard } from "../BookedClassRoom/SpeakingLessonCalendarCard.tsx";
import { slotToClassRoomCalendarCardItemDTO } from "../slotToClassRoomCalendarCardItemDTO.tsx";
import { ErrorMessagePopup } from "../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../../UI/MessagePopup/useMessagePopupController.ts";
import { RemainingLessonInfoBag } from "../RemainingLessonInfoBag.tsx";
import { useControlCalendarCardModal } from "../useControlCalendarCardModal.ts";

export function SpeakingLessonSlotCalendarCard({
  slot,
  remainingLessonsInfo,
}: {
  slot: BookableSlotFragmentFragment;
  remainingLessonsInfo?: RemainingLessonInfoBag;
}) {
  const { showModal, onOpen, onClose } = useControlCalendarCardModal(
    slot.slotId,
  );
  const { bookSpeakingClass, loading } = useBookSpeakingClass();
  const { t } = useTranslation("speakingClassSlotCard");
  const { title, description, show, showPopup, closePopup } =
    useMessagePopupController({
      timeoutInMs: 5 * 1000,
    });
  async function book() {
    try {
      await bookSpeakingClass({
        classRoom: slot.classRoom
          ? {
              id: slot.classRoom.id,
            }
          : undefined,
        endDateUtc: slot.endDateUtc,
        slotId: slot.slotId,
        startDateUtc: slot.startDateUtc,
        timezone: slot.slotTimezone,
        topicId: slot.topic.id,
      });
    } catch (e) {
      showPopup(t("errorTitle"), t("errorDescription"));
      throw e;
    }
  }
  return slot.userIsAParticipant ? (
    <SpeakingLessonCalendarCard
      classRoom={slotToClassRoomCalendarCardItemDTO(slot)}
      showModal={showModal}
      onOpen={onOpen}
      onClose={onClose}
    />
  ) : (
    <>
      <ErrorMessagePopup
        title={title}
        description={description}
        show={show}
        onClose={closePopup}
      />
      <CalendarCardComponent
        showModal={showModal}
        showLessonTopicBadge
        onOpen={onOpen}
        onClose={onClose}
        remainingLessonInfo={remainingLessonsInfo}
        loadingCta={loading}
        ctaAction={book}
        pdfUrl={slot.topic.pdfUrl}
        title={slot.topic.name}
        startDateUtc={slot.startDateUtc}
        level={slot.slotLevel}
        participantsCount={slot.participantsCount}
        capacity={slot.capacity}
        type={slot.slotType}
        disabled={!slot.hasEnoughRemainingLessons}
        ctaLabel={t("bookSpeakingLesson")}
        disabledReason={
          !slot.hasEnoughRemainingLessons ? t("allHourForThisWeekUsed") : ""
        }
        slotTimezone={slot.slotTimezone}
      />
    </>
  );
}
