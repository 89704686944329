import { PropsWithChildren, useEffect, useState } from "react";
import { initI18n } from "./initI18n.ts";
import { FullPageLoading } from "../UI/Loading/FullPageLoading.tsx";

type Props = PropsWithChildren;
export function I18nLoader({ children }: Props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function loadI18n() {
      await initI18n();
      setLoading(false);
    })();
  }, []);

  if (loading) return <FullPageLoading />;

  return <>{children ?? null}</>;
}
