import { ImpersonationRepository } from "./ImpersonationRepository.ts";
import { useCurrentUserOrThrow } from "../Auth/useCurrentUserOrThrow.ts";

export function ImpersonationTopNotice() {
  const { user } = useCurrentUserOrThrow();
  return (
    <div
      className={
        "flex items-center justify-between space-x-2 bg-red-500 text-white p-1.5 text-sm"
      }
    >
      <p className={""}>You're impersonating {user.email}</p>
      <a
        className={"underline cursor-pointer"}
        onClick={(e) => {
          e.preventDefault();
          ImpersonationRepository.clearImpersonationUid();
          window.location.reload();
        }}
      >
        Exit impersonation
      </a>
    </div>
  );
}
