import { MissingTestComponent } from "../UI/MissingTest/MissingTestComponent.tsx";
import { IntroductionVideo } from "../User/IntroductionVideo.tsx";
import { StarIcon } from "@heroicons/react/24/outline/index.js";
import { Layout } from "../UI/Layout/Layout.tsx";
import { useTranslation } from "react-i18next";

export const StartHereScreenPath = "/start-here";
export function StartHereScreen() {
  const { t } = useTranslation("startHere");
  function downloadURI(uri: string, name: string) {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <Layout removePadding maxWClass={"max-w-full p-0"}>
      <MissingTestComponent
        topRightComponent={
          <IntroductionVideo
            videoUrl={"https://player.vimeo.com/video/946620550"}
          />
        }
        title={t("title")}
        description={t("description")}
        cta={{
          label: t("cta"),
          action: () => {
            downloadURI(
              "https://drive.google.com/uc?export=download&id=1s6imFQXsa6x0OVVYZOtN24U7HnnorpMk",
              "Promessa a me stesso",
            );
          },
        }}
        perks={[
          {
            title: t("perks.perk1.title"),
            description: t("perks.perk1.description"),
            icon: <StarIcon className={"w-6"} />,
          },
          {
            title: t("perks.perk2.title"),
            description: t("perks.perk2.description"),
            icon: <StarIcon className={"w-6"} />,
          },
          {
            title: t("perks.perk3.title"),
            description: t("perks.perk3.description"),
            icon: <StarIcon className={"w-6"} />,
          },
        ]}
      />
    </Layout>
  );
}
