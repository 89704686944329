import { useTranslation } from "react-i18next";
import { DropDown } from "../DropDown/DropDown.tsx";
import { DropDownItem } from "../DropDown/DropDownItem.tsx";
import { selectedLanguageRepo } from "../../I18n/selectedLanguageRepo.ts";
import { defaultLanguageCode } from "../../I18n/defaultLanguageCode.ts";

type Language = {
  code: string;
  label: string;
  icon: string;
};
export function FooterLanguageSelector() {
  const { t, i18n } = useTranslation("languageSelector");

  const languages: Language[] = [
    {
      code: "en",
      label: t("english"),
      icon: "/flag/gb.svg",
    },
    {
      code: "it",
      label: t("italian"),
      icon: "/flag/it.svg",
    },
  ];
  const defLanguage = languages.find((l) => l.code === defaultLanguageCode);
  const selectedLanguage =
    languages.find((l) => l.code === i18n.language) ?? defLanguage;
  if (!selectedLanguage)
    throw new Error("No language selected, should never happen");
  return (
    <DropDown
      above
      activator={
        <p
          className={
            "underline text-white text-xs flex items-end space-x-2 h-full"
          }
        >
          <img
            className={"w-4"}
            alt={selectedLanguage.label}
            src={selectedLanguage.icon}
          />
          <span>{selectedLanguage.label}</span>
        </p>
      }
    >
      {languages.map((l) => (
        <DropDownItem
          key={l.code}
          onClick={async () => {
            selectedLanguageRepo.saveLanguage(l.code);
            window.location.reload();
          }}
        >
          <p className={"p-2 flex items-center space-x-2 min-w-[150px]"}>
            <img className={"w-4"} alt={l.label} src={l.icon} />
            <span>{l.label}</span>
          </p>
        </DropDownItem>
      ))}
    </DropDown>
  );
}
