import { DisabledEyeIcon } from "../UI/DisabledEyeIcon.tsx";
import { ProgressBar } from "../UI/Progress/ProgressBar.tsx";
import { PrimaryButton } from "../UI/Button/PrimaryButton.tsx";
import { CalendarDaysIcon } from "@heroicons/react/24/solid/index.js";

type Props = {
  title: string;
  description: string;
  progress?: {
    current: number;
    total: number;
    progressText: string;
  };
  cta: {
    hidden?: boolean;
    label: string;
    onClick: () => void;
  };
};
export function EmptySectionComponent({
  title,
  description,
  progress,
  cta,
}: Props) {
  return (
    <div className={"flex-1 w-full items-center justify-center flex"}>
      <div className={"flex flex-col items-center space-y-12"}>
        <div className={"space-y-4 flex flex-col items-center"}>
          <DisabledEyeIcon />
          <div>
            <h3 className={"text-center font-bold text-lg"}>{title}</h3>
            <p className={"text-center"}>{description}</p>
          </div>
        </div>
        {progress ? (
          <div className={"w-full space-y-2"}>
            <h4 className={"font-bold text-center"}>{progress.progressText}</h4>
            <ProgressBar
              colorClassRanges={[
                {
                  minIncl: progress.total - Math.round(progress.total * 0.5),
                  maxExcl: Infinity,
                  colorClass: "bg-green-700",
                },
              ]}
              current={progress.current}
              total={progress.total}
            />
          </div>
        ) : null}
        {cta.hidden ? null : (
          <div>
            <PrimaryButton
              iconLeft={<CalendarDaysIcon className={"w-5"} />}
              label={cta.label}
              onClick={cta.onClick}
            />
          </div>
        )}
      </div>
    </div>
  );
}
