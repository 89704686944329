import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useWithLoading } from "../../UI/Loading/useWithLoading.ts";
import { Col } from "../../UI/Layout/Col.tsx";
import { Row } from "../../UI/Layout/Row.tsx";
import { InputWithLabel } from "../../UI/Input/InputWithLabel.tsx";
import { TextInput } from "../../UI/Input/TextInput.tsx";
import { PrimaryButton } from "../../UI/Button/PrimaryButton.tsx";
import {
  CheckCircleIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid/index.js";
import { HorizontalLineDivider } from "../../UI/HorizontalLineDivider.tsx";
import { useSendResetPasswordLink } from "../useSendResetPasswordLink.ts";
import { SuccessMessagePopup } from "../../UI/MessagePopup/SuccessMessagePopup.tsx";
import { useMessagePopupController } from "../../UI/MessagePopup/useMessagePopupController.ts";
import { ErrorMessagePopup } from "../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { OutlinedButton } from "../../UI/Button/OutlinedButton.tsx";

export type EditUserFormInputs = {
  givenName: string;
  familyName: string;
  email: string;
};

export function EditUserForm({
  defaultValues,
  onSave,
}: {
  defaultValues?: Partial<EditUserFormInputs>;
  onSave: (data: EditUserFormInputs) => Promise<void>;
}) {
  const { t } = useTranslation("editUserForm");
  const { sendResetPasswordEmail } = useSendResetPasswordLink();
  const schema = yup
    .object({
      givenName: yup.string().required(t("insertAName")),
      familyName: yup.string().required(t("insertASurname")),
      email: yup
        .string()
        .email(t("insertAValidEmail"))
        .required(t("insertAValidEmail")),
    })
    .required();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EditUserFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...(defaultValues ?? {}),
    },
  });
  const { withLoading, loading } = useWithLoading();
  const {
    withLoading: withLoadingSendResetPassword,
    loading: sendingResetPassword,
  } = useWithLoading();

  const successResetController = useMessagePopupController({
    timeoutInMs: 5 * 1000,
  });
  const errorResetController = useMessagePopupController({
    timeoutInMs: 5 * 1000,
  });
  const onSubmit: SubmitHandler<EditUserFormInputs> = async (data) => {
    await withLoading(async () => onSave(data));
  };

  async function sendResetPw() {
    await withLoadingSendResetPassword(async () => {
      try {
        await sendResetPasswordEmail();
        successResetController.showPopup(
          t("passwordResetSentTitle"),
          t("passwordResetSentDescription"),
        );
      } catch (e) {
        errorResetController.showPopup(
          t("passwordResetErrorTitle"),
          t("passwordResetErrorDescription"),
        );
        throw e;
      }
    });
  }

  return (
    <>
      <ErrorMessagePopup
        title={errorResetController.title}
        description={errorResetController.description}
        show={errorResetController.show}
        onClose={errorResetController.closePopup}
      />
      <SuccessMessagePopup
        title={successResetController.title}
        description={successResetController.description}
        show={successResetController.show}
        onClose={successResetController.closePopup}
      />
      <form>
        <Col>
          <Row>
            <InputWithLabel label={t("givenName")}>
              <TextInput
                {...register("givenName")}
                errorMessage={errors.givenName?.message}
              />
            </InputWithLabel>
            <InputWithLabel label={t("familyName")}>
              <TextInput
                {...register("familyName")}
                errorMessage={errors.familyName?.message}
              />
            </InputWithLabel>
          </Row>
          <Row>
            <InputWithLabel label={t("email")}>
              <TextInput
                disabled
                {...register("email")}
                errorMessage={errors.email?.message}
              />
            </InputWithLabel>
            <InputWithLabel label={t("password")}>
              <div
                className={
                  "flex md:flex-row flex-col space-y-2 md:space-y-0 md:space-x-2"
                }
              >
                <div className={"flex-1 w-full"}>
                  <TextInput
                    type={"password"}
                    value={Array.from(
                      {
                        length: 20,
                      },
                      () => "*",
                    ).join("")}
                    disabled
                  />
                </div>
                <div>
                  <OutlinedButton
                    loading={sendingResetPassword}
                    iconLeft={<LockClosedIcon className={"w-5"} />}
                    label={t("resetPassword")}
                    onClick={sendResetPw}
                  />
                </div>
              </div>
            </InputWithLabel>
          </Row>
          <Row>
            <div className={"w-full"}>
              <HorizontalLineDivider />
            </div>
          </Row>
          <Row>
            <div className={"md:ml-auto"}>
              <PrimaryButton
                iconLeft={<CheckCircleIcon className={"w-5"} />}
                loading={loading}
                label={t("save")}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </Row>
        </Col>
      </form>
    </>
  );
}
