import { MyProfileCard } from "./MyProfileCard.tsx";
import { EditUserForm } from "./EditUserForm.tsx";
import { DeleteAccountCard } from "./DeleteAccount/DeleteAccountCard.tsx";
import { TotalRemainingLessonsComponent } from "../Plan/TotalRemainingLessonsComponent.tsx";
import { useUpdateMe } from "../useUpdateMe.ts";
import { useCurrentUserOrThrow } from "../../Auth/useCurrentUserOrThrow.ts";
import { useTranslation } from "react-i18next";
import { HorizontalLineDivider } from "../../UI/HorizontalLineDivider.tsx";
import { StudyPlanButton } from "../Plan/FreezeWeek/StudyPlanButton.tsx";

export function MyProfileMyAccountTab() {
  const { updateMe } = useUpdateMe();
  const { user } = useCurrentUserOrThrow();
  const { t } = useTranslation("myProfileMyAccountTab");
  return (
    <div
      className={
        "mt-4 flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-4"
      }
    >
      <div className={"basis-3/4 space-y-4"}>
        <MyProfileCard>
          <h2 className={"font-semibold text-lg"}>
            {t("personalInformation")}
          </h2>
          <EditUserForm
            defaultValues={{
              givenName: user?.givenName ?? "",
              familyName: user?.familyName ?? "",
              email: user?.email ?? "",
            }}
            onSave={async (data) => {
              await updateMe({
                givenName: data.givenName,
                familyName: data.familyName,
              });
            }}
          />
        </MyProfileCard>
        <div className={"hidden xl:block"}>
          <DeleteAccountCard />
        </div>
      </div>
      <div className={"basis-1/4"}>
        <MyProfileCard>
          <h2 className={"font-semibold text-lg"}>{t("lessonsPlanTitle")}</h2>
          <TotalRemainingLessonsComponent />
          <HorizontalLineDivider />
          <StudyPlanButton />
        </MyProfileCard>
      </div>
      <div className={"xl:hidden"}>
        <DeleteAccountCard />
      </div>
    </div>
  );
}
