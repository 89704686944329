export function PrimaryTag({ text }: { text: string }) {
  return (
    <div
      className={
        "bg-primary py-1 px-2 text-white rounded-lg inline-block text-sm"
      }
    >
      <p>{text}</p>
    </div>
  );
}
