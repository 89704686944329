import { ReactNode } from "react";
import { classNames } from "../Utils/classNames.ts";

export function DangerBox({
  title,
  description,
}: {
  title: string;
  description: string | ReactNode;
}) {
  return (
    <div
      className={classNames(
        "bg-red-100/50 text-red-700",
        "p-4 rounded-2xl flex flex-col space-y-3",
      )}
    >
      <h5 className={"font-semibold text-base"}>{title}</h5>
      <div>
        <p className={"text-sm"}>{description}</p>
      </div>
    </div>
  );
}
