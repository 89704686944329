import { ReactNode } from "react";
import { classNames } from "../Utils/classNames.ts";
import { PrimaryButton } from "../Button/PrimaryButton.tsx";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid/index.js";

export function InformationBox({
  title,
  description,
  cta,
}: {
  title: string;
  description: string | ReactNode;
  cta?: {
    title: string;
    onClick: () => void;
  };
}) {
  return (
    <div
      className={classNames(
        "bg-blue-100/50",
        "p-4 rounded-2xl flex flex-col space-y-3",
      )}
    >
      <h5 className={"font-semibold text-primary text-base"}>{title}</h5>
      <div>
        <p className={"text-primary text-sm"}>{description}</p>
      </div>
      {cta ? (
        <div className={"flex mr-auto"}>
          <PrimaryButton
            iconRight={<ArrowLongRightIcon className={"w-5"} />}
            label={cta.title}
            onClick={cta.onClick}
          />
        </div>
      ) : null}
    </div>
  );
}
