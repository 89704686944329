import { LayoutWithOnlyNavbar } from "../../UI/Layout/LayoutWithOnlyNavbar.tsx";
import { NavBarDropDownWithOnlyLogout } from "../../UI/Layout/NavBarDropDown/NavBarDropDownWithOnlyLogout.tsx";
import { Card } from "../../UI/Card.tsx";
import { useTranslation } from "react-i18next";

export const NotActiveScreenPath = "/not-active";
export function NotActiveScreen() {
  const { t } = useTranslation("notActiveAccountScreen");
  return (
    <LayoutWithOnlyNavbar navBarRight={<NavBarDropDownWithOnlyLogout />}>
      <div className="flex flex-col items-center justify-center flex-1 max-w-[500px] mx-auto">
        <Card>
          <h1 className="text-3xl font-bold text-center">{t("title")}</h1>
          <p className="text-center mt-4">{t("description")}</p>
        </Card>
      </div>
    </LayoutWithOnlyNavbar>
  );
}
