import { PropsWithChildren } from "react";

export function Card({ children }: PropsWithChildren) {
  return (
    <div
      className={"space-y-6 bg-white p-8 rounded-lg shadow-xl shadow-slate-200"}
    >
      {children}
    </div>
  );
}
