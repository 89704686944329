import { SlotDayAndHourKey } from "./SlotDayAndHourKey.tsx";
import { Dayjs } from "dayjs";

type DateString = string;
type HourString = string;
export class CalendarItemMap<T> {
  constructor(
    private readonly map: Map<DateString, Map<HourString, T>> = new Map(),
  ) {}

  public set(key: SlotDayAndHourKey, slot: T): void {
    const dateMap = this.getDateMap(key.dateString);
    dateMap.set(key.hourString, slot);
    this.map.set(key.dateString, dateMap);
  }

  public get(key: SlotDayAndHourKey): T | undefined {
    const dateMap = this.getDateMap(key.dateString);
    return dateMap.get(key.hourString);
  }

  public countSlotsOnDate(date: Dayjs): number {
    return this.getDateMap(date.format(SlotDayAndHourKey.formatDateString))
      .size;
  }

  private getDateMap(dateString: string): Map<DateString, T> {
    return this.map.get(dateString) ?? new Map();
  }

  public getAllSlotOfADate(key: SlotDayAndHourKey): T[] {
    const map = this.getDateMap(key.dateString);
    return Array.from(map.values());
  }
}
