import React from "react";
import { User } from "@auth0/auth0-react";
import { UserFragmentFragment } from "../gql/graphql.ts";

export type AuthCtxType = {
  authUser?: User;
  signOut: () => Promise<void>;
  user?: UserFragmentFragment;
  isActiveUser?: boolean;
  refetch: () => Promise<void>;
};

const AuthCtx = React.createContext<AuthCtxType>({
  signOut: async () => {
    throw new Error("AuthCtx not initialized");
  },
  refetch: async () => {
    throw new Error("AuthCtx not initialized");
  },
});

export { AuthCtx };
