import { ClassRoomCalendarCardItemDTO } from "./ClassRoomCalendarCardItemDTO.tsx";
import { ClassRoomType } from "../../gql/graphql.ts";
import { ProgressCheckCalendarCard } from "./ProgressCheckCalendarCard.tsx";
import { SpeakingLessonCalendarCard } from "./SpeakingLessonCalendarCard.tsx";
import { useControlCalendarCardModal } from "../useControlCalendarCardModal.ts";

type Props = {
  classRoom: ClassRoomCalendarCardItemDTO;
};

export function ClassRoomCalendarCard({ classRoom }: Props) {
  const { showModal, onClose, onOpen } = useControlCalendarCardModal(
    classRoom.id,
  );
  switch (classRoom.type) {
    case ClassRoomType.SpeakingClass:
      return (
        <SpeakingLessonCalendarCard
          showModal={showModal}
          onClose={onClose}
          onOpen={onOpen}
          classRoom={classRoom}
        />
      );
    case ClassRoomType.Exam:
      return (
        <ProgressCheckCalendarCard
          showModal={showModal}
          onClose={onClose}
          onOpen={onOpen}
          classRoom={classRoom}
        />
      );
    default:
      throw new Error("Invalid class room type");
  }
}
