import { FullPageLoading } from "../UI/Loading/FullPageLoading.tsx";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { ImpersonationRepository } from "./ImpersonationRepository.ts";
import { DangerBox } from "../UI/Information/DangerBox.tsx";
import { LayoutWithOnlyNavbar } from "../UI/Layout/LayoutWithOnlyNavbar.tsx";

export const ImpersonationScreenPath = "/impersonate-user";
export function ImpersonationScreen() {
  const [searchParams] = useSearchParams();
  const { getAccessTokenSilently } = useAuth0();
  const [errorString, setErrorString] = useState<string>("");

  useEffect(() => {
    (async function impersonate() {
      const authId = searchParams.get("uid");
      if (!authId) return setErrorString(`No user id provided`);
      const accessToken = await getAccessTokenSilently();
      const payload = jwtDecode<{
        permissions?: string[];
      }>(accessToken);
      const isAdmin = payload.permissions?.includes("admin") ?? false;
      if (!isAdmin) return setErrorString(`You can't perform this action`);
      ImpersonationRepository.setImpersonationUid(authId);
      window.location.href = "/";
    })();
  }, [searchParams, getAccessTokenSilently]);

  if (errorString)
    return (
      <LayoutWithOnlyNavbar>
        <DangerBox
          title={"Ops, something went wrong"}
          description={errorString}
        />
      </LayoutWithOnlyNavbar>
    );

  return <FullPageLoading />;
}
