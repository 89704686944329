import { ExclamationCircleIcon } from "@heroicons/react/24/solid/index.js";
import { ReactNode } from "react";

export function EmptyCalendarMessage({
  message,
  icon,
}: {
  message: string;
  icon?: ReactNode;
}) {
  return (
    <div className={"flex flex-col items-center space-y-2"}>
      {icon ?? <ExclamationCircleIcon className={"h-7 w-7"} />}
      <p className={"text-center text-sm"}>{message}</p>
    </div>
  );
}
