import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

export function useHandleOpenedSlotQueryString() {
  const [searchParams, setSearchParams] = useSearchParams();

  const openedCardKeyName = "os";

  const openedSlotId = searchParams.get(openedCardKeyName);

  const setOpenedSlot = useCallback(
    (slotId: string | undefined) => {
      const newSearchParams = new URLSearchParams(searchParams);
      if (slotId) {
        newSearchParams.set(openedCardKeyName, slotId);
      } else {
        newSearchParams.delete(openedCardKeyName);
      }
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  return {
    openedSlotId: openedSlotId ?? undefined,
    setOpenedSlot,
  };
}
