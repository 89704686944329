export function Logo() {
  return (
    <div className={"flex items-center space-x-3"}>
      <img
        className={"h-5 xs:h-10"}
        src={"/logo-fps.png"}
        alt={"Norma's teaching"}
      />
    </div>
  );
}
