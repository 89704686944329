import { graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";

const IsActiveUserQuery = graphql(`
  query isActiveUser {
    isActiveUser
  }
`);

export function useIsActiveUser(skip: boolean) {
  const { data, loading } = useQuery(IsActiveUserQuery, {
    skip,
  });

  return {
    isActive: data?.isActiveUser ?? false,
    loading,
  };
}
