import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { graphql } from "../gql";
import { LeaveClassRoomReviewMutationVariables } from "../gql/graphql.ts";

const LeaveClassRoomReviewMutation = graphql(`
  mutation leaveClassRoomReview(
    $classRoomId: String!
    $reviewNotes: String!
    $teacherClearanceScore: Int!
    $teacherComfortableScore: Int!
    $teacherInvolvementScore: Int!
    $generalLessonScore: Int!
    $topicDifficultyScore: Int!
    $topicUsefulnessScore: Int!
  ) {
    leaveClassRoomReview(
      classRoomId: $classRoomId
      reviewNotes: $reviewNotes
      teacherClearanceScore: $teacherClearanceScore
      teacherComfortableScore: $teacherComfortableScore
      teacherInvolvementScore: $teacherInvolvementScore
      generalLessonScore: $generalLessonScore
      topicDifficultyScore: $topicDifficultyScore
      topicUsefulnessScore: $topicUsefulnessScore
    ) {
      id
    }
  }
`);
export function useLeaveClassRoomReview() {
  const [leaveClassRoomReviewMutation, { loading }] = useMutation(
    LeaveClassRoomReviewMutation,
  );

  const leaveClassRoomReview = useCallback(
    async (params: LeaveClassRoomReviewMutationVariables) => {
      await leaveClassRoomReviewMutation({
        variables: params,
      });
    },
    [leaveClassRoomReviewMutation],
  );

  return {
    leaveClassRoomReview,
    loading,
  };
}
