import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const UpdateLastLoginMutation = graphql(`
  mutation updateLastLogin {
    updateLastLogin
  }
`);
export function useUpdateLastLogin() {
  const [updateLastLoginMutation, { loading }] = useMutation(
    UpdateLastLoginMutation,
  );

  const updateLastLogin = useCallback(async () => {
    await updateLastLoginMutation();
  }, [updateLastLoginMutation]);

  return {
    loading,
    updateLastLogin,
  };
}
