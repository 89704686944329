import { useEffect, useRef, useState } from "react";
import _uniq from "lodash.uniq";
import { Transition } from "@headlessui/react";
import { classNames } from "../Utils/classNames.ts";
import {
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/solid/index.js";

export type SelectOption<T> = {
  id: T;
  label: string;
  disabled?: {
    message: string;
    isDisabled: boolean;
  };
};

export function MultipleSelect<T extends string = string>({
  options,
  value,
  onChange,
  errorMessage,
  placeholder,
}: {
  value: string[];
  options: SelectOption<T>[];
  onChange: (value: string[]) => void;
  errorMessage?: string;
  placeholder?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const selectedOptions = options.filter((option) => value.includes(option.id));
  useEffect(() => {
    function closeOnClick(event: MouseEvent) {
      const node = event.target as Node;
      if (inputRef.current?.contains(node) || panelRef.current?.contains(node))
        return;
      setIsOpen(false);
    }

    document.body.addEventListener("click", closeOnClick, true);

    return () => {
      document.body.removeEventListener("click", closeOnClick, true);
    };
  }, []);

  function selectOpt(option: SelectOption<T>) {
    if (value.includes(option.id)) {
      onChange(value.filter((v) => v !== option.id));
      return;
    }
    onChange(_uniq([...value, option.id]));
  }

  return (
    <>
      <div>
        <div>
          <div className={"relative flex items-center"}>
            <input
              readOnly
              placeholder={placeholder}
              ref={inputRef}
              onClick={() => setIsOpen(true)}
              value={selectedOptions.map((o) => o.label).join(", ") || ""}
              className={classNames(
                "bg-white cursor-pointer border text-sm rounded-lg block w-full pt-2.5 pb-2.5 pl-2.5 pr-10 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white",
                errorMessage
                  ? "border-red-500 focus:ring-red-500"
                  : "dark:focus:border-blue-500 focus:border-blue-500 dark:border-gray-600 focus:ring-blue-500 dark:focus:ring-blue-500",
              )}
            />
            <ChevronUpDownIcon
              className={"absolute right-4 w-5 text-slate-500"}
            />
          </div>
          {errorMessage ? (
            <p className={"text-md mt-2 text-red-500"}>{errorMessage}</p>
          ) : null}
        </div>
        <div className={"relative w-full bg-blue-200"}>
          <Transition
            as={"div"}
            className={
              "w-full left-0 top-2 right-0 md:w-auto flex-shrink-0 absolute"
            }
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={isOpen}
          >
            <div
              ref={panelRef}
              className={
                "bg-white rounded-lg shadow-xl shadow-slate-200/40 overflow-hidden overflow-y-auto max-h-[300px]"
              }
            >
              {options.map((option) => {
                const isSelected = value.includes(option.id);
                const isDisabled = option.disabled?.isDisabled && !isSelected;
                return (
                  <div
                    onClick={() => {
                      if (isDisabled) return;
                      selectOpt(option);
                    }}
                    key={option.id}
                    className={classNames(
                      "flex items-center justify-between pl-2.5 pt-2.5 pb-2.5 border-b border-gray-100 pr-10",
                      isDisabled
                        ? "cursor-not-allowed bg-slate-50/30"
                        : "cursor-pointer hover:bg-primary hover:text-white",
                    )}
                  >
                    <div className={isDisabled ? "text-slate-400" : ""}>
                      <p className={""}>{option.label}</p>
                      {isDisabled && option.disabled?.message ? (
                        <p className={"text-sm font-light"}>
                          {option.disabled.message}
                        </p>
                      ) : null}
                    </div>
                    {isSelected ? (
                      <CheckIcon className={"absolute right-4 w-5"} />
                    ) : null}
                  </div>
                );
              })}
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
