import { Layout } from "../UI/Layout/Layout.tsx";
import { PrimaryButton } from "../UI/Button/PrimaryButton.tsx";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid/index.js";
import { useTranslation } from "react-i18next";
function ProductCard({
  title,
  description,
  cta,
  iconSrc,
}: {
  title: string;
  description: string;
  iconSrc: string;
  cta: {
    href: string;
  };
}) {
  const { t } = useTranslation("otherProductsScreen");
  return (
    <div
      className={
        "shadow-xl shadow-slate-200 rounded-lg p-4 bg-white flex flex-col space-y-3.5 flex-1"
      }
    >
      <div className={"flex items-center space-x-2"}>
        <img src={iconSrc} alt={title} className={"w-12 h-12"} />
        <h2 className={"font-semibold text-lg"}>{title}</h2>
      </div>
      <p className={"font-light text-slate-500"}>{description}</p>
      <div className={"mr-auto"}>
        <PrimaryButton
          iconRight={<ArrowLongRightIcon className={"w-5"} />}
          label={t("discover")}
          onClick={() => {
            window.open(cta.href, "_blank", "noopener noreferrer");
          }}
        />
      </div>
    </div>
  );
}
export const OtherProductsScreenPath = "/other-products";
export function OtherProductsScreen() {
  const { t } = useTranslation("otherProductsScreen");
  return (
    <Layout maxWClass={"max-w-full"}>
      <div className={""}>
        <h1 className={"font-semibold text-2xl"}>{t("title")}</h1>
        <p className={"font-light mt-3"}>{t("description")}</p>
        <div
          className={
            "flex flex-col md:flex-row md:space-x-5 md:space-y-0 space-y-5 mt-7"
          }
        >
          <ProductCard
            iconSrc={"/products/yay.svg"}
            cta={{
              href: "https://normasteaching.com/corsi-inglese-online/corso-di-pronuncia-inglese/",
            }}
            title={"YAY"}
            description={t("yay.description")}
          />
          <ProductCard
            iconSrc={"/products/nod.png"}
            cta={{
              href: "https://normasteaching.com/corsi-inglese-online/corsi-inglese-online-nod/",
            }}
            title={"NOD"}
            description={t("nod.description")}
          />
          <ProductCard
            iconSrc={"/products/cvwow.svg"}
            cta={{
              href: "https://normasteaching.com/corsi-inglese-online/cv-wow/",
            }}
            title={"CVWOW"}
            description={t("cvwow.description")}
          />
        </div>
      </div>
    </Layout>
  );
}
