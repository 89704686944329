import { CalendarCardComponent } from "../CalendarCardComponent.tsx";
import { BookableSlotFragmentFragment } from "../../gql/graphql.ts";
import { useBookProgressCheck } from "./useBookProgressCheck.ts";
import { useTranslation } from "react-i18next";
import { ProgressCheckCalendarCard } from "../BookedClassRoom/ProgressCheckCalendarCard.tsx";
import { slotToClassRoomCalendarCardItemDTO } from "../slotToClassRoomCalendarCardItemDTO.tsx";
import { useMessagePopupController } from "../../UI/MessagePopup/useMessagePopupController.ts";
import { ErrorMessagePopup } from "../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { RemainingLessonInfoBag } from "../RemainingLessonInfoBag.tsx";
import { useControlCalendarCardModal } from "../useControlCalendarCardModal.ts";

export function ProgressCheckSlotCalendarCard({
  slot,
  hasAtLeastOnProgressCheckBookable,
  remainingLessonInfo,
}: {
  slot: BookableSlotFragmentFragment;
  hasAtLeastOnProgressCheckBookable?: boolean;
  remainingLessonInfo?: RemainingLessonInfoBag;
}) {
  const { showModal, onOpen, onClose } = useControlCalendarCardModal(
    slot.slotId,
  );
  const { bookProgressCheck, loading } = useBookProgressCheck();
  const { t } = useTranslation("progressCheckSlotCard");
  const { title, description, show, showPopup, closePopup } =
    useMessagePopupController({
      timeoutInMs: 5 * 1000,
    });
  async function book() {
    try {
      await bookProgressCheck({
        classRoom: slot.classRoom
          ? {
              id: slot.classRoom.id,
            }
          : undefined,
        endDateUtc: slot.endDateUtc,
        slotId: slot.slotId,
        startDateUtc: slot.startDateUtc,
        timezone: slot.slotTimezone,
        topicId: slot.topic.id,
      });
    } catch (e) {
      showPopup(t("errorTitle"), t("errorDescription"));
      throw e;
    }
  }

  function getDisabledInfo(): {
    disabled: boolean;
    disabledReason?: string;
  } {
    if (!hasAtLeastOnProgressCheckBookable)
      return {
        disabled: true,
        disabledReason: t("allProgressCheckBooked"),
      };
    if (!slot.hasEnoughRemainingLessons)
      return {
        disabled: true,
        disabledReason: t("allHourForThisWeekUsed"),
      };

    return { disabled: false };
  }

  const disabledInfo = getDisabledInfo();

  return slot.userIsAParticipant ? (
    <ProgressCheckCalendarCard
      showModal={showModal}
      onClose={onClose}
      onOpen={onOpen}
      classRoom={slotToClassRoomCalendarCardItemDTO(slot)}
    />
  ) : (
    <>
      <ErrorMessagePopup
        title={title}
        description={description}
        show={show}
        onClose={closePopup}
      />
      <CalendarCardComponent
        showModal={showModal}
        onClose={onClose}
        onOpen={onOpen}
        loadingCta={loading}
        remainingLessonInfo={remainingLessonInfo}
        ctaAction={book}
        title={"Progress Check"}
        startDateUtc={slot.startDateUtc}
        level={slot.slotLevel}
        participantsCount={slot.participantsCount}
        capacity={slot.capacity}
        type={slot.slotType}
        disabled={disabledInfo.disabled}
        ctaLabel={t("bookProgressCheck")}
        disabledReason={disabledInfo.disabledReason}
        slotTimezone={slot.slotTimezone}
      />
    </>
  );
}
