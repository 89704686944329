import { graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";

export const GetMyRemainingLessons = graphql(`
  query getMyRemainingLessons {
    getMyRemainingLessons {
      remainingLessons
      totalAvailableLessons
    }
  }
`);

export function useMyRemainingLessons() {
  const { data, loading } = useQuery(GetMyRemainingLessons);
  return {
    remainingLessons: data?.getMyRemainingLessons.remainingLessons ?? 0,
    totalAvailableLessons:
      data?.getMyRemainingLessons.totalAvailableLessons ?? 0,
    loading,
  };
}
