import { DefaultNamespace, TFunction, use } from "i18next";
import { initReactI18next } from "react-i18next";
import { translations } from "./translations.ts";
import { selectedLanguageRepo } from "./selectedLanguageRepo.ts";
import { defaultLanguageCode } from "./defaultLanguageCode.ts";
const supportedLanguages = ["en", "it"];
export async function initI18n(): Promise<
  TFunction<DefaultNamespace, undefined>
> {
  const language = selectedLanguageRepo.getLanguage() ?? defaultLanguageCode;
  return await use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: translations,
      lng: supportedLanguages.includes(language)
        ? language
        : defaultLanguageCode, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      // debug: true,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
}
