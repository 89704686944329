export const it = {
  bookSpeakingLessonScreen: {
    title: "Prenota Real Life Conversation",
    emptyCalendarDay: "Ops, tutte le lezioni per oggi sono già al completo",
    frozenWeekDisabled:
      "Hai sospeso le Real Life Conversations per questa settimana",
  },
  bookProgressCheckScreen: {
    title: "Prenota Progress Check",
    emptyCalendarDay: "Ops, tutti i progress check sono già al completo",
    noProgressCheck: {
      title: "Ops! Non puoi ancora prenotare il Progress Check",
      subTitle:
        "Potrai prenotare il Progress Check solo quando avrai partecipato almeno a 10 Real Life Conversations",
      lessonsAttended:
        "{{lessonCompletedToUnlockProgressCheck}} di {{totalLessonNeededToUnlockProgressCheck}} Real Life Conversations partecipate",
      goToLessonCalendar: "Prenota Real Life Conversation",
    },
    frozenWeekDisabled: "Hai sospeso il tuo piano per questa settimana",
  },
  weeklyCalendar: {
    previousWeek: "Settimana Precedente",
    nextWeek: "Settimana Successiva",
  },
  weeklyRemainingLessons: {
    remainingLessons:
      "Lezioni Settimanali Rimanenti: <2>{{remainingLessonCount}} di {{totalLessonCount}}</2>",
  },
  sideMenu: {
    lessonCalendar: "{{index}}.Prenota Real Life Conversation",
    progressCheckCalendar: "{{index}}.Prenota Progress Check",
    bookedLessonsCalendar: "{{index}}.Le Mie Prenotazioni",
    menu: "Menu",
    startHere: "{{index}}.Come Funziona FPS",
    help: "{{index}}.Help",
    otherProducts: "{{index}}.Altri Prodotti",
    mindset: "{{index}}.Mindset",
    extraResources: "{{index}}.Risorse Extra",
    realLifeSkills: "{{index}}.Real Life Skills",
    liveWithNorma: "{{index}}.Norma's Hour",
  },
  progressCheckSlotCard: {
    bookProgressCheck: "Prenota Verifica Progressi",
    allProgressCheckBooked:
      "Hai già prenotato tutti i Progress Check disponibili",
    allHourForThisWeekUsed:
      "Hai già prenotato tutti Progress Check per questa settimana",
    errorTitle: "Oops, qualcosa è andato storto",
    errorDescription:
      "Si è verificato un errore durante la prenotazione del Progress Check, per favore aggiorna la pagina e riprova",
  },
  progressCheckCard: {
    cancelProgressCheck: "Cancella Progress Check",
    cancelProgressCheckDisabledDueToTime:
      "🚫 Puoi cancellare un Progress Check entro e non oltre 24 ore prima dell'inizio per non perderlo",
  },
  speakingClassSlotCard: {
    bookSpeakingLesson: "Prenota Real Life Conversation",
    allHourForThisWeekUsed:
      "Hai già prenotato tutte le tue Real Life Conversation per questa settimana",
    errorTitle: "Oops, qualcosa è andato storto",
    errorDescription:
      "Si è verificato un errore durante la prenotazione della Real Life Conversation, per favore aggiorna la pagina e riprova",
  },
  speakingClassCard: {
    cancelSpeakingLesson: "Cancella Real Life Conversation",
    cancelSpeakingLessonDisabledDueToTime:
      "🚫 Puoi cancellare una Real Life Conversation entro e non oltre 24 ore prima dell'inizio per non perderla",
  },
  calendarCardComponent: {
    backToCalendar: "Torna al calendario",
    lessonTopic: "Argomento della Real Life Conversation",
    close: "Chiudi",
    downloadLessonMaterial: "Scarica la presentazione della lezione",
    join: "Partecipa con Google Meet",
    remainingLessons:
      "Prenotazioni settimanali rimanenti: <2>{{remaining}} di {{total}}</2>",
    participants: "{{participantsCount}} su {{capacity}}",
    timezoneInfo: "L'orario è espresso in {{timezone}}",
    localTimeHour: "{{hour}} nella tua ora locale",
  },
  navBarDropDown: {
    logout: "Esci",
    englishLevel: "La tua Fluency Personality",
    myProfile: "Il mio account",
  },
  bookedClassRoomCalendarScreen: {
    title: "Le mie prenotazioni",
    emptyCalendarDay:
      "Non hai prenotato nessuna Real Life Conversation e/o Progress Check oggi!",
    noClassRoomYet: {
      title: "Nessuna prenotazione",
      description: "Non hai ancora prenotato le tue Real Life Conversation",
      goToLessonCalendar: "Prenota Ora!",
    },
  },
  totalRemainingLessonsComponent: {
    title: "Lezioni Totali Rimanenti",
    buy: "Compra più Lezioni",
    remainingLessons:
      "<0>{{remainingLessons}}</0> di {{totalAvailableLessons}}",
  },
  leavingClassError: {
    title: "Errore",
    description:
      "Puoi cancellare una Real Life Conversation o un Progress Check entro e non oltre 24 ore prima dell'inizio per non perderli",
  },
  completeUserDataScreen: {
    title: "Benvenuto!",
    completeData: "Completa i tuoi dati per continuare",
  },
  completeUserForm: {
    givenName: "Nome",
    familyName: "Cognome",
    save: "Conferma e procedi",
    insertAName: "Inserisci un nome",
    insertASurname: "Inserisci un cognome",
  },
  navBarWithLogo: {
    logOut: "Esci",
  },
  editUserForm: {
    email: "Email",
    givenName: "Nome",
    familyName: "Cognome",
    save: "Salva",
    password: "Password",
    resetPassword: "Reimposta Password",
    insertAName: "Inserisci il tuo nome",
    insertASurname: "Inserisci il tuo cognome",
    insertAValidEmail: "Inserisci un'email valida",
    passwordResetSentTitle: "Reimpostazione Password",
    passwordResetSentDescription:
      "Ti abbiamo inviato un link per reimpostare la password sulla tua email",
    passwordResetErrorTitle: "Reimpostazione Password",
    passwordResetErrorDescription:
      "Si è verificato un errore durante l'invio del link per la reimpostazione della password, per favore riprova",
  },
  myProfileMyAccountTab: {
    personalInformation: "Informazioni Personali",
    lessonsPlanTitle: "Real Life Conversations",
  },
  myAccountEnglishLevelTab: {
    myEnglishLevel: "La mia Fluency Personality",
    myCefrLevel: "Il mio livello CEFR",
    commonEuropeanFrameOfReference: "Quadro Comune Europeo di Riferimento",
    commonEuropeanFrameOfReferenceDesc:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.",

    mySpeakingLevel: "La mia Fluency Personality",
    mySpeakingLevelTitle: "Livello di Speaking",
    mySpeakingLevelDesc:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  myProfileScreen: {
    title: "Informazioni Personali",
    myAccount: "Il mio account",
    myEnglishLevel: "La mia Fluency Personality",
  },
  missingGrammarTestScreen: {
    title: "Benvenuto in Fluente Per Sempre!",
    description:
      "In 6 mesi (o meno) diventerai Fluente con il metodo English Norma-Lization. Guarda il video di Norma e inizia oggi questa fantastica avventura!",
    goToTest: "Vai al Self-Assessment",
    perks: {
      perk1: {
        title: "Self-Assessment",
        description:
          "Completa il Self-Assessment e scopri la tua Fluency Personality",
      },
      perk2: {
        title: "Real Life Conversations",
        description:
          "Prenota subito le tue Real Life Conversations (Live class) per questa settimana",
      },
      perk3: {
        title: "Real Life Skills",
        description:
          "Esercitati con le Real Life Skills (video e audio lezioni) sulla piattaforma",
      },
    },
  },
  missingAccountScreen: {
    title: "Account mancante",
    description:
      "Sembra che non ci sia un account associato alle tue credenziali. Se pensi che sia un errore, per favore contatta il nostro team di supporto",
    contactSupport: "Contatta il supporto",
  },
  speakingFeatureDisabled: {
    title: "Funzionalità non disponibile",
    subTitle:
      "Abbiamo bisogno che tu completi il tuo Test di Valutazione così possiamo capire il tuo livello di parlato e raccomandare le lezioni più adatte a te",
    bookEvaluationCheck: "Vai al test di valutazione",
  },
  deleteAccount: {
    title: "Elimina account",
    warningTitle: "Attenzione! Azione irreversibile",
    warningText:
      "Stai per eliminare definitivamente il tuo account, le prenotazioni e tutto il tuo storico di lezioni completate. Questa azione è irreversibile e non può essere annullata. Contattaci a help@normasteaching.com per maggiori informazioni",
    ctaText: "Elimina definitivamente il mio account",
    ctaConfirm:
      "Sei sicuro di voler eliminare il tuo account, perderai accesso a Fluente Per Sempre",
  },
  hasUnlockedProgressCheckSideMenu: {
    title: "Hai sbloccato il Progress Check!",
    description: "Accedi al calendario e procedi con la tua prenotazione",
    bookNow: "Prenota Ora",
  },
  startHere: {
    cta: "Scarica la Promessa",
    title: "Come funziona Fluente Per Sempre",
    description:
      "Guarda il tutorial e scopri come diventare fluente in sei mesi o meno",
    perks: {
      perk1: {
        title: "Real Life Conversations",
        description:
          "Prenota le Real Life Conversation e sblocca la tua Fluency",
      },
      perk2: {
        title: "Real Life Skills",
        description:
          "Esercitati con le Real Life Skills: guarda, ascolta, comprendi e ripeti",
      },
      perk3: {
        title: "Mindset e Risorse Extra",
        description:
          "Approfondici il metodo English Norma-Lization nella sezione Mindset e scopri risorse aggiuntive nella sezione Risorse Extra",
      },
    },
  },
  completeUserNavBar: {
    theCourse: "Il corso",
    skills: {
      title: "Real Life Skills",
      description: "Audio, Video lezioni ed Esercizi",
    },
    mindset: {
      title: "Mindset",
      description: "Il giusto approccio per iniziare",
    },
    resources: {
      title: "Risorse Extra",
      description: "Scopri contenuti aggiuntivi",
    },
  },
  footer: {
    privacyPolicy: "Legal Info",
    cookiePolicy: "Cookies",
    helpCenter: "Help",
  },
  languageSelector: {
    english: "Inglese",
    italian: "Italiano",
  },
  otherProductsScreen: {
    title: "Gli altri prodotti di Norma's Teaching",
    description:
      "Scopri come migliorare e allenare il tuo inglese per portarlo al prossimo livello",
    nod: {
      description:
        "NOD, Norma On Demand, è la piattaforma ad abbonamento di Norma's Teaching. Oltre 1000 videolezioni in HD e 30 nuove lezioni ogni mese. 3 appuntamenti live al mese con Norma e i Teacher e una community di studenti dedicata. 5 minuti al giorno per continuare ad allenare il tuo inglese in modo Fast, Fun and Fantastic®",
    },
    yay: {
      description:
        "Pronunci-YAY-tion! è il corso online per migliorare la tua pronuncia in sole 5 settimane composto da 16 lezioni in video HD, 5 moduli, esercitazioni audio, quiz, glossari, strumenti utili per l’apprendimento e schede per il ripasso dei contenuti.",
    },
    cvwow: {
      description:
        "CVwow è il corso online per creare un CV in inglese con WOW factor! 5 moduli di videolezioni in HD con esempi pratici, template e tutorial step by step per distinguerti tra migliaia di candidati e farti notare dai recruiter. Il tuo lavoro dei sogni ti aspetta!",
    },
    discover: "Scopri",
  },
  notVisibleAvailability: {
    title: "Ops!",
    desc: "Puoi prenotare le tue Real Life Conversations con un anticipo massimo di 3 settimane rispetto a quella corrente. Ogni settimana il calendario si aggiorna automaticamente. Ritorna presto.",
  },
  notActiveAccountScreen: {
    title: "L'account non è attivo",
    description:
      "Sembra che il tuo account non sia attivo al momento. Se credi che sia un errore, contattaci a help@normasteaching.com",
  },
  postSelfAssessmentLanding: {
    title: "Grazie per aver completato il Self-Assessment 😊",
    subTitle:
      "Che emozione! Tra poco scoprirai la tua Fluency Personality. Ti preghiamo di non chiudere questa pagina",
    errorTitle: "Ops, qualcosa è andato storto 😢",
    errorSubtitle:
      "Ti preghiamo di attendere 5 minuti e riprovare. Se il problema persiste contattaci a help@normasteaching.com",
    tryAgain: "Riprova",
    resultTitle: "🎉 Ecco il tuo risultato! 🎉",
    resultSubTitle:
      "La tua <1>Fluency Personality</1> è: <4>{{speakingLevel}}</4>",

    letsStart: "Perfetto, cominciamo!",
  },
  levelDesc: {
    explorers:
      "Gli Explorers stanno appena iniziando a imparare l'inglese e non si sentono ancora sicuri o solidi nelle loro competenze. Conoscono alcune parole e grammatica ma spesso faticano a mettere insieme semplici frasi complete da soli. Possono capire e utilizzare espressioni di base per le necessità quotidiane. Parlando con gli altri, possono dire chi sono e fare o rispondere a domande semplici su dove vivono, chi conoscono e cosa possiedono. Ma hanno davvero bisogno che l'altra persona parli lentamente e chiaramente e sia disposta ad aiutare affinché la conversazione funzioni. Questo livello consiste nel compiere quei primi passi nell'apprendimento di come comunicare idee più complesse in seguito.",
    navigators:
      "I Navigators possono parlare di argomenti che conoscono bene, come i loro hobby o ciò che piace loro, ma trovano difficile conversare su argomenti meno specifici o in situazioni quotidiane. Stanno migliorando nella comprensione di più cose, ma spesso non riescono a trovare le parole giuste quando ne hanno bisogno. Possono capire frasi semplici ed espressioni comuni riguardanti informazioni personali di base, lo shopping, dove vivono e lavorano. Possono avere conversazioni semplici su cose familiari, ma di solito si attengono a frasi che hanno imparato a memoria.",
    pioneers:
      "I Pioneers conoscono abbastanza bene l'inglese per cavarsela in molte situazioni diverse. Potrebbero non sempre scegliere le parole perfette o mettere insieme frasi come farebbe un madrelingua, ma riescono a farsi capire. Il loro inglese è buono per l'uso quotidiano, e non commettono errori nelle strutture semplici. Possono seguire ciò che viene detto nelle conversazioni normali sul lavoro, a scuola, sugli interessi o su argomenti che non sono completamente nuovi per loro. Sono anche pronti quando viaggiano e sono in grado di parlare di cose che conoscono o che gli piacciono. Possono iniziare, mantenere e concludere conversazioni su argomenti familiari, condividendo i loro pensieri e spiegando le cose senza troppi problemi.",
    voyagers:
      "I Voyagers a questo livello stanno diventando piuttosto sicuri nel loro inglese. Possono conversare facilmente utilizzando una vasta gamma di strutture e possono cogliere il punto principale delle conversazioni e dei testi, anche quando non conoscono ogni parola. Sono bravi a comprendere sia argomenti quotidiani che più complicati. Quando parlano, riescono a stare al passo con i madrelingua inglesi senza troppi problemi, rendendo la conversazione naturale. Sono anche pronti a partecipare a discussioni su argomenti che conoscono, condividendo i loro pensieri in modo chiaro e dettagliato. La maggior parte delle volte, parlano correttamente l'inglese e fanno solo piccoli errori. Questo livello dimostra che hanno fatto molta strada nell'apprendimento dell'inglese, concentrandosi ora sul migliorare nell'esprimersi chiaramente e comprendere idee più profonde o complesse.",
    starters:
      "Gli Starters sono all'inizio del loro viaggio nell'apprendimento dell'inglese. Non hanno precedenti esperienze con la lingua (o ne hanno ben poche) e necessitano di un supporto extra per muovere i primi passi. Questo livello fornisce loro le basi necessarie per acquisire sicurezza e affrontare il nuovo percorso linguistico con fiducia. Con una guida attenta e un approccio paziente, saranno in grado di superare le prime sfide e prepararsi per il livello successivo.",
  },
  levelTitle: {
    explorers: "Explorer",
    navigators: "Navigator",
    pioneers: "Pioneer",
    voyagers: "Voyager",
    starters: "Starter",
  },
  megaMenu: {
    back: "Indietro",
  },
  lessonPlanManagement: {
    backToProfile: "Torna al profilo",
    freezeWeekComponent: {
      title: "Sospendi Real Life Conversations",
      explanation:
        "Durante i 6 mesi di corso potrai decidere di sospendere per un massimo di 2 settimane le Real Life Conversations, ovvero 4 lezioni live, senza perderle. Durante le settimane sospese avrai comunque accesso a tutti gli altri contenuti di Fluente Per Sempre.",
      rememberTitle: "Ricordati che:",
      remember1: "La settimana va dalla domenica al sabato",
      remember2:
        "La sospensione deve avvenire in anticipo, non puoi sospendere una settimana in corso o già passata.",
      selectWeekToSuspend: "Seleziona le settimane da sospendere",
      fromTo: "Da {{from}} a {{to}}",
      alreadySuspendedAllWeeks:
        "Hai già sospeso tutte le settimane disponibili.",
      suspend: "Sospendi",
      selectAtLeastOneWeek: "Seleziona almeno una settimana da sospendere",
      freezeError: {
        generic: {
          title: "Ops",
          description:
            "C'è stato un errore durante la sospensione della settimana, per favore riprova",
        },
        alreadyBookedClassroom: {
          title: "Impossibile sospendere questa settimana",
          description: `Hai già prenotato delle lezioni per la settimana che va da {{from}} a {{to}}. Se vuoi sospenderla, devi prima cancellare queste lezioni`,
        },
      },
    },
    frozenWeeks: {
      title: "Settimane sospese",
      noSuspensionTitle: "Nessuna sospensione",
      noSuspensionDescription: "Al momento non hai sospeso nessuna settimana",
      item: {
        fromTo: "Da {{from}} a {{to}}",
        unfreezeError: {
          title: "Ops",
          description:
            "Qualcosa è andato storto durante la riattivazione della settimana, per favore riprova",
        },
      },
    },
  },
  lessonPlanManagementButton: {
    label: "Gestisci Piano di Studi",
  },
  somethingWentWrong: {
    title: "Qualcosa è andato storto",
    description:
      "Si è verificato un errore durante il caricamento della pagina, per favore aggiorna la pagina e riprova",
    reload: "Ricarica",
  },
  leaveClassRoomReviewScreen: {
    title: "Valuta lezione di {{date}} con l'insegnante {{teacherName}}",
    dateFormat: "ddd DD MMM YYYY [alle] HH:mm",
    error: {
      title: "Ops!",
      description:
        "Qualcosa è andato storto mentre cercavi di lasciare una recensione. Per favore, riprova più tardi.",
    },
  },
  leaveClassroomReviewForm: {
    errors: {
      requireValueError: "Seleziona un valore tra 1 e 5",
    },
    labels: {
      topicUsefulness:
        "Quanto hai trovato utile il topic della Real Life Conversation?",
      topicDifficulty:
        "Quanto hai trovato facile/difficile il topic della  Real Life Conversation?",
      teacherClearance:
        "Quanto è stato chiaro il teacher nello spiegare i concetti?",
      teacherInvolvement:
        "Quanto è stato coinvolgente il teacher durante la lezione?",
      teacherComfortable:
        "Quanto ti ha fatto sentire a tuo agio il teacher durante la lezione?",
      generalLesson:
        "Come valuteresti l’esperienza di questa Real Life Conversation in generale?",
      notes: "Note (facoltativo)",
      topicUsefulnessScore: {
        veryLow: "Per niente utile",
        low: "Poco utile",
        medium: "Abbastanza utile",
        high: "Molto utile",
        veryHigh: "Estremamente utile",
      },
      topicDifficultyScore: {
        veryLow: "Estremamente difficile",
        low: "Molto difficile",
        medium: "Ok",
        high: "Facile",
        veryHigh: "Molto facile",
      },
      teacherClearanceScore: {
        veryLow: "Per niente chiaro",
        low: "Poco chiaro",
        medium: "Abbastanza chiaro",
        high: "Chiaro",
        veryHigh: "Estremamente chiaro",
      },
      teacherInvolvementScore: {
        veryLow: "Per niente coinvolgente",
        low: "Poco coinvolgente",
        medium: "Abbastanza coinvolgente",
        high: "Coinvolgente",
        veryHigh: "Estremamente coinvolgente",
      },
      teacherComfortableScore: {
        veryLow: "Per niente a mio agio",
        low: "Poco a mio agio",
        medium: "Abbastanza a mio agio",
        high: "Molto a mio agio",
        veryHigh: "Estremamente a mio agio",
      },
      generalLessonScore: {
        veryLow: "Per niente positiva",
        low: "Poco positiva",
        medium: "Abbastanza positiva",
        high: "Molto positiva",
        veryHigh: "Estremamente positiva",
      },
    },
    section: {
      topic: "Topic - Real Life Conversation",
      teacher: "Insegnante - Real Life Conversation",
      lesson: "Esperienza generale - Real Life Conversation",
    },
    sendValuation: "Invia Valutazione",
  },
  notFound: {
    title: "Ops! 🥺",
    description: "Quello che stavi cercando sembra non essere più qui",
  },
  leaveReviewThankYouScreen: {
    title: "Grazie! 😊",
    description: "La tua opinione è molto importante per noi",
    cta: "Vai a Fluente per Sempre",
  },
};
