import { Card } from "../Card.tsx";
import { useTranslation } from "react-i18next";

export function NotFoundComponent() {
  const { t } = useTranslation("notFound");
  return (
    <Card>
      <h1 className={"text-4xl font-bold text-center"}>{t("title")}</h1>
      <p className={"text-center text-lg"}>{t("description")}</p>
    </Card>
  );
}
