/* eslint-disable */
import * as types from "./graphql.js";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  query getClassRoomByIdV2($classRoomId: String!) {\n    getClassRoomByIdV2(classRoomId: $classRoomId) {\n      ...ClassRoomV2\n    }\n  }\n":
    types.GetClassRoomByIdV2Document,
  "\n  mutation leaveClassRoomReview(\n    $classRoomId: String!\n    $reviewNotes: String!\n    $teacherClearanceScore: Int!\n    $teacherComfortableScore: Int!\n    $teacherInvolvementScore: Int!\n    $generalLessonScore: Int!\n    $topicDifficultyScore: Int!\n    $topicUsefulnessScore: Int!\n  ) {\n    leaveClassRoomReview(\n      classRoomId: $classRoomId\n      reviewNotes: $reviewNotes\n      teacherClearanceScore: $teacherClearanceScore\n      teacherComfortableScore: $teacherComfortableScore\n      teacherInvolvementScore: $teacherInvolvementScore\n      generalLessonScore: $generalLessonScore\n      topicDifficultyScore: $topicDifficultyScore\n      topicUsefulnessScore: $topicUsefulnessScore\n    ) {\n      id\n    }\n  }\n":
    types.LeaveClassRoomReviewDocument,
  "\n  fragment BookableSlotFragment on BookableSlot {\n    slotId\n    startDateUtc\n    endDateUtc\n    hasEnoughRemainingLessons\n    topic {\n      name\n      id\n      level\n      pdfUrl\n    }\n    capacity\n    slotLevel\n    participantsCount\n    slotType\n    userIsAParticipant\n    slotTimezone\n    classRoom {\n      id\n      meetingUri\n      canBeLeaved\n    }\n  }\n":
    types.BookableSlotFragmentFragmentDoc,
  "\n  fragment ClassRoomFragment on ClassRoomWithoutParticipantDto {\n    id\n    endedAtUtc\n    startedAtUtc\n    topic {\n      name\n      id\n      level\n      pdfUrl\n    }\n    type\n    speakingLevel\n    capacity\n    participantsCount\n    meetingUri\n    canBeLeaved\n    classRoomTimezone\n  }\n":
    types.ClassRoomFragmentFragmentDoc,
  "\n  fragment ClassRoomV2 on ClassRoomV2 {\n    id\n    endedAtUtc\n    startedAtUtc\n    topic {\n      name\n      id\n      level\n      pdfUrl\n    }\n    type\n    speakingLevel\n    capacity\n    participantsCount\n    meetingUri\n    canBeLeaved\n    classRoomTimezone\n    teacher {\n      id\n      givenName\n      familyName\n    }\n  }\n":
    types.ClassRoomV2FragmentDoc,
  "\n  mutation leaveClassRoom($classRoomId: String!) {\n    leaveClassRoom(classRoomId: $classRoomId) {\n      ...ClassRoomFragment\n    }\n  }\n":
    types.LeaveClassRoomDocument,
  "\n  query getUserClassRoomsBetweenDates(\n    $fromDate: String!\n    $toDate: String!\n    $clientTimezone: String!\n  ) {\n    getUserClassRoomsBetweenDates(\n      fromDate: $fromDate\n      toDate: $toDate\n      clientTimezone: $clientTimezone\n    ) {\n      ...ClassRoomFragment\n    }\n  }\n":
    types.GetUserClassRoomsBetweenDatesDocument,
  "\n  mutation bookProgressCheck($bookSlotInput: BookSlotInput!) {\n    bookProgressCheck(bookSlotInput: $bookSlotInput)\n  }\n":
    types.BookProgressCheckDocument,
  "\n  query hasUnlockedProgressCheck {\n    hasUnlockedProgressCheck {\n      lessonCompletedToUnlockProgressCheck\n      totalLessonNeededToUnlockProgressCheck\n      hasUnlockedProgressCheck\n    }\n  }\n":
    types.HasUnlockedProgressCheckDocument,
  "\n  query getAvailabilityForProgressCheck(\n    $clientTimezone: String!\n    $fromDate: String!\n    $toDate: String!\n  ) {\n    getAvailabilityForProgressCheck(\n      clientTimezone: $clientTimezone\n      fromDate: $fromDate\n      toDate: $toDate\n    ) {\n      slots {\n        ...BookableSlotFragment\n      }\n      hasAtLeastOnProgressCheckBookable\n    }\n  }\n":
    types.GetAvailabilityForProgressCheckDocument,
  "\n  mutation bookSpeakingClass($bookSlotInput: BookSlotInput!) {\n    bookSpeakingClass(bookSlotInput: $bookSlotInput)\n  }\n":
    types.BookSpeakingClassDocument,
  "\n  query getAvailabilityForSpeakingClass(\n    $clientTimezone: String!\n    $fromDate: String!\n    $toDate: String!\n  ) {\n    getAvailabilityForSpeakingClass(\n      clientTimezone: $clientTimezone\n      fromDate: $fromDate\n      toDate: $toDate\n    ) {\n      slots {\n        ...BookableSlotFragment\n      }\n    }\n  }\n":
    types.GetAvailabilityForSpeakingClassDocument,
  "\n  query hasSpeakingEnabled {\n    hasSpeakingEnabled\n  }\n":
    types.HasSpeakingEnabledDocument,
  "\n  query isActiveUser {\n    isActiveUser\n  }\n":
    types.IsActiveUserDocument,
  "\n  fragment FrozenWeek on FrozenWeekDto {\n    startOfWeekUtc\n    endOfWeekUtc\n  }\n":
    types.FrozenWeekFragmentDoc,
  "\n  fragment GetFreezableWeeksResponse on GetFreezableWeeksResponse {\n    weeks {\n      planId\n      week {\n        startOfWeekUtc\n        endOfWeekUtc\n      }\n    }\n    remainingWeeksToFreeze {\n      planId\n      remainingCount\n    }\n  }\n":
    types.GetFreezableWeeksResponseFragmentDoc,
  "\n  query getFreezableWeeks {\n    getFreezableWeeks {\n      ...GetFreezableWeeksResponse\n    }\n  }\n":
    types.GetFreezableWeeksDocument,
  "\n  mutation addFrozenWeek($week: FrozenWeekInput!) {\n    addFrozenWeek(week: $week)\n  }\n":
    types.AddFrozenWeekDocument,
  "\n  query isFrozenWeek($week: FrozenWeekInput!) {\n    isFrozenWeek(week: $week)\n  }\n":
    types.IsFrozenWeekDocument,
  "\n  query getMyFrozenWeeks {\n    getMyFrozenWeeks {\n      ...FrozenWeek\n    }\n  }\n":
    types.GetMyFrozenWeeksDocument,
  "\n  mutation removeFrozenWeek($week: FrozenWeekInput!) {\n    removeFrozenWeek(week: $week)\n  }\n":
    types.RemoveFrozenWeekDocument,
  "\n  query getMyRemainingLessons {\n    getMyRemainingLessons {\n      remainingLessons\n      totalAvailableLessons\n    }\n  }\n":
    types.GetMyRemainingLessonsDocument,
  "\n  query getUserRemainingLessonByWeek(\n    $dateUtc: String!\n    $clientTimezone: String!\n  ) {\n    getUserRemainingLessonByWeek(\n      dateUtc: $dateUtc\n      clientTimezone: $clientTimezone\n    ) {\n      remainingLessonCount\n      totalLessonCount\n    }\n  }\n":
    types.GetUserRemainingLessonByWeekDocument,
  "\n  mutation deleteMe {\n    deleteMe\n  }\n": types.DeleteMeDocument,
  "\n  fragment UserFragment on User {\n    id\n    email\n    givenName\n    familyName\n    speakingLevel\n    hideBookProgressCheckSection\n    hideBookSpeakingClassSection\n  }\n":
    types.UserFragmentFragmentDoc,
  "\n  query me {\n    me {\n      ...UserFragment\n    }\n  }\n":
    types.MeDocument,
  "\n  mutation sendResetPasswordEmail {\n    sendResetPasswordEmail\n  }\n":
    types.SendResetPasswordEmailDocument,
  "\n  mutation updateLastLogin {\n    updateLastLogin\n  }\n":
    types.UpdateLastLoginDocument,
  "\n  mutation updateMe($givenName: String, $familyName: String) {\n    updateMe(givenName: $givenName, familyName: $familyName) {\n      ...UserFragment\n    }\n  }\n":
    types.UpdateMeDocument,
  "\n  query getUserClassRoomCount {\n    getUserClassRoomCount {\n      totalClassRoomCount\n    }\n  }\n":
    types.GetUserClassRoomCountDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getClassRoomByIdV2($classRoomId: String!) {\n    getClassRoomByIdV2(classRoomId: $classRoomId) {\n      ...ClassRoomV2\n    }\n  }\n",
): (typeof documents)["\n  query getClassRoomByIdV2($classRoomId: String!) {\n    getClassRoomByIdV2(classRoomId: $classRoomId) {\n      ...ClassRoomV2\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation leaveClassRoomReview(\n    $classRoomId: String!\n    $reviewNotes: String!\n    $teacherClearanceScore: Int!\n    $teacherComfortableScore: Int!\n    $teacherInvolvementScore: Int!\n    $generalLessonScore: Int!\n    $topicDifficultyScore: Int!\n    $topicUsefulnessScore: Int!\n  ) {\n    leaveClassRoomReview(\n      classRoomId: $classRoomId\n      reviewNotes: $reviewNotes\n      teacherClearanceScore: $teacherClearanceScore\n      teacherComfortableScore: $teacherComfortableScore\n      teacherInvolvementScore: $teacherInvolvementScore\n      generalLessonScore: $generalLessonScore\n      topicDifficultyScore: $topicDifficultyScore\n      topicUsefulnessScore: $topicUsefulnessScore\n    ) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation leaveClassRoomReview(\n    $classRoomId: String!\n    $reviewNotes: String!\n    $teacherClearanceScore: Int!\n    $teacherComfortableScore: Int!\n    $teacherInvolvementScore: Int!\n    $generalLessonScore: Int!\n    $topicDifficultyScore: Int!\n    $topicUsefulnessScore: Int!\n  ) {\n    leaveClassRoomReview(\n      classRoomId: $classRoomId\n      reviewNotes: $reviewNotes\n      teacherClearanceScore: $teacherClearanceScore\n      teacherComfortableScore: $teacherComfortableScore\n      teacherInvolvementScore: $teacherInvolvementScore\n      generalLessonScore: $generalLessonScore\n      topicDifficultyScore: $topicDifficultyScore\n      topicUsefulnessScore: $topicUsefulnessScore\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment BookableSlotFragment on BookableSlot {\n    slotId\n    startDateUtc\n    endDateUtc\n    hasEnoughRemainingLessons\n    topic {\n      name\n      id\n      level\n      pdfUrl\n    }\n    capacity\n    slotLevel\n    participantsCount\n    slotType\n    userIsAParticipant\n    slotTimezone\n    classRoom {\n      id\n      meetingUri\n      canBeLeaved\n    }\n  }\n",
): (typeof documents)["\n  fragment BookableSlotFragment on BookableSlot {\n    slotId\n    startDateUtc\n    endDateUtc\n    hasEnoughRemainingLessons\n    topic {\n      name\n      id\n      level\n      pdfUrl\n    }\n    capacity\n    slotLevel\n    participantsCount\n    slotType\n    userIsAParticipant\n    slotTimezone\n    classRoom {\n      id\n      meetingUri\n      canBeLeaved\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomFragment on ClassRoomWithoutParticipantDto {\n    id\n    endedAtUtc\n    startedAtUtc\n    topic {\n      name\n      id\n      level\n      pdfUrl\n    }\n    type\n    speakingLevel\n    capacity\n    participantsCount\n    meetingUri\n    canBeLeaved\n    classRoomTimezone\n  }\n",
): (typeof documents)["\n  fragment ClassRoomFragment on ClassRoomWithoutParticipantDto {\n    id\n    endedAtUtc\n    startedAtUtc\n    topic {\n      name\n      id\n      level\n      pdfUrl\n    }\n    type\n    speakingLevel\n    capacity\n    participantsCount\n    meetingUri\n    canBeLeaved\n    classRoomTimezone\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomV2 on ClassRoomV2 {\n    id\n    endedAtUtc\n    startedAtUtc\n    topic {\n      name\n      id\n      level\n      pdfUrl\n    }\n    type\n    speakingLevel\n    capacity\n    participantsCount\n    meetingUri\n    canBeLeaved\n    classRoomTimezone\n    teacher {\n      id\n      givenName\n      familyName\n    }\n  }\n",
): (typeof documents)["\n  fragment ClassRoomV2 on ClassRoomV2 {\n    id\n    endedAtUtc\n    startedAtUtc\n    topic {\n      name\n      id\n      level\n      pdfUrl\n    }\n    type\n    speakingLevel\n    capacity\n    participantsCount\n    meetingUri\n    canBeLeaved\n    classRoomTimezone\n    teacher {\n      id\n      givenName\n      familyName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation leaveClassRoom($classRoomId: String!) {\n    leaveClassRoom(classRoomId: $classRoomId) {\n      ...ClassRoomFragment\n    }\n  }\n",
): (typeof documents)["\n  mutation leaveClassRoom($classRoomId: String!) {\n    leaveClassRoom(classRoomId: $classRoomId) {\n      ...ClassRoomFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getUserClassRoomsBetweenDates(\n    $fromDate: String!\n    $toDate: String!\n    $clientTimezone: String!\n  ) {\n    getUserClassRoomsBetweenDates(\n      fromDate: $fromDate\n      toDate: $toDate\n      clientTimezone: $clientTimezone\n    ) {\n      ...ClassRoomFragment\n    }\n  }\n",
): (typeof documents)["\n  query getUserClassRoomsBetweenDates(\n    $fromDate: String!\n    $toDate: String!\n    $clientTimezone: String!\n  ) {\n    getUserClassRoomsBetweenDates(\n      fromDate: $fromDate\n      toDate: $toDate\n      clientTimezone: $clientTimezone\n    ) {\n      ...ClassRoomFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation bookProgressCheck($bookSlotInput: BookSlotInput!) {\n    bookProgressCheck(bookSlotInput: $bookSlotInput)\n  }\n",
): (typeof documents)["\n  mutation bookProgressCheck($bookSlotInput: BookSlotInput!) {\n    bookProgressCheck(bookSlotInput: $bookSlotInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query hasUnlockedProgressCheck {\n    hasUnlockedProgressCheck {\n      lessonCompletedToUnlockProgressCheck\n      totalLessonNeededToUnlockProgressCheck\n      hasUnlockedProgressCheck\n    }\n  }\n",
): (typeof documents)["\n  query hasUnlockedProgressCheck {\n    hasUnlockedProgressCheck {\n      lessonCompletedToUnlockProgressCheck\n      totalLessonNeededToUnlockProgressCheck\n      hasUnlockedProgressCheck\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getAvailabilityForProgressCheck(\n    $clientTimezone: String!\n    $fromDate: String!\n    $toDate: String!\n  ) {\n    getAvailabilityForProgressCheck(\n      clientTimezone: $clientTimezone\n      fromDate: $fromDate\n      toDate: $toDate\n    ) {\n      slots {\n        ...BookableSlotFragment\n      }\n      hasAtLeastOnProgressCheckBookable\n    }\n  }\n",
): (typeof documents)["\n  query getAvailabilityForProgressCheck(\n    $clientTimezone: String!\n    $fromDate: String!\n    $toDate: String!\n  ) {\n    getAvailabilityForProgressCheck(\n      clientTimezone: $clientTimezone\n      fromDate: $fromDate\n      toDate: $toDate\n    ) {\n      slots {\n        ...BookableSlotFragment\n      }\n      hasAtLeastOnProgressCheckBookable\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation bookSpeakingClass($bookSlotInput: BookSlotInput!) {\n    bookSpeakingClass(bookSlotInput: $bookSlotInput)\n  }\n",
): (typeof documents)["\n  mutation bookSpeakingClass($bookSlotInput: BookSlotInput!) {\n    bookSpeakingClass(bookSlotInput: $bookSlotInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getAvailabilityForSpeakingClass(\n    $clientTimezone: String!\n    $fromDate: String!\n    $toDate: String!\n  ) {\n    getAvailabilityForSpeakingClass(\n      clientTimezone: $clientTimezone\n      fromDate: $fromDate\n      toDate: $toDate\n    ) {\n      slots {\n        ...BookableSlotFragment\n      }\n    }\n  }\n",
): (typeof documents)["\n  query getAvailabilityForSpeakingClass(\n    $clientTimezone: String!\n    $fromDate: String!\n    $toDate: String!\n  ) {\n    getAvailabilityForSpeakingClass(\n      clientTimezone: $clientTimezone\n      fromDate: $fromDate\n      toDate: $toDate\n    ) {\n      slots {\n        ...BookableSlotFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query hasSpeakingEnabled {\n    hasSpeakingEnabled\n  }\n",
): (typeof documents)["\n  query hasSpeakingEnabled {\n    hasSpeakingEnabled\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query isActiveUser {\n    isActiveUser\n  }\n",
): (typeof documents)["\n  query isActiveUser {\n    isActiveUser\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FrozenWeek on FrozenWeekDto {\n    startOfWeekUtc\n    endOfWeekUtc\n  }\n",
): (typeof documents)["\n  fragment FrozenWeek on FrozenWeekDto {\n    startOfWeekUtc\n    endOfWeekUtc\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment GetFreezableWeeksResponse on GetFreezableWeeksResponse {\n    weeks {\n      planId\n      week {\n        startOfWeekUtc\n        endOfWeekUtc\n      }\n    }\n    remainingWeeksToFreeze {\n      planId\n      remainingCount\n    }\n  }\n",
): (typeof documents)["\n  fragment GetFreezableWeeksResponse on GetFreezableWeeksResponse {\n    weeks {\n      planId\n      week {\n        startOfWeekUtc\n        endOfWeekUtc\n      }\n    }\n    remainingWeeksToFreeze {\n      planId\n      remainingCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getFreezableWeeks {\n    getFreezableWeeks {\n      ...GetFreezableWeeksResponse\n    }\n  }\n",
): (typeof documents)["\n  query getFreezableWeeks {\n    getFreezableWeeks {\n      ...GetFreezableWeeksResponse\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation addFrozenWeek($week: FrozenWeekInput!) {\n    addFrozenWeek(week: $week)\n  }\n",
): (typeof documents)["\n  mutation addFrozenWeek($week: FrozenWeekInput!) {\n    addFrozenWeek(week: $week)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query isFrozenWeek($week: FrozenWeekInput!) {\n    isFrozenWeek(week: $week)\n  }\n",
): (typeof documents)["\n  query isFrozenWeek($week: FrozenWeekInput!) {\n    isFrozenWeek(week: $week)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getMyFrozenWeeks {\n    getMyFrozenWeeks {\n      ...FrozenWeek\n    }\n  }\n",
): (typeof documents)["\n  query getMyFrozenWeeks {\n    getMyFrozenWeeks {\n      ...FrozenWeek\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation removeFrozenWeek($week: FrozenWeekInput!) {\n    removeFrozenWeek(week: $week)\n  }\n",
): (typeof documents)["\n  mutation removeFrozenWeek($week: FrozenWeekInput!) {\n    removeFrozenWeek(week: $week)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getMyRemainingLessons {\n    getMyRemainingLessons {\n      remainingLessons\n      totalAvailableLessons\n    }\n  }\n",
): (typeof documents)["\n  query getMyRemainingLessons {\n    getMyRemainingLessons {\n      remainingLessons\n      totalAvailableLessons\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getUserRemainingLessonByWeek(\n    $dateUtc: String!\n    $clientTimezone: String!\n  ) {\n    getUserRemainingLessonByWeek(\n      dateUtc: $dateUtc\n      clientTimezone: $clientTimezone\n    ) {\n      remainingLessonCount\n      totalLessonCount\n    }\n  }\n",
): (typeof documents)["\n  query getUserRemainingLessonByWeek(\n    $dateUtc: String!\n    $clientTimezone: String!\n  ) {\n    getUserRemainingLessonByWeek(\n      dateUtc: $dateUtc\n      clientTimezone: $clientTimezone\n    ) {\n      remainingLessonCount\n      totalLessonCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation deleteMe {\n    deleteMe\n  }\n",
): (typeof documents)["\n  mutation deleteMe {\n    deleteMe\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment UserFragment on User {\n    id\n    email\n    givenName\n    familyName\n    speakingLevel\n    hideBookProgressCheckSection\n    hideBookSpeakingClassSection\n  }\n",
): (typeof documents)["\n  fragment UserFragment on User {\n    id\n    email\n    givenName\n    familyName\n    speakingLevel\n    hideBookProgressCheckSection\n    hideBookSpeakingClassSection\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query me {\n    me {\n      ...UserFragment\n    }\n  }\n",
): (typeof documents)["\n  query me {\n    me {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation sendResetPasswordEmail {\n    sendResetPasswordEmail\n  }\n",
): (typeof documents)["\n  mutation sendResetPasswordEmail {\n    sendResetPasswordEmail\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation updateLastLogin {\n    updateLastLogin\n  }\n",
): (typeof documents)["\n  mutation updateLastLogin {\n    updateLastLogin\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation updateMe($givenName: String, $familyName: String) {\n    updateMe(givenName: $givenName, familyName: $familyName) {\n      ...UserFragment\n    }\n  }\n",
): (typeof documents)["\n  mutation updateMe($givenName: String, $familyName: String) {\n    updateMe(givenName: $givenName, familyName: $familyName) {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getUserClassRoomCount {\n    getUserClassRoomCount {\n      totalClassRoomCount\n    }\n  }\n",
): (typeof documents)["\n  query getUserClassRoomCount {\n    getUserClassRoomCount {\n      totalClassRoomCount\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
