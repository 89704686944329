import { graphql } from "../gql";

export const UserFragment = graphql(`
  fragment UserFragment on User {
    id
    email
    givenName
    familyName
    speakingLevel
    hideBookProgressCheckSection
    hideBookSpeakingClassSection
  }
`);
