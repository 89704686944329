import { useHasUnlockedProgressCheckInfo } from "../../Speaking/PorgressCheck/useHasUnlockedProgressCheckInfo.ts";
import { InformationBox } from "../Information/InformationBox.tsx";
import { useNavigate } from "react-router-dom";
import { BookProgressCheckCalendarScreenPath } from "../../Speaking/PorgressCheck/BookProgressCheckCalendarScreen.tsx";
import { useTranslation } from "react-i18next";

export function HasUnlockedProgressCheckSideMenuInfo() {
  const { hasUnlockedProgressCheck } = useHasUnlockedProgressCheckInfo();

  const navigate = useNavigate();
  const { t } = useTranslation("hasUnlockedProgressCheckSideMenu");
  if (!hasUnlockedProgressCheck) return null;

  return (
    <div className={"max-w-[300px]"}>
      <InformationBox
        title={t("title")}
        description={t("description")}
        cta={{
          title: t("bookNow"),
          onClick: () => navigate(BookProgressCheckCalendarScreenPath),
        }}
      />
    </div>
  );
}
