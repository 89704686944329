import { Layout } from "../../UI/Layout/Layout.tsx";
import { HasSpeakingEnabledGuard } from "../HasSpeakingEnabledGuard.tsx";
import { SomethingWentWrong } from "../SomethingWentWrong.tsx";
import { useState } from "react";
import { Interval } from "../Interval.tsx";
import { createTodayInterval } from "../createTodayInterval.ts";
import { useTranslation } from "react-i18next";
import { WeekRemainingLessons } from "../../User/Plan/WeekRemainingLessons.tsx";
import { HorizontalLineDivider } from "../../UI/HorizontalLineDivider.tsx";
import { WeeklyCalendar } from "../WeeklyCalendar.tsx";
import { EmptyCalendarMessage } from "../EmptyCalendarMessage.tsx";
import { useMyClassRoomBetweenDates } from "./useMyClassRoomBetweenDates.ts";
import { convertClassRoomToCalendarItemMap } from "./convertClassRoomToCalendarItemMap.ts";
import { ClassRoomCalendarCard } from "./ClassRoomCalendarCard.tsx";
import { convertClassRoomFragmentToClassRoomCalendarCardItem } from "./convertClassRoomFragmentToClassRoomCalendarCardItem.tsx";
import { BookmarkIcon } from "@heroicons/react/24/outline/index.js";
import { ScrollToTop } from "../../UI/Layout/ScrollToTop.tsx";
import { useUserClassRoomCount } from "../../User/useUserClassRoomCount.ts";
import { CenteredLoading } from "../../UI/Loading/CenteredLoading.tsx";
import { EmptySectionComponent } from "../EmptySectionComponent.tsx";
import { useNavigate } from "react-router-dom";

import { BookSpeakingLessonCalendarScreenPath } from "../SpeakingLesson/BookSpeakingLessonCalendarScreenPath.tsx";
import { useCurrentUserOrThrow } from "../../Auth/useCurrentUserOrThrow.ts";

export const BookedClassRoomCalendarScreenPath = "/classRooms/plan";

function BookedClassRoomCalendarScreenInner() {
  const [interval, setInterval] = useState<Interval>(createTodayInterval());

  const { classRooms, loading } = useMyClassRoomBetweenDates({
    interval,
  });

  const navigate = useNavigate();
  const { t } = useTranslation("bookedClassRoomCalendarScreen");

  const { totalClassRoomCount, loading: loadingClassRoomCount } =
    useUserClassRoomCount();

  const { user } = useCurrentUserOrThrow();

  if (loadingClassRoomCount) return <CenteredLoading />;

  return (
    <>
      <div className={"flex flex-col flex-1"}>
        <div
          className={
            "hidden md:flex items-center justify-between space-x-2 mb-4"
          }
        >
          <h1 className={"font-bold text-2xl"}>{t("title")}</h1>
          <WeekRemainingLessons interval={interval} />
        </div>
        <div className={"hidden md:block mb-4"}>
          <HorizontalLineDivider />
        </div>
        {totalClassRoomCount > 0 ? (
          <WeeklyCalendar
            canGoToPrevWeek={true}
            emptyDayComponent={
              <EmptyCalendarMessage
                icon={<BookmarkIcon className={"w-5"} />}
                message={t("emptyCalendarDay")}
              />
            }
            interval={interval}
            onChangeInterval={setInterval}
            loading={loading}
            items={convertClassRoomToCalendarItemMap(classRooms)}
            itemRenderer={(classRoom) => (
              <ClassRoomCalendarCard
                classRoom={convertClassRoomFragmentToClassRoomCalendarCardItem(
                  classRoom,
                )}
              />
            )}
          />
        ) : (
          <EmptySectionComponent
            title={t("noClassRoomYet.title")}
            description={t("noClassRoomYet.description")}
            cta={{
              hidden: user.hideBookSpeakingClassSection,
              label: t("noClassRoomYet.goToLessonCalendar"),
              onClick: () => navigate(BookSpeakingLessonCalendarScreenPath),
            }}
          />
        )}
      </div>
    </>
  );
}

export function BookedClassRoomCalendarScreen() {
  return (
    <>
      <ScrollToTop />

      <HasSpeakingEnabledGuard
        ifEnabled={
          <Layout maxWClass={"max-w-full"}>
            <BookedClassRoomCalendarScreenInner />
          </Layout>
        }
        ifDisabled={<SomethingWentWrong />}
      />
    </>
  );
}
