import dayjs, { Dayjs } from "dayjs";
import { classNames } from "../UI/Utils/classNames.ts";
import { useTranslation } from "react-i18next";

export function CalendarDayLabel({
  slotCount,
  day,
}: {
  slotCount: number;
  day: Dayjs;
}) {
  const isToday = day.isSame(dayjs(), "day");
  const { i18n } = useTranslation();

  function getCalendarTitleClass(): string {
    if (isToday) return "bg-primary text-white";
    if (slotCount <= 0)
      return "border border-slate-400 text-black text-slate-400";
    return "border border-slate-400 bg-slate-200 text-black";
  }

  return (
    <div
      key={day.valueOf()}
      className={classNames(
        "px-2 font-bold md:font-normal md:py-6 py-2 items-center justify-center space-x-2 md:space-x-0 rounded-2xl text-center flex md:flex-col",
        getCalendarTitleClass(),
      )}
    >
      <span className={"text-sm md:font-light"}>
        {day.locale(i18n.language).format("dddd")}
      </span>
      <span className={"md:text-3xl md:font-bold"}>
        {day.locale(i18n.language).format("DD")}
      </span>
    </div>
  );
}
