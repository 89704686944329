import { graphql } from "../gql/index.ts";
import { useQuery } from "@apollo/client";

const HasSpeakingEnabledQuery = graphql(`
  query hasSpeakingEnabled {
    hasSpeakingEnabled
  }
`);
export function useHasSpeakingEnabled() {
  const { data, loading } = useQuery(HasSpeakingEnabledQuery);
  return {
    hasSpeakingEnabled: data?.hasSpeakingEnabled ?? false,
    loading,
  };
}
