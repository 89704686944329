import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { I18nLoader } from "./I18n/I18nLoader.tsx";
import App from "./App.tsx";

export function MainComponent() {
  return (
    <React.StrictMode>
      <Auth0Provider
        // cacheLocation={"localstorage"}
        // useRefreshTokens
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        onRedirectCallback={(appState) => {
          window.location.href = appState?.returnTo || window.location.pathname;
        }}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          scope: "email profile admin",
        }}
      >
        <I18nLoader>
          <App />
        </I18nLoader>
      </Auth0Provider>
    </React.StrictMode>
  );
}
