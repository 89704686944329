import { forwardRef, InputHTMLAttributes } from "react";
import { classNames } from "../Utils/classNames.ts";
export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  errorMessage?: string;
};
const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { className, errorMessage, ...otherProps } = props;
  return (
    <div className={"w-full flex flex-col space-y-2"}>
      <input
        className={classNames(
          className,
          "bg-gray-50 border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
          errorMessage ? "border-red-500" : "border-gray-300",
          otherProps.disabled
            ? "bg-slate-100 cursor-not-allowed text-gray-900/50"
            : "bg-white text-gray-900",
        )}
        {...otherProps}
        ref={ref}
      />
      {errorMessage ? <p className={"text-red-500"}>{errorMessage}</p> : null}
    </div>
  );
});
export { TextInput };
