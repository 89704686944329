import { graphql } from "../gql/index.ts";
import { useQuery } from "@apollo/client";

export const GetUserClassRoomCountQuery = graphql(`
  query getUserClassRoomCount {
    getUserClassRoomCount {
      totalClassRoomCount
    }
  }
`);
export function useUserClassRoomCount() {
  const { data, loading } = useQuery(GetUserClassRoomCountQuery);

  return {
    loading,
    totalClassRoomCount: data?.getUserClassRoomCount.totalClassRoomCount ?? 0,
  };
}
