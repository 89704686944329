import { graphql } from "../../../gql/index.ts";
import { useQuery } from "@apollo/client";
import { FrozenWeekInput } from "../../../gql/graphql.ts";

const GetFrozenWeekQuery = graphql(`
  query isFrozenWeek($week: FrozenWeekInput!) {
    isFrozenWeek(week: $week)
  }
`);
export function useIsFrozenWeek(weeK: FrozenWeekInput) {
  const { data, loading } = useQuery(GetFrozenWeekQuery, {
    variables: {
      week: weeK,
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    isFrozenWeek: data?.isFrozenWeek ?? false,
    loading,
  };
}
