import { PropsWithChildren } from "react";
import { useAuthCtx } from "../../Auth/useAuthCtx.ts";
import { Navigate } from "react-router-dom";

type Props = PropsWithChildren;
export function RequireNotActiveUser({ children }: Props) {
  const { isActiveUser } = useAuthCtx();
  if (isActiveUser) return <Navigate to={"/"} />;

  return children ?? null;
}
