import { getFragmentData, graphql } from "../../../gql/index.ts";
import { useQuery } from "@apollo/client";
import { GetFreezableWeekResponseFragment } from "./GetFreezableWeekResponseFragment.ts";

export const GetFreezableWeeksQuery = graphql(`
  query getFreezableWeeks {
    getFreezableWeeks {
      ...GetFreezableWeeksResponse
    }
  }
`);
export function useFreezableWeeks() {
  const { data, loading } = useQuery(GetFreezableWeeksQuery, {
    fetchPolicy: "cache-and-network",
  });

  return {
    freezableWeeks:
      getFragmentData(
        GetFreezableWeekResponseFragment,
        data?.getFreezableWeeks,
      ) ?? undefined,
    loading,
  };
}
