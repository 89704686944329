import { graphql } from "../../../gql/index.ts";

export const GetFreezableWeekResponseFragment = graphql(`
  fragment GetFreezableWeeksResponse on GetFreezableWeeksResponse {
    weeks {
      planId
      week {
        startOfWeekUtc
        endOfWeekUtc
      }
    }
    remainingWeeksToFreeze {
      planId
      remainingCount
    }
  }
`);
