import { PropsWithChildren, ReactNode } from "react";
import { UnCompleteUserNavBar } from "./UnCompleteUserNavBar.tsx";
import { classNames } from "../Utils/classNames.ts";
import { Footer } from "./Footer.tsx";
import { ImpersonationRepository } from "../../Impersonation/ImpersonationRepository.ts";
import { ImpersonationTopNotice } from "../../Impersonation/ImpersonationTopNotice.tsx";
const isImpersonating = ImpersonationRepository.hasUid();
export function LayoutWithOnlyNavbar({
  children,
  maxWClass,
  navBarRight,
  removePadding,
}: PropsWithChildren<{
  maxWClass?: string;
  navBarRight?: ReactNode;
  removePadding?: boolean;
}>) {
  return (
    <div>
      {isImpersonating ? <ImpersonationTopNotice /> : null}
      <div className={"flex min-h-screen"}>
        <div className={"flex-1 flex flex-col"}>
          <UnCompleteUserNavBar navBarRight={navBarRight} />
          <div className={classNames("flex-1 bg-slate-100/50")}>
            <div
              className={classNames(
                "min-h-full flex flex-col max-w-[1024px] mx-auto",
                maxWClass ? maxWClass : "max-w-[1024px]",
                removePadding ? "" : "p-4",
              )}
            >
              {children}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
