import { Menu, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import { classNames } from "../Utils/classNames.ts";

export function DropDown({
  activator,
  children,
  dropDownHeader,
  minWidthFull,
  above,
}: {
  activator: ReactNode;
  dropDownHeader?: ReactNode;
  minWidthFull?: boolean;
  children?: ReactNode | ((renderProps: { close: () => void }) => ReactNode);
  above?: boolean;
}) {
  return (
    <Menu
      as="div"
      className={classNames(
        "relative block text-left",
        minWidthFull ? "w-full" : "",
      )}
    >
      {({ close }) => {
        return (
          <>
            <div className={"w-full h-full"}>
              <Menu.Button className="flex h-full w-full justify-center text-sm">
                {activator}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={classNames(
                  "absolute z-10 right-0 mt-2 origin-top-right divide-y divide-gray-100 overflow-hidden overflow-y-auto max-h-[250px] md:max-h-none rounded-2xl bg-white shadow-lg ring-1 ring-black/5 focus:outline-none",
                  minWidthFull ? "min-w-full" : "",
                  above ? "bottom-[150%]" : "",
                )}
              >
                <div className="">
                  {dropDownHeader ?? null}
                  <div>
                    {typeof children === "function"
                      ? children({
                          close,
                        })
                      : (children ?? null)}
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        );
      }}
    </Menu>
  );
}
