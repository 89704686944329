import { TFunction } from "i18next";
import { ISpeakingLevel } from "./ISpeakingLevel.ts";
import { SpeakingLevel } from "../../gql/graphql.ts";

export class PioneerLevel implements ISpeakingLevel {
  is(level: string): boolean {
    return level === this.getId();
  }
  getId(): SpeakingLevel {
    return SpeakingLevel.Pioneers;
  }
  getBgColorClass(): string {
    return "bg-norma-pioneer";
  }

  getLevelDescription(t: TFunction<"levelDesc">): string {
    return t("pioneers");
  }

  getLevelTitle(t: TFunction<"levelTitle">): string {
    return t("pioneers");
  }

  getTextColorClass(): string {
    return "text-norma-pioneer";
  }

  getTextOnBgColorClass(): string {
    return "text-white";
  }
}
