import { Layout } from "../../../UI/Layout/Layout.tsx";
import { useFreezableWeeks } from "./useFreezableWeeks.ts";
import { Card } from "../../../UI/Card.tsx";
import { CenteredLoading } from "../../../UI/Loading/CenteredLoading.tsx";
import { useMyFrozenWeeks } from "./useMyFrozenWeeks.ts";
import { HorizontalLineDivider } from "../../../UI/HorizontalLineDivider.tsx";
import { InformationBox } from "../../../UI/Information/InformationBox.tsx";
import { FreezeWeekComponent } from "./FreezeWeekComponent.tsx";
import { FrozenWeekItem } from "./FrozenWeekItem.tsx";
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid/index.js";
import { useNavigate } from "react-router-dom";
import { MyProfileScreenPath } from "../../ProfileScreen/MyProfileScreen.tsx";
import { SpinnerContainer } from "../../../UI/Loading/SpinnerContainer.tsx";
import { useTranslation } from "react-i18next";
import { SomethingWentWrong } from "../../../Speaking/SomethingWentWrong.tsx";

export const StudyPlanScreenPath = "/user/plan/manage";

export function StudyPlanScreen() {
  const { freezableWeeks, loading: loadingFreezableWeeks } =
    useFreezableWeeks();
  const { frozenWeeks, loading: loadingFrozenWeeks } = useMyFrozenWeeks();

  const loading = loadingFreezableWeeks || loadingFrozenWeeks;

  const navigate = useNavigate();

  const { t } = useTranslation("lessonPlanManagement");

  if (loading && !freezableWeeks)
    return (
      <Layout maxWClass={"max-w-full"}>
        <CenteredLoading />
      </Layout>
    );

  if (!freezableWeeks) return <SomethingWentWrong />;

  return (
    <Layout maxWClass={"max-w-full"}>
      <div className={"flex flex-col"}>
        <div
          onClick={() => navigate(MyProfileScreenPath)}
          className={
            "text-md flex items-center space-x-2 mb-4 text-slate-600 cursor-pointer"
          }
        >
          <ArrowLongLeftIcon className={"w-5"} />
          <p>{t("backToProfile")}</p>
        </div>
        <Card>
          <SpinnerContainer loading={loading}>
            <div className={"space-y-6"}>
              <FreezeWeekComponent freezableWeeks={freezableWeeks} />

              <HorizontalLineDivider />
              <h3 className={"font-bold"}>{t("frozenWeeks.title")}</h3>
              {frozenWeeks.length > 0 ? (
                <div className={"space-y-4"}>
                  {frozenWeeks.map((fw, i) => {
                    return <FrozenWeekItem key={i} week={fw} />;
                  })}
                </div>
              ) : (
                <InformationBox
                  title={t("frozenWeeks.noSuspensionTitle")}
                  description={t("frozenWeeks.noSuspensionDescription")}
                />
              )}
            </div>
          </SpinnerContainer>
        </Card>
      </div>
    </Layout>
  );
}
