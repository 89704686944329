import { useTranslation } from "react-i18next";
import { MyProfileCard } from "../MyProfileCard.tsx";
import { DangerBox } from "../../../UI/Information/DangerBox.tsx";
import { DangerButton } from "../../../UI/Button/DangerButton.tsx";
import { XCircleIcon } from "@heroicons/react/24/solid/index.js";
import { useDeleteAccount } from "./useDeleteAccount.ts";
import { useAuthCtx } from "../../../Auth/useAuthCtx.ts";
import { useWithLoading } from "../../../UI/Loading/useWithLoading.ts";
import { useCallback } from "react";

export function DeleteAccountCard() {
  const { t } = useTranslation("deleteAccount");
  const { signOut } = useAuthCtx();
  const { deleteAccount } = useDeleteAccount();
  const { withLoading, loading } = useWithLoading();

  const deleteAndLogout = useCallback(async () => {
    await deleteAccount();
    await signOut();
  }, [deleteAccount, signOut]);
  async function requestConfirmationForDeletingAccount() {
    if (window.confirm(t("ctaConfirm"))) {
      await withLoading(deleteAndLogout);
    }
  }

  return (
    <MyProfileCard>
      <h2 className={"font-semibold text-lg"}>{t("title")}</h2>
      <DangerBox title={t("warningTitle")} description={t("warningText")} />
      <DangerButton
        iconLeft={<XCircleIcon className={"w-5 h-5"} />}
        label={t("ctaText")}
        loading={loading}
        onClick={requestConfirmationForDeletingAccount}
      />
    </MyProfileCard>
  );
}
