import { getFragmentData, graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";
import { BookableSlotFragment } from "../BookableSlotFragment.ts";
import { Interval } from "../Interval.tsx";
import dayjs from "dayjs";
import { Writeable } from "../../DataUtils/Writable.ts";
import { BookableSlotFragmentFragment } from "../../gql/graphql.ts";

export const GetSpeakingClassAvailabilityQuery = graphql(`
  query getAvailabilityForSpeakingClass(
    $clientTimezone: String!
    $fromDate: String!
    $toDate: String!
  ) {
    getAvailabilityForSpeakingClass(
      clientTimezone: $clientTimezone
      fromDate: $fromDate
      toDate: $toDate
    ) {
      slots {
        ...BookableSlotFragment
      }
    }
  }
`);
export function useSpeakingClassAvailability({
  interval,
}: {
  interval: Interval;
}) {
  const dateFormat = "YYYY-MM-DD";
  const { data, loading } = useQuery(GetSpeakingClassAvailabilityQuery, {
    variables: {
      fromDate: interval.fromDate.format(dateFormat),
      toDate: interval.toDate.format(dateFormat),
      clientTimezone: dayjs.tz.guess(),
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    bookableSlots: (getFragmentData(
      BookableSlotFragment,
      data?.getAvailabilityForSpeakingClass?.slots,
    ) ?? []) as Writeable<BookableSlotFragmentFragment[]>,
    loading,
  };
}
