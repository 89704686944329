import { PrimaryLoadingIndicator } from "./PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "./LoaderSize.tsx";

export function CenteredLoading() {
  return (
    <div className={"flex justify-center items-center flex-1"}>
      <PrimaryLoadingIndicator size={LoaderSize.Large} />
    </div>
  );
}
