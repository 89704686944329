import { useAuth0 } from "@auth0/auth0-react";
import { AuthCtx } from "./AuthCtx.ts";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMe } from "../User/useMe.ts";
import { FullPageLoading } from "../UI/Loading/FullPageLoading.tsx";
import { useIsActiveUser } from "../User/NotActive/useIsActiveUser.ts";
import { useUpdateLastLogin } from "../User/useUpdateLastLogin.ts";
import * as Sentry from "@sentry/browser";
type Props = PropsWithChildren;

export function AuthCtxProvider({ children }: Props) {
  const [loading, setLoading] = useState(true);
  const { user: authUser, isLoading, logout } = useAuth0();
  const { user, loading: loadingUser, refetch: refetchMe } = useMe(!authUser);
  const { isActive, loading: loadingIsActive } = useIsActiveUser(!authUser);

  const updatedLastLoginRef = useRef(false);
  const userId = user?.id;
  const { updateLastLogin } = useUpdateLastLogin();

  useEffect(() => {
    (async function triggerLastLoginUpdate() {
      if (!userId) return;
      if (updatedLastLoginRef.current) return;
      await updateLastLogin();
      updatedLastLoginRef.current = true;
    })();
  }, [updateLastLogin, userId]);

  useEffect(() => {
    if (!user) Sentry.setUser(null);
    else
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: `${user.givenName} ${user.familyName}`,
      });
  }, [user]);

  const refetch = useCallback(async () => {
    await refetchMe();
  }, [refetchMe]);

  useEffect(() => {
    if (isLoading) return;
    if (!authUser) {
      setLoading(false);
      return;
    }
    if (loadingUser) return;
    if (!user) {
      setLoading(false);
      return;
    }
    if (loadingIsActive) return;
    setLoading(false);
  }, [authUser, isLoading, loadingIsActive, loadingUser, user]);

  const signOut = useCallback(async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  if (loading) return <FullPageLoading />;

  return (
    <AuthCtx.Provider
      value={{
        authUser,
        signOut,
        user: user ?? undefined,
        isActiveUser: isActive,
        refetch,
      }}
    >
      {children ?? null}
    </AuthCtx.Provider>
  );
}
