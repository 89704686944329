import { LayoutWithOnlyNavbar } from "../UI/Layout/LayoutWithOnlyNavbar.tsx";
import { useTranslation } from "react-i18next";
import { StarIcon } from "@heroicons/react/24/outline/index.js";
import { NavBarDropDownWithOnlyLogout } from "../UI/Layout/NavBarDropDown/NavBarDropDownWithOnlyLogout.tsx";
import { MissingTestComponent } from "../UI/MissingTest/MissingTestComponent.tsx";
import { IntroductionVideo } from "./IntroductionVideo.tsx";
import { useCurrentUserOrThrow } from "../Auth/useCurrentUserOrThrow.ts";

export const MissingSpeakingTestScreenPath = "/missing-speaking-test";
export function MissingSpeakingTestScreen() {
  const { t } = useTranslation("missingGrammarTestScreen");
  const { user } = useCurrentUserOrThrow();

  function getSelfAssessmentUrl(): string {
    const url = new URL(import.meta.env.VITE_SELF_ASSESSMENT_URL);
    url.searchParams.set("email", user.email);
    return url.toString();
  }

  function goToTest() {
    window.location.href = getSelfAssessmentUrl();
  }
  return (
    <LayoutWithOnlyNavbar
      navBarRight={<NavBarDropDownWithOnlyLogout />}
      maxWClass={"max-w-full"}
      removePadding
    >
      <MissingTestComponent
        topRightComponent={
          <IntroductionVideo
            videoUrl={"https://player.vimeo.com/video/946650134"}
          />
        }
        cta={{
          label: t("goToTest"),
          action: goToTest,
        }}
        title={t("title")}
        description={t("description")}
        perks={[
          {
            title: t("perks.perk1.title"),
            description: t("perks.perk1.description"),
            icon: <StarIcon className={"w-6"} />,
          },
          {
            title: t("perks.perk2.title"),
            description: t("perks.perk2.description"),
            icon: <StarIcon className={"w-6"} />,
          },
          {
            title: t("perks.perk3.title"),
            description: t("perks.perk3.description"),
            icon: <StarIcon className={"w-6"} />,
          },
        ]}
      />
    </LayoutWithOnlyNavbar>
  );
}
