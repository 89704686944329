import { ReactNode } from "react";

export function PerkBox({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: ReactNode;
}) {
  return (
    <div
      className={
        "flex md:flex-row md:space-y-0 space-y-3 flex-col md:space-x-3 max-w-[350px]"
      }
    >
      <div className={"mx-auto md:mx-0"}>
        <div className={"p-2.5 bg-blue-200 text-blue-900 rounded-xl"}>
          {icon}
        </div>
      </div>
      <div className={"flex-1 flex flex-col space-y-2"}>
        <h3 className={"font-semibold text-center md:text-left"}>{title}</h3>
        <p className={"font-light text-center md:text-left text-slate-500"}>
          {description}
        </p>
      </div>
    </div>
  );
}
