const ImpersonationLocalStorageKey = "impersonation-uid";
export const ImpersonationRepository = {
  setImpersonationUid: (uid: string) => {
    localStorage.setItem(ImpersonationLocalStorageKey, uid);
  },
  getImpersonationUid: () => {
    return localStorage.getItem(ImpersonationLocalStorageKey) ?? "";
  },
  clearImpersonationUid: () => {
    localStorage.removeItem(ImpersonationLocalStorageKey);
  },
  hasUid: () => {
    return !!localStorage.getItem(ImpersonationLocalStorageKey);
  },
};
