import { useCallback, useEffect, useRef, useState } from "react";
import { MessagePopupControlBag } from "./MessagePopupControlBag.ts";

export function useMessagePopupController({
  timeoutInMs,
  onClose,
}: {
  timeoutInMs: number;
  onClose?: () => void;
}) {
  const [controlBag, setControlBag] = useState<MessagePopupControlBag>();
  const show = controlBag?.show ?? false;
  const timeoutRef = useRef<NodeJS.Timeout>();
  const closePopup = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setControlBag(undefined);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (show) {
      timeoutRef.current = setTimeout(() => {
        closePopup();
      }, timeoutInMs);
    }
  }, [closePopup, show, timeoutInMs]);

  const showPopup = useCallback((title: string, description: string) => {
    setControlBag({ title, description, show: true });
  }, []);

  return {
    show: show,
    title: controlBag?.title ?? "",
    description: controlBag?.description ?? "",
    showPopup,
    closePopup,
  };
}
