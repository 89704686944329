import { Layout } from "../../UI/Layout/Layout.tsx";
import { Tabs } from "../../UI/Tab/Tabs.tsx";
import {
  ShieldCheckIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid/index.js";
import { useTranslation } from "react-i18next";
import { useMyProfileSelectedTab } from "../UseMyProfileSelectedTab.tsx";
import { MyProfileTab } from "./MyProfileTab.tsx";
import { ScrollToTop } from "../../UI/Layout/ScrollToTop.tsx";
import { MyProfileMyAccountTab } from "./MyProfileMyAccountTab.tsx";
import { MyProfileEnglishLevelTab } from "./MyProfileEnglishLevelTab.tsx";

export const MyProfileScreenPath = "/user/me";

export function MyProfileScreen() {
  const { t } = useTranslation("myProfileScreen");
  const { selectedTab, setSelectedTab } = useMyProfileSelectedTab();
  return (
    <>
      <ScrollToTop />
      <Layout maxWClass={"w-full"}>
        <div className={"flex flex-col"}>
          <div
            className={
              "hidden md:flex items-center justify-between space-x-2 mb-4"
            }
          >
            <h1 className={"font-bold text-2xl"}>{t("title")}</h1>
          </div>
          <Tabs
            onChangeTab={(tab) => setSelectedTab(tab.id as MyProfileTab)}
            initialSelectedTabId={selectedTab}
            tabs={[
              {
                element: <MyProfileMyAccountTab />,
                label: (
                  <p className={"flex items-center space-x-2"}>
                    <UserCircleIcon className={"h-5 w-5"} />
                    <span>{t("myAccount")}</span>
                  </p>
                ),
                id: "my-account",
              },
              {
                element: <MyProfileEnglishLevelTab />,
                label: (
                  <p className={"flex items-center space-x-2"}>
                    <ShieldCheckIcon className={"h-5 w-5"} />
                    <span>{t("myEnglishLevel")}</span>
                  </p>
                ),
                id: "english-level",
              },
            ]}
          />
        </div>
      </Layout>
    </>
  );
}
