import { graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";

const GetUnlockedProgressCheckInfoQuery = graphql(`
  query hasUnlockedProgressCheck {
    hasUnlockedProgressCheck {
      lessonCompletedToUnlockProgressCheck
      totalLessonNeededToUnlockProgressCheck
      hasUnlockedProgressCheck
    }
  }
`);
export function useHasUnlockedProgressCheckInfo() {
  const { data, loading } = useQuery(GetUnlockedProgressCheckInfoQuery);

  return {
    hasUnlockedProgressCheck:
      data?.hasUnlockedProgressCheck?.hasUnlockedProgressCheck ?? false,
    totalLessonNeededToUnlockProgressCheck:
      data?.hasUnlockedProgressCheck?.totalLessonNeededToUnlockProgressCheck ??
      0,
    lessonCompletedToUnlockProgressCheck:
      data?.hasUnlockedProgressCheck?.lessonCompletedToUnlockProgressCheck ?? 0,
    loading,
  };
}
