import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col } from "../UI/Layout/Col.tsx";
import { Row } from "../UI/Layout/Row.tsx";
import { InputWithLabel } from "../UI/Input/InputWithLabel.tsx";
import { TextInput } from "../UI/Input/TextInput.tsx";
import { PrimaryButton } from "../UI/Button/PrimaryButton.tsx";
import { useWithLoading } from "../UI/Loading/useWithLoading.ts";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/24/solid/index.js";

export type CompleteUserFormInputs = {
  givenName: string;
  familyName: string;
};

export function CompleteUserForm({
  defaultValues,
  onSave,
}: {
  defaultValues?: Partial<CompleteUserFormInputs>;
  onSave: (data: CompleteUserFormInputs) => Promise<void>;
}) {
  const { t } = useTranslation("completeUserForm");
  const schema = yup
    .object({
      givenName: yup.string().required(t("insertAName")),
      familyName: yup.string().required(t("insertASurname")),
    })
    .required();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CompleteUserFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...(defaultValues ?? {}),
    },
  });
  const { withLoading, loading } = useWithLoading();
  const onSubmit: SubmitHandler<CompleteUserFormInputs> = async (data) => {
    await withLoading(async () => onSave(data));
  };
  return (
    <form>
      <Col>
        <Row>
          <InputWithLabel label={t("givenName")}>
            <TextInput
              {...register("givenName")}
              errorMessage={errors.givenName?.message}
            />
          </InputWithLabel>
          <InputWithLabel label={t("familyName")}>
            <TextInput
              {...register("familyName")}
              errorMessage={errors.familyName?.message}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <div>
            <PrimaryButton
              iconLeft={<CheckCircleIcon className={"w-5"} />}
              loading={loading}
              label={t("save")}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </Row>
      </Col>
    </form>
  );
}
