import { UserFragmentFragment } from "../gql/graphql.ts";
import { StepProgress } from "../UI/Progress/StepProgress.tsx";
import { useTranslation } from "react-i18next";
import { SpeakingLevelRepository } from "./SpeakingLevel/SpeakingLevelRepository.ts";

export function SpeakingLevelProgress(props: { user: UserFragmentFragment }) {
  const { t } = useTranslation("levelTitle");
  const levels = SpeakingLevelRepository.getAllLevels();
  return (
    <StepProgress
      current={props.user?.speakingLevel}
      steps={levels.map((l) => ({
        name: l.getLevelTitle(t),
        colorClass: l.getBgColorClass(),
        textColorClass: l.getTextOnBgColorClass(),
        iconColor: l.getTextColorClass(),
        id: l.getId(),
      }))}
    />
  );
}
