import { PropsWithChildren } from "react";
import { useAuthCtx } from "./useAuthCtx.ts";
import { Navigate } from "react-router-dom";
import { LoginScreenPath } from "./LoginScreen.ts";
import { useHandleRedirectToOldUrl } from "./useHandleRedirectToOldUrl.ts";
import { MissingAccountScreenPath } from "./MissingAccountScreen.tsx";

type Props = PropsWithChildren;
export function RequireAuth({ children }: Props) {
  const { authUser, user } = useAuthCtx();
  const { getUrlWithRedirect } = useHandleRedirectToOldUrl();
  if (!authUser) {
    const redirectTo = getUrlWithRedirect(
      new URL(LoginScreenPath, window.location.origin),
    );
    return (
      <Navigate
        to={`${LoginScreenPath}?${redirectTo.searchParams.toString()}`}
        replace
      />
    );
  }

  if (!user) return <Navigate to={MissingAccountScreenPath} replace />;

  return children ?? null;
}
