import { useAuthCtx } from "../../../Auth/useAuthCtx.ts";
import { DropDown } from "../../DropDown/DropDown.tsx";
import { DropDownItem } from "../../DropDown/DropDownItem.tsx";
import {
  ArrowLeftStartOnRectangleIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline/index.js";
import { useTranslation } from "react-i18next";
import {
  ShieldCheckIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid/index.js";
import { HorizontalLineDivider } from "../../HorizontalLineDivider.tsx";
import { useNavigate } from "react-router-dom";
import { MyProfileScreenPath } from "../../../User/ProfileScreen/MyProfileScreen.tsx";
import { useMyProfileSelectedTab } from "../../../User/UseMyProfileSelectedTab.tsx";
import { MyProfileTab } from "../../../User/ProfileScreen/MyProfileTab.tsx";
import { UserLetterProfileImage } from "../../../User/UserLetterProfileImage.tsx";
import { useCurrentUserOrThrow } from "../../../Auth/useCurrentUserOrThrow.ts";
import { classNames } from "../../Utils/classNames.ts";
import { SpeakingLevelRepository } from "../../../User/SpeakingLevel/SpeakingLevelRepository.ts";

export function NavBarDropDown() {
  const { signOut } = useAuthCtx();
  const { user } = useCurrentUserOrThrow();
  const { t } = useTranslation("navBarDropDown");
  const { t: tLevel } = useTranslation("levelTitle");
  const navigate = useNavigate();

  const { getSelectedTabUrl } = useMyProfileSelectedTab();
  function getFullName(): string {
    const parts: string[] = [];
    if (user?.givenName) parts.push(user.givenName);
    if (user?.familyName) parts.push(user.familyName);
    return parts.join(" ");
  }

  const fullName = getFullName();

  function getSpeakingLevelColorClass() {
    return SpeakingLevelRepository.get(user.speakingLevel).getTextColorClass();
  }

  return (
    <DropDown
      dropDownHeader={
        <div className={"flex flex-col p-3 text-sm"}>
          <h6 className={"font-bold"}>{fullName}</h6>
          <p>{user.email ?? ""}</p>
        </div>
      }
      activator={
        <div className={"flex items-center space-x-2 whitespace-nowrap"}>
          <UserLetterProfileImage
            familyName={user.familyName}
            givenName={user.givenName}
          />
          <div className={"items-center space-x-2 md:flex hidden"}>
            <span>{fullName}</span>
            <span>|</span>
            <span
              className={classNames(
                "flex items-center space-x-1",
                getSpeakingLevelColorClass(),
              )}
            >
              <ShieldCheckIcon className={"w-5"} />
              <span>{tLevel(user.speakingLevel.toLowerCase() ?? "")}</span>
            </span>
          </div>
          <div className={"block md:hidden"}>
            <Bars3Icon className={"w-5"} />
          </div>
        </div>
      }
    >
      <HorizontalLineDivider />
      <DropDownItem
        onClick={() => {
          const url = getSelectedTabUrl(
            new URL(MyProfileScreenPath, window.location.origin),
            MyProfileTab.MyAccount,
          );
          navigate(`${MyProfileScreenPath}?${url.searchParams.toString()}`);
        }}
      >
        <div className={"p-3 flex items-center"}>
          <UserCircleIcon className={"h-5 w-5 mr-2"} />
          {t("myProfile")}
        </div>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          const url = getSelectedTabUrl(
            new URL(MyProfileScreenPath, window.location.origin),
            MyProfileTab.EnglishLevel,
          );
          navigate(`${MyProfileScreenPath}?${url.searchParams.toString()}`);
        }}
      >
        <div className={"p-3 flex items-center"}>
          <ShieldCheckIcon className={"h-5 w-5 mr-2"} />
          {t("englishLevel")}
        </div>
      </DropDownItem>
      <HorizontalLineDivider />
      <DropDownItem onClick={signOut}>
        <div className={"p-3 flex items-center"}>
          <ArrowLeftStartOnRectangleIcon className={"h-5 w-5 mr-2"} />
          {t("logout")}
        </div>
      </DropDownItem>
    </DropDown>
  );
}
