import { NotFoundComponent } from "./NotFoundComponent.tsx";
import { Layout } from "../Layout/Layout.tsx";

export function NotFoundLoggedInScreen() {
  return (
    <Layout>
      <div className={"flex flex-1 items-center justify-center"}>
        <NotFoundComponent />
      </div>
    </Layout>
  );
}
