import { DropDown } from "../../DropDown/DropDown.tsx";
import { UserLetterProfileImage } from "../../../User/UserLetterProfileImage.tsx";
import {
  ArrowLeftStartOnRectangleIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline/index.js";
import { DropDownItem } from "../../DropDown/DropDownItem.tsx";
import { useTranslation } from "react-i18next";
import { useAuthCtx } from "../../../Auth/useAuthCtx.ts";
import { useCurrentUserOrThrow } from "../../../Auth/useCurrentUserOrThrow.ts";
import { HorizontalLineDivider } from "../../HorizontalLineDivider.tsx";

export function NavBarDropDownWithOnlyLogout() {
  const { t } = useTranslation("navBarDropDown");
  const { signOut } = useAuthCtx();
  const { user } = useCurrentUserOrThrow();
  function getFullName(): string {
    const parts: string[] = [];
    if (user?.givenName) parts.push(user.givenName);
    if (user?.familyName) parts.push(user.familyName);
    return parts.join(" ");
  }

  const fullName = getFullName();

  return (
    <DropDown
      dropDownHeader={
        <div className={"flex flex-col p-3 text-sm"}>
          <h6 className={"font-bold"}>{fullName}</h6>
          <p>{user.email ?? ""}</p>
        </div>
      }
      activator={
        <div className={"flex items-center space-x-2 whitespace-nowrap"}>
          <UserLetterProfileImage
            familyName={user.familyName}
            givenName={user.givenName}
          />
          <div className={"items-center space-x-2 md:flex hidden"}>
            <span>{fullName}</span>
          </div>
          <div className={"block md:hidden"}>
            <Bars3Icon className={"w-5"} />
          </div>
        </div>
      }
    >
      <HorizontalLineDivider />
      <DropDownItem onClick={signOut}>
        <div className={"p-3 flex items-center"}>
          <ArrowLeftStartOnRectangleIcon className={"h-5 w-5 mr-2"} />
          {t("logout")}
        </div>
      </DropDownItem>
    </DropDown>
  );
}
