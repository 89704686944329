export function IntroductionVideo({ videoUrl }: { videoUrl: string }) {
  return (
    <div className={"md:max-w-none max-w-[400px] mx-auto"}>
      <div className={"pt-[56.25%] relative rounded-xl overflow-hidden"}>
        <iframe
          className={"absolute inset-0 w-full h-full"}
          src={videoUrl}
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="Fluente per Sempre"
        />
      </div>
    </div>
  );
}
