import { PropsWithChildren } from "react";
import { Menu } from "@headlessui/react";

type Props = PropsWithChildren<{
  onClick: () => void;
}>;
export function DropDownItem({ children, onClick }: Props) {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={() => {
            onClick();
          }}
          className={`${
            active ? "bg-primary text-white" : ""
          } group flex w-full items-center text-sm`}
        >
          {children ?? null}
        </button>
      )}
    </Menu.Item>
  );
}
