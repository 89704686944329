import { graphql } from "../../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { BookSlotInput } from "../../gql/graphql.ts";
import { GetProgressCheckAvailabilityQuery } from "./useProgressCheckAvailability.ts";
import { GetRemainingLessonPerWeek } from "../../User/Plan/useRemainingLessonPerWeek.ts";
import { GetMyRemainingLessons } from "../../User/Plan/useMyRemainingLessons.ts";
import { GetUserClassRoomsBetweenDates } from "../BookedClassRoom/useMyClassRoomBetweenDates.ts";
import { GetUserClassRoomCountQuery } from "../../User/useUserClassRoomCount.ts";

const BookProgressCheckMutation = graphql(`
  mutation bookProgressCheck($bookSlotInput: BookSlotInput!) {
    bookProgressCheck(bookSlotInput: $bookSlotInput)
  }
`);
export function useBookProgressCheck() {
  const [bookProgressCheckMutation, { loading }] = useMutation(
    BookProgressCheckMutation,
    {
      refetchQueries: [
        GetProgressCheckAvailabilityQuery,
        GetRemainingLessonPerWeek,
        GetMyRemainingLessons,
        GetUserClassRoomsBetweenDates,
        GetUserClassRoomCountQuery,
      ],
      awaitRefetchQueries: true,
    },
  );

  const bookProgressCheck = useCallback(
    async (input: BookSlotInput) => {
      await bookProgressCheckMutation({
        variables: {
          bookSlotInput: input,
        },
      });
    },
    [bookProgressCheckMutation],
  );
  return {
    bookProgressCheck,
    loading,
  };
}
