import { classNames } from "../Utils/classNames.ts";

export function Badge({
  title,
  colorClass,
}: {
  title: string;
  colorClass: string;
}) {
  return (
    <div
      className={classNames("px-2 py-1 rounded-lg mr-auto text-xs", colorClass)}
    >
      <h6 className={"font-semibold"}>{title}</h6>
    </div>
  );
}
