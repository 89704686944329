import { graphql } from "../../../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const DeleteAccountMutation = graphql(`
  mutation deleteMe {
    deleteMe
  }
`);
export function useDeleteAccount() {
  const [deleteAccountMutation, { loading }] = useMutation(
    DeleteAccountMutation,
  );

  const deleteAccount = useCallback(async () => {
    await deleteAccountMutation();
  }, [deleteAccountMutation]);

  return {
    deleteAccount,
    loading,
  };
}
