import { getFragmentData, graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";
import { Interval } from "../Interval.tsx";
import dayjs from "dayjs";
import { BookableSlotFragment } from "../BookableSlotFragment.ts";
import { Writeable } from "../../DataUtils/Writable.ts";
import { BookableSlotFragmentFragment } from "../../gql/graphql.ts";

export const GetProgressCheckAvailabilityQuery = graphql(`
  query getAvailabilityForProgressCheck(
    $clientTimezone: String!
    $fromDate: String!
    $toDate: String!
  ) {
    getAvailabilityForProgressCheck(
      clientTimezone: $clientTimezone
      fromDate: $fromDate
      toDate: $toDate
    ) {
      slots {
        ...BookableSlotFragment
      }
      hasAtLeastOnProgressCheckBookable
    }
  }
`);
export function useProgressCheckAvailability({
  interval,
}: {
  interval: Interval;
}) {
  const dateFormat = "YYYY-MM-DD";
  const { data, loading } = useQuery(GetProgressCheckAvailabilityQuery, {
    variables: {
      fromDate: interval.fromDate.format(dateFormat),
      toDate: interval.toDate.format(dateFormat),
      clientTimezone: dayjs.tz.guess(),
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    bookableSlots:
      (getFragmentData(
        BookableSlotFragment,
        data?.getAvailabilityForProgressCheck?.slots,
      ) as Writeable<BookableSlotFragmentFragment[]>) ?? [],
    hasAtLeastOnProgressCheckBookable:
      data?.getAvailabilityForProgressCheck
        ?.hasAtLeastOnProgressCheckBookable ?? false,
    loading,
  };
}
