import { LayoutWithOnlyNavbar } from "../UI/Layout/LayoutWithOnlyNavbar.tsx";
import {
  CompleteUserForm,
  CompleteUserFormInputs,
} from "../User/CompleteUserForm.tsx";
import { useAuthCtx } from "./useAuthCtx.ts";
import { useUpdateMe } from "../User/useUpdateMe.ts";
import { useTranslation } from "react-i18next";
import { Card } from "../UI/Card.tsx";
import { BookSpeakingLessonCalendarScreenPath } from "../Speaking/SpeakingLesson/BookSpeakingLessonCalendarScreenPath.tsx";

export const CompleteUserDataScreenPath = "/complete-user-data";
export function CompleteUserDataScreen() {
  const { authUser, user } = useAuthCtx();
  const { updateMe } = useUpdateMe();
  const { t } = useTranslation("completeUserDataScreen");
  function computeDefaultValues(): CompleteUserFormInputs {
    return {
      givenName: user?.givenName ?? authUser?.givenName ?? "",
      familyName: user?.familyName ?? authUser?.familyName ?? "",
    };
  }

  async function saveUser({ givenName, familyName }: CompleteUserFormInputs) {
    await updateMe({
      givenName,
      familyName,
    });
    window.location.href = BookSpeakingLessonCalendarScreenPath;
  }

  return (
    <LayoutWithOnlyNavbar>
      <div className={"flex-1 justify-center flex flex-col"}>
        <Card>
          <div className={"space-y-1"}>
            <h1 className={"font-bold text-xl"}>{t("title")}</h1>
            <p>{t("completeData")}</p>
          </div>
          <CompleteUserForm
            onSave={saveUser}
            defaultValues={computeDefaultValues()}
          />
        </Card>
      </div>
    </LayoutWithOnlyNavbar>
  );
}
