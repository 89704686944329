import { FrozenWeekFragment } from "../../../gql/graphql.ts";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useUnfreezeWeek } from "./useUnfreezeWeek.ts";
import { PrimaryLoadingIndicator } from "../../../UI/Loading/PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "../../../UI/Loading/LoaderSize.tsx";
import { XCircleIcon } from "@heroicons/react/24/outline/index.js";
import { ErrorMessagePopup } from "../../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../../../UI/MessagePopup/useMessagePopupController.ts";

export function FrozenWeekItem({ week }: { week: FrozenWeekFragment }) {
  const parseDateFormat = "YYYY-MM-DD";
  const { i18n, t } = useTranslation("lessonPlanManagement");

  const { loading, unFreezeWeek } = useUnfreezeWeek();

  const { title, description, show, showPopup, closePopup } =
    useMessagePopupController({
      timeoutInMs: 5 * 1000,
    });
  function formatDate(dateString: string): string {
    return dayjs(dateString, parseDateFormat)
      .local()
      .locale(i18n.language)
      .format("ddd DD MMM YYYY");
  }

  async function unfreeze() {
    try {
      await unFreezeWeek({
        startOfWeekDateString: week.startOfWeekUtc,
        endOfWeekDateString: week.endOfWeekUtc,
      });
    } catch (e) {
      showPopup(
        t("frozenWeeks.item.unfreezeError.title"),
        t("frozenWeeks.item.unfreezeError.description"),
      );
    }
  }

  function startInFuture() {
    return dayjs(week.startOfWeekUtc, parseDateFormat).isAfter(dayjs());
  }

  return (
    <>
      <ErrorMessagePopup
        title={title}
        description={description}
        show={show}
        onClose={closePopup}
      />
      <div
        className={
          "border rounded-lg p-3 border-slate-100 flex justify-between space-x-2 items-center"
        }
      >
        <p className={""}>
          {t("frozenWeeks.item.fromTo", {
            from: formatDate(week.startOfWeekUtc),
            to: formatDate(week.endOfWeekUtc),
          })}
        </p>
        {startInFuture() ? (
          <div>
            {loading ? (
              <PrimaryLoadingIndicator size={LoaderSize.Small} />
            ) : (
              <XCircleIcon
                onClick={unfreeze}
                className={"w-7 text-red-400 cursor-pointer"}
              />
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}
