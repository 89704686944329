import { DropDown } from "../DropDown/DropDown.tsx";
import { DropDownItem } from "../DropDown/DropDownItem.tsx";
import { useTranslation } from "react-i18next";
import { getRoutes } from "./getRoutes.tsx";
import {
  ArrowsPointingOutIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid/index.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useCurrentUserOrThrow } from "../../Auth/useCurrentUserOrThrow.ts";

export function MobileSubNavBar() {
  const { t } = useTranslation("sideMenu");
  const location = useLocation();
  const activeRoute = location.pathname;
  const { user } = useCurrentUserOrThrow();
  const routes = getRoutes(t, {
    hideBookProgressCheck: user.hideBookProgressCheckSection,
    hideBookSpeakingClass: user.hideBookSpeakingClassSection,
  });
  const activeRouteItem = routes.find((r) => r.to === activeRoute);
  const navigate = useNavigate();
  return (
    <div className={"w-full p-3"}>
      <DropDown
        minWidthFull
        activator={
          <div
            className={
              "px-4 cursor-pointer py-2 shadow-slate-200/40 rounded-lg w-full text-center flex items-center space-x-2 bg-slate-200 hover:brightness-90"
            }
          >
            <div
              className={
                "flex items-center justify-between space-x-2 w-full xs:text-base text-xs"
              }
            >
              {activeRouteItem ? (
                <p className={"flex items-center space-x-2"}>
                  {activeRouteItem.icon}
                  <span>{activeRouteItem.label}</span>
                </p>
              ) : (
                <p className={"flex items-center space-x-2"}>
                  <ArrowsPointingOutIcon className={"w-5"} />
                  <span>{t("menu")}</span>
                </p>
              )}
              <ChevronDownIcon className={"h-5 w-5"} />
            </div>
          </div>
        }
      >
        <div className={"bg-white p-3"}>
          {routes.map((r) => (
            <DropDownItem
              key={r.to}
              onClick={() => {
                if (r.externalUrl) window.location.href = r.to;
                else navigate(r.to);
              }}
            >
              <div className={"flex items-center space-x-2 p-2"}>
                {r.icon}
                <span>{r.label}</span>
              </div>
            </DropDownItem>
          ))}
        </div>
      </DropDown>
    </div>
  );
}
