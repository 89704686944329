import { WhiteLoadingIndicator } from "../Loading/WhiteLoadingIndicator.tsx";
import { ReactNode } from "react";
import { classNames } from "../Utils/classNames.ts";

type Props = {
  label: string;
  onClick: () => void;
  loading?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  disabled?: boolean;
};
export function DangerButton({
  label,
  onClick,
  loading,
  iconLeft,
  iconRight,
  disabled,
}: Props) {
  return (
    <button
      onClick={
        disabled
          ? undefined
          : (e) => {
              e.preventDefault();
              onClick();
            }
      }
      className={classNames(
        "flex w-full justify-center items-center space-x-2 text-white font-medium rounded-lg text-sm px-5 py-2.5",
        disabled
          ? "bg-slate-200 text-slate-600 cursor-not-allowed"
          : "cursor-pointer hover:brightness-125 focus:ring-4 ring-red-600/20 bg-red-600 ring-offset-1",
      )}
    >
      {iconLeft ?? null}
      <span>{label}</span>
      {iconRight ?? null}
      {loading ? <WhiteLoadingIndicator /> : null}
    </button>
  );
}
