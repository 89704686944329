import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const UpdateMeMutation = graphql(`
  mutation updateMe($givenName: String, $familyName: String) {
    updateMe(givenName: $givenName, familyName: $familyName) {
      ...UserFragment
    }
  }
`);
export function useUpdateMe() {
  const [updateMeMutation, { loading }] = useMutation(UpdateMeMutation);

  const updateMe = useCallback(
    async ({
      givenName,
      familyName,
    }: {
      givenName?: string;
      familyName?: string;
    }) => {
      await updateMeMutation({
        variables: {
          givenName,
          familyName,
        },
      });
    },
    [updateMeMutation],
  );

  return {
    updateMe,
    loading,
  };
}
